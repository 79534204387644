import React, { Component } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { withAlert } from 'react-alert';
import { withRouter } from 'react-router';

import * as API from '../../../API'

class MasterPassword extends Component {

  constructor(props, context) {

    super(props, context);

    this.token = localStorage.getItem('myartadmin.token');
    this.login = localStorage.getItem('myartadmin.login');

    this.state = {
      masterPassword : ''
    }
  }

  updateMasterPassword() {
    API.updateMasterPassword(this.state.masterPassword, this.token)
      .then(() => {
        this.props.alert.success(this.props.t('navigation.masterpasswordUpdated'));
        this.props.onHide();
      })
      .catch(() => {
        this.props.alert.error(this.props.t('navigation.masterpasswordError'));
        this.props.onHide();
      })
  }

  render() {

    if(this.login !== 'admin') {
      return <div></div>;
    }

    return (
      <Modal size="md"
        show={this.props.show}
        onHide={this.props.onHide}
        onEnter={() => document.getElementById('master-password-field').focus()}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.t('navigation.masterpassword')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>{this.props.t('navigation.masterpassword')}</Form.Label>
            <Form.Control
              id="master-password-field"
              onChange={(event) => this.setState({masterPassword : event.target.value})}
              value={this.state.masterPassword}
              type="password"
              placeholder={this.props.t('navigation.masterpassword')}
            />
            <Form.Text>{this.props.t('navigation.masterpasswordcontrol')}</Form.Text>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide} variant="secondary">{this.props.t('frame.close')}</Button>
          <Button disabled={this.state.masterPassword.length < 6}
            onClick={this.updateMasterPassword.bind(this)} variant="gold">
            {this.props.t('navigation.update')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

}

export default withRouter(withTranslation('translate')(withAlert()(MasterPassword)));