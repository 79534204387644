import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import './i18n/i18n.js';
import Admin from './pages/Admin/Admin';
import Signin from './pages/Signin/Signin';
import Home from './pages/Home/Home';
import Synthese from './pages/Synthese/Synthese';
import ResetPassword from './pages/ResetPassword/ResetPassword';
import ForgottenPassword from './pages/ResetPassword/ForgottenPassword';
import SendInfoToReset from './pages/ResetPassword/SendInfoToReset';
import { transitions, positions, Provider as AlertProvider } from 'react-alert'
import AlertTemplate from 'react-alert-template-oldschool-dark'
import { BrowserRouter, Switch, Route } from 'react-router-dom';

import * as serviceWorker from './serviceWorker';
import AdsApp from "./pages/AdsApp/AdsApp";

const alertOptions = {
    position: positions.TOP_RIGHT,
    timeout: 5000,
    offset: '30px',
    transition: transitions.SCALE,
    containerStyle: {
        zIndex: 10000
    }
}

ReactDOM.render(
    <BrowserRouter>
        <AlertProvider template={AlertTemplate} {...alertOptions}>
            <div className="MyArtAdmin">
                <Switch>
                    <Route exact path='/' component={Signin} />
                    <Route exact path='/home' component={Home} />
                    <Route exact path='/synthese' component={Synthese} />
                    <Route exact path='/pwd' component={ResetPassword} />
                    <Route path='/admin/*' component={Admin} />
                    <Route exact path='/reset-password' component={SendInfoToReset} />
                    <Route exact path='/reset-password/:key' component={ForgottenPassword} />
                    <Route exact path='/ads-app' component={AdsApp} />
                </Switch>
            </div>
        </AlertProvider>
    </BrowserRouter>,
    document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
