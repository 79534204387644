import React, { Component } from 'react';
import { Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { withAlert } from 'react-alert';
import { withRouter } from 'react-router';
import ResetForm from '../ResetPassword/ResetForm';

class ChangePassword extends Component {

  render() {

    return (

      <Modal size="md" show={this.props.show} onHide={this.props.onHide}>
        <Modal.Body>
          <ResetForm onCancel={this.props.onHide} onConfirm={this.props.onHide} />
        </Modal.Body>
      </Modal>
    );
  }

}

export default withRouter(withTranslation('translate')(withAlert()(ChangePassword)));