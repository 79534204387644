import React, { Component } from 'react';
import { Button, Form, Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { withAlert } from 'react-alert';
import { withRouter } from 'react-router';

import * as API from '../../../API'

class CreateFrame extends Component {

  constructor(props, context) {
    super(props, context);

    this.token = localStorage.getItem('myartadmin.token');

    this.state = {
      label: '',
      externalId: ''
    }

  }

  createNewFrame() {
    API.createFrame({ ...this.state }, this.token)
      .then(frame => this.props.history.push('/admin/frames/' + frame.id))
      .catch(() => {
        this.props.alert.error(this.props.t('frames.createError'));
      })
  }

  render() {

    return (
      <Modal size="md"
        show={this.props.show}
        onHide={this.props.onHide}
        onEnter={() => document.getElementById('new-frame-name').focus()}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.t('frames.newFrameName')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>{this.props.t('frame.name')}</Form.Label>
            <Form.Control
              id="new-frame-name"
              onChange={(event) => this.setState({label : event.target.value})}
              value={this.state.label}
              type="text"
              placeholder={this.props.t('frame.name')}
            />
            <Form.Text>{this.props.t('frame.namecontrol')}</Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>{this.props.t('frame.externalId')}</Form.Label>
            <Form.Control
              id="new-frame-externalId"
              onChange={(event) => this.setState({externalId : event.target.value})}
              value={this.state.externalId}
              type="text"
              placeholder={this.props.t('frame.externalId')}
            />
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide} variant="secondary">{this.props.t('frame.close')}</Button>
          <Button disabled={this.state.label.length < 4}
            onClick={this.createNewFrame.bind(this)} variant="gold">
            {this.props.t('frames.create')}
          </Button>
        </Modal.Footer>
      </Modal>
    );
  }

}

export default withRouter(withTranslation('translate')(withAlert()(CreateFrame)));