import React, {Component} from 'react';
import {
  Button,
  Col,
  Container,
  Dropdown,
  Form,
  Nav,
  Navbar,
  Row,
  Spinner,
  Tab,
  Table,
  Tabs,
  Toast
} from 'react-bootstrap';
import {withTranslation} from 'react-i18next';
import {withAlert} from 'react-alert';
import Footer from '../Footer/Footer';
import {Redirect} from "react-router-dom";

import CampaignContact from './CampaignContact'

import frLogo from '../Admin/Navigation/fr.png';
import enLogo from '../Admin/Navigation/en.png';
import esLogo from '../Admin/Navigation/es.png';

import RedirectChart from './RedirectChart';
import ZonePlanification from '../ZonePlanification/ZonePlanification'
import VideoUpload from '../VideoUpload/VideoUpload';

import ZoneColorModal from '../Admin/Frame/ZoneColorModal';

import * as API from '../../API';

import adpLogo from './ads.png';
import ChangePassword from './ChangePassword';
import './home.css';
import Stats from '../Admin/Frame/Stats';
import momentTZ from 'moment-timezone';

class Home extends Component {

  constructor(props, context) {

    super(props, context);

    this.token = localStorage.getItem('myartadmin.token');
    this.login = localStorage.getItem('myartadmin.login');

    this.state = {
      selectedFrame: null,
      frames: [],
      zones: [],
      editableZones: [],
      frameImage: null,
      sortField: null,
      sortOrder: null,
      showModal: false,
      showVideoModal: false,
      showResetModal: false,
      showMenuLang: false,
      showMenuContact: false,
      showColorModal: false,
      selectedZoneColor: null,
      redirect: null,
      data: this.props.location,
      windowWidth: 0,
      campaigns: [],
      showToast: false,
      loading: false,
    }


    this.showMenuLang = this.showMenuLang.bind(this);
    this.closeMenuLang = this.closeMenuLang.bind(this);

    this.showMenuContact = this.showMenuContact.bind(this);
    this.closeMenuContact = this.closeMenuContact.bind(this);
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  setShow(value) {
    this.setState({
      showToast: value,
    })
  }

  signout() {
    localStorage.removeItem('myartadmin.token');
    localStorage.removeItem('myartadmin.login');
    this.props.history.push('/');
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({windowWidth: window.innerWidth});
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    // API.getCampaigns(this.token).then(res=> {
    //   console.log("res campaigns user =>", res)
    //   this.setState({
    //     campaigns: res,
    //   })
    // })
    this.setState({loading: true})

    API.fetchMyFrames(this.token).then(frames => {

      this.setState({
        frames: frames
      })


      if (this.state.selectedFrame === null && frames.length > 0) {
        if (this.state.data.data !== undefined) {
          this.selectFrame(this.state.data.data);
        } else {
          this.selectFrame(frames[0].id);
        }
      }


    }).catch(() => {
      this.props.alert.error(this.props.t('home.loadError'));
    })
  }

  selectFrame(frameId) {
    if (frameId === 0) {
      this.setState({
        selectedFrame: null,
        redirect: "/synthese",
        loading: false,
      })


    } else {
      Promise.all([
        API.fetchZones(frameId, this.token),
        API.fetchTargets(frameId, this.token),
        API.getImage(frameId, this.token)
      ]).then(values => {
        this.setState({
          zones: values[0],
          editableZones: values[0].map(z => {
            const newZone = {...z};
            newZone.timeZone = values[1].find(t => t.label === z.target).timeZone;
            return newZone;
          }),
          targets: values[1],
          editableTargets: values[1],
          selectedFrame: this.state.frames.find(f => f.id === frameId),
          frameImage: (values[2] === '' || values[2] === null) ? null : values[2],
          sortField: null,
          sortOrder: null,
          loading: false,
        })

      }).catch(() => {
        this.props.alert.error(this.props.t('home.loadError'));
      })
    }
  }

  globalSave() {
    this.state.editableZones.forEach(zone => {
      if (!((zone.targetUrl === undefined || zone.targetUrl === null || zone.targetUrl === '') || this.state.zones.find(zz => zone.index === zz.index && zone.target === zz.target).targetUrl === zone.targetUrl)) {
        this.updateZone(zone)
      }
    });
  }

  updateZone(zone) {
    const frameId = this.state.selectedFrame.id;
    API.updateZone(frameId, zone.target, zone.index, {
      targetUrl: zone.targetUrl,
      label: zone.label,
      color: zone.color
    }, this.token)
      .then(() => this.selectFrame(frameId))
      .catch(() => this.props.alert.error(this.props.t('home.updateUrlError')))
  }

  changeSort(field) {
    if (this.state.sortField === null) {
      const tmpeditableZones = this.state.editableZones.sort((ez1, ez2) => {
        return ('' + ez1[field]).localeCompare(ez2[field]);
      });
      this.setState({sortField: field, sortOrder: 'ASC', editableZones: tmpeditableZones});
    } else if (this.state.sortField === field && this.state.sortOrder === 'ASC') {
      const tmpeditableZones = this.state.editableZones.sort((ez1, ez2) => {
        return ('' + ez2[field]).localeCompare(ez1[field]);
      });
      this.setState({sortOrder: 'DESC', editableZones: tmpeditableZones});
    } else {
      const tmpeditableZones = this.state.editableZones.sort((ez1, ez2) => {
        if (ez1.target !== ez2.target) {
          return ('' + ez1.target).localeCompare(ez2.target);
        }
        return ez1.index - ez2.index;
      });
      this.setState({sortField: null, sortOrder: null, editableZones: tmpeditableZones});
    }
  }

  showMenuLang(event) {
    event.preventDefault();
    this.setState({showMenuLang: true}, () => {
      document.addEventListener('click', this.closeMenuLang);
    });
  }

  closeMenuLang() {
    this.setState({showMenuLang: false}, () => {
      document.removeEventListener('click', this.closeMenuLang);
    });
  }

  showMenuContact(event) {
    event.preventDefault();
    this.setState({showMenuContact: true}, () => {
      document.addEventListener('click', this.closeMenuContact);
    });
  }

  closeMenuContact() {
    this.setState({showMenuContact: false}, () => {
      document.removeEventListener('click', this.closeMenuContact);
    });
  }

  sendNotification(label, zoneName) {
    API.sendNotification(this.state.selectedFrame.id, this.state.selectedFrame.label, label, zoneName, this.token)
      .then(() => {
        this.props.alert.success(this.props.t('frame.notificationSent'));
      })
  }

  handleLanguageFlag() {
    // console.log("language =>", this.props.i18n.language)
    if (this.props.i18n.language.indexOf("-") > 0) {
      this.props.i18n.language = this.props.i18n.languages[1];
    }
    switch (this.props.i18n.language) {
      case 'fr':
        return <img className="flag" alt="fr lang" onClick={() => this.props.i18n.changeLanguage('fr')} src={frLogo}/>
      case 'en':
        return <img className="flag" alt="en lang" onClick={() => this.props.i18n.changeLanguage('en')} src={enLogo}/>
      case "es":
        return <img className="flag" alt="es lang" onClick={() => this.props.i18n.changeLanguage('es')} src={esLogo}/>
      default:
        return <img className="flag" alt="en lang" onClick={() => this.props.i18n.changeLanguage('en')} src={enLogo}/>
    }
  }

  handleTimezoneChange(newTargets, t, value) {
    API.modifyUserTimeZone(this.frameId, t.id, value, this.token);
    this.setState({
      editableTargets: newTargets,
      showToast: true,
    })
  }

  render() {
    console.log("state =>", this.state)
    if (this.state.redirect !== null) {
      return <Redirect to={'/synthese'}/>
    }
    return (
      <div>
        <Navbar variant="custom" fixed="top">
          <Container>
            <Navbar.Brand className="mr-auto">
              <img src={adpLogo} alt="adp logo" style={{height: '50px'}}/>
            </Navbar.Brand>


            {this.state.windowWidth <= 399 ?
              <React.Fragment>
                <Button onClick={this.showMenuContact} variant="success"><i className="fas fa-phone"></i> </Button>
                <Dropdown drop='down'>
                  <Dropdown.Toggle variant="gold" id="dropdown-basic"
                                   style={{height: '40px', width: '50px', marginLeft: '0.5rem'}}>
                    <i class="fas fa-bars"></i>
                  </Dropdown.Toggle>

                  <Dropdown.Menu className="settings-dropdown-content-container">
                    <Dropdown.Item href="#/action-1" className="settings-dropdown-item">
                      <Button onClick={this.showMenuLang} variant="gold"
                              style={{width: '45px'}}> {this.handleLanguageFlag()} </Button>
                    </Dropdown.Item>
                    <Dropdown.Item href="#/action-2" className="settings-dropdown-item">
                      <Button variant="gold" onClick={this.signout.bind(this)} style={{width: '45px'}}><i
                        className="fas fa-sign-out-alt"></i> </Button>
                    </Dropdown.Item>
                  </Dropdown.Menu>
                </Dropdown>
              </React.Fragment>
              :
              <React.Fragment>
                <Nav className="mr-2">
                  <Button onClick={this.showMenuContact} variant="success"><i className="fas fa-phone"></i> </Button>
                </Nav>
                <Nav className="mr-2">
                  <Button onClick={this.showMenuLang} variant="gold"
                          style={{width: '45px'}}> {this.handleLanguageFlag()} </Button>
                </Nav>

                <Nav>
                  <Button variant="gold" onClick={this.signout.bind(this)}><i className="fas fa-sign-out-alt"></i>
                  </Button>
                </Nav>
              </React.Fragment>
            }
            {/* <Nav className="mr-2">
              <Button onClick={this.showMenuContact} variant="success"><i className="fas fa-phone"></i> </Button>
            </Nav>
            <Nav className="mr-2">
              <Button onClick={this.showMenuLang} variant="gold" style={{width: '45px'}}> {this.handleLanguageFlag()} </Button>
            </Nav>
            <Nav>
              <Button variant="gold" onClick={this.signout.bind(this)}><i className="fas fa-sign-out-alt"></i> </Button>
            </Nav> */}
          </Container>
        </Navbar>
        {
          this.state.showMenuLang
            ? (
              <Navbar variant="custom" fixed="top" style={{top: '66px', height: '60px',}}>
                <Container>
                  <Row style={{flex: 1, flexDirection: 'row-reverse', marginBottom: '8px'}}>
                    <Col className="mb-sm-4 text-right">
                      <div className="menu btn btn-gold">
                        <img className="flag" alt="fr lang" onClick={() => this.props.i18n.changeLanguage('fr')}
                             src={frLogo}/>
                        <img className="flag" alt="en lang" onClick={() => this.props.i18n.changeLanguage('en')}
                             src={enLogo}/>
                        <img className="flag" alt="es lang" onClick={() => this.props.i18n.changeLanguage('es')}
                             src={esLogo}/>
                      </div>
                    </Col>
                  </Row>
                </Container>
              </Navbar>
            )
            : (
              null
            )

        }

        {
          this.state.showMenuContact
            ? (
              <Navbar variant="custom" fixed="top" style={{top: '66px', height: '40px'}}>
                <Container>
                  <Row style={{flex: 1, flexDirection: 'row-reverse', padding: '10px'}}>
                    <Nav className="mr-2">
                      <div className="menu btn btn-gold" style={{marginRight: '0.5rem'}}>
                        <a className="fas fa-envelope-square" style={{color: 'black'}}
                           href="mailto:Hello@ArtDesignPainting.paris"></a>
                      </div>
                      <div className="menu btn btn-gold">
                        <a className="fas fa-mobile-alt" style={{color: 'black'}} href="tel:+33665512312"></a>
                      </div>
                    </Nav>
                  </Row>
                </Container>
              </Navbar>
            )
            : (
              null
            )
        }


        <Container className="Common-Container Home" style={{paddingTop: '80px'}}>
          <div style={{position: 'fixed', bottom: '140px', right: '20px', zIndex: '999'}}>
            <Toast onClose={() => this.setShow(false)} show={this.state.showToast} delay={3000} autohide>
              <Toast.Header>
                <img
                  src="holder.js/20x20?text=%20"
                  className="rounded mr-2"
                  alt=""
                />
                <strong className="mr-auto">{this.props.t("userManagement.thanks")}</strong>
                <small>{this.props.t("userManagement.toastTime")}</small>
              </Toast.Header>
              <Toast.Body style={{color: 'black'}}>
                {this.props.t('home.toastTimeZoneModified')}
              </Toast.Body>
            </Toast>
            {/* <Col xs={6}>
                <Button onClick={() => this.setShow(true)}>Show Toast</Button>
            </Col> */}
          </div>
          <Row>
            <Col md={12} className="mb-sm-4 text-right welcome-message">

              <strong style={{color: '#B59A4F'}}
                      className="strong-welcome-message">{this.props.t('home.welcome')}</strong> <span
              className="font-italic">{this.login}</span>
              <Button onClick={() => this.props.history.push('/synthese')} className="ml-2" variant="gold" size="sm"><i
                className="fas fa-table"></i> {/*this.props.t('frames.synthetisis')*/}</Button>
              <Button onClick={this.globalSave.bind(this)} className="ml-2" variant="gold" size="sm"><i
                className="fas fa-save"></i></Button>
              <Button onClick={() => this.setState({showResetModal: true})} className="ml-2" variant="gold" size="sm"><i
                className="fas fa-lock"></i></Button>

            </Col>
            <ChangePassword show={this.state.showResetModal} onHide={() => this.setState({showResetModal: false})}/>
          </Row>

          {
            (this.state.frames.length > 1) &&
            <Row className="mb-4">
              <Col md={{span: 5, offset: 7}}>
                <Form.Control as="select"
                              value={(this.state.selectedFrame === null || this.state.selectedFrame === undefined) ? 0 : this.state.selectedFrame.id}
                              onChange={(event) => this.selectFrame(parseInt(event.target.value))}>
                  <option value={0}>{this.props.t('frames.synthetisis')}</option>
                  ;
                  {
                    this.state.frames.map(t => {
                      return <option key={t.id} value={t.id}>{t.label}</option>;
                    })
                  }
                </Form.Control>
              </Col>
            </Row>
          }

          {
            this.state.selectedFrame === null && !this.state.loading &&

            <Row className="mb-4">
              <Col md={12}>
                <Table variant="custom" size="sm" responsive="md" borderless>
                  <thead>
                  <tr>
                    <th>{this.props.t('redirections.frameLabel')}</th>
                    <th>{this.props.t('redirections.redirectCount')}</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    this.state.frames.map(t => {
                      return <tr key={t.id}>
                        <td>{t.label}</td>
                        <td>{t.redirectCount}</td>
                      </tr>;
                    })
                  }
                  </tbody>
                </Table>
              </Col>
            </Row>
          }
          {this.state.selectedFrame !== null &&
          <h3 style={{fontWeight: '800'}}>{this.state.selectedFrame.label.toUpperCase()}</h3>
          }
          <Tabs variant="pills" defaultActiveKey="informations" id="frame-tabs" className="campaign-manager-tabs" fill
                style={{padding: '0'}}>
            <Tab eventKey="informations" title={this.props.t('frame.informationsAndData').toUpperCase()}
                 className="campaign-manager-tabs" style={{padding: '0'}}>
              {this.state.selectedFrame === null ?
                <Container className="frame-container">
                  <Spinner animation="border" className="spinner-border-custom"/>
                </Container>
                :
                <div>
                  {this.state.selectedFrame !== null &&
                  <React.Fragment>
                    <Row>
                      <h4 style={{
                        marginBottom: '1.5rem',
                        paddingLeft: '15px',
                        color: '#B59A4F',
                        fontWeight: 'bold'
                      }}>{this.props.t('frame.informations').toUpperCase()}</h4>
                    </Row>
                    <Row>
                      {/* <Col md={4}>
                <h3>{this.state.selectedFrame.label}</h3> */}
                      {/* <ul className="list-unstyled">
                  {
                    this.state.targets.map((t, index) => {
                      console.log("t c'est: =>", t)
                      return (
                      <ul style={{padding: '0px'}} key={`title-icons-${index + 1}`}>
                        <li style={{float: 'left', width: 'auto', listStyle: 'outside none none'}}
                            className="trash-button"
                            key={t.label}>{t.label} {(t.targetUrl !== null && t.targetUrl !== '') &&
                        <i onClick={() => window.open(t.targetUrl, '_blank')}
                          className="ml-sm-2 fas fa-external-link-alt"> </i>}</li>
                        <li style={{float: 'left', width: 'auto', listStyle: 'outside none none', marginLeft: '5px'}}
                            className="trash-button"><i onClick={() => this.sendNotification(t.label, null)}
                                                        className="fas fa-satellite-dish"></i></li>
                        <br/>
                      </ul>)
                    })
                  }
                </ul> */}
                      {/* </Col> */}
                      {
                        this.state.frameImage !== null &&
                        <Col md={4}>
                          <img style={{width: '98%'}} src={'data:image/*;base64,' + this.state.frameImage}
                               alt="visuel"/>
                        </Col>
                      }
                    </Row>
                  </React.Fragment>
                  }
                  {(this.state.selectedFrame !== null && this.state.targets.length > 0) &&
                  <React.Fragment>
                    <Row className="mt-5">
                      <Col md={12} className="mb-sm-2">
                        <h4 className="Uppercase-Title">{this.props.t('home.charttitle')}</h4>
                      </Col>
                      {this.state.targets.map(t => {
                        return <Col key={t.label} md={{span: 8, offset: 0}}>
                          <h4>{t.label}</h4>
                          <RedirectChart zones={this.state.zones.filter(z => z.target === t.label)}/>
                        </Col>
                      })
                      }
                    </Row>
                    <section>
                      <Stats frameId={this.state.selectedFrame.id} targetsLabel={this.state.targets}
                             zones={this.state.zones}/>
                    </section>
                  </React.Fragment>
                  }
                </div>
              }
            </Tab>
            <Tab eventKey="targets" title={this.props.t('frame.targetsAndZones').toUpperCase()}
                 className="campaign-manager-tabs" style={{padding: '0'}}>
              {
                this.state.editableTargets && this.state.editableTargets.length > 0 ?
                  <Table variant="custom" size="sm" responsive="md" borderless>
                    <thead>
                    <tr>
                      <th>{this.props.t('frame.target')}</th>
                      <th>{this.props.t('frame.timezone')}</th>
                      <th>{this.props.t('frame.targetUrl')}</th>
                      <th>{this.props.t('frame.paintingCode')}</th>
                      <th></th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      this.state.editableTargets.map((t, i) => {
                        return <tr key={t.label} className={i % 2 === 0 ? 'even' : 'odd'}>
                          <td style={{height: '38px', paddingTop: '12px'}}>{t.label}</td>
                          <td>
                            <Form.Control
                              as="select"
                              size="sm"
                              style={{height: '38px'}}
                              value={t.timeZone}
                              placeholder={this.props.t('frame.timezone')}
                              onChange={(event) => {
                                const editableTargets = this.state.editableTargets.map(tt => {
                                  if (t.label === tt.label) {
                                    return {
                                      ...tt,
                                      timeZone: event.target.value,
                                    }
                                  }
                                  return tt;
                                });
                                this.handleTimezoneChange(editableTargets, t, event.target.value);
                                // this.setState({editableTargets});
                              }}>
                              {
                                momentTZ.tz.names().map(tz => {
                                  return <option key={tz} value={tz}>{tz}</option>;
                                })
                              }
                            </Form.Control>
                          </td>
                          <td>
                            <div style={{display: 'flex', flexDirection: 'row'}}>
                              <Form.Control
                                style={{height: '38px', marginRight: '10px', minWidth: '100px'}}
                                value={t.targetUrl === null ? '' : t.targetUrl}
                                type="text"
                                size="sm"
                                disabled
                              />
                              <Button
                                variant="gold"
                                className="small-button"
                                onClick={() => window.open(t.targetUrl, '_blank')}>
                                <i className="fas fa-external-link-alt"></i>
                              </Button>
                            </div>
                          </td>
                          <td style={{color: '#B59A4F', fontWeight: 'bold'}}>
                            <span style={{paddingRight: '1rem'}}>{t.frameId}_{t.label}</span>
                            <Button
                              onClick={() => this.props.history.push('/ads-app')}
                              variant="gold"
                              className="mr-sm-2 large-button"
                              title={this.props.t('frame.timezone')}
                            >
                              <i className="fas fa-mobile-alt"> ADS</i>
                            </Button>
                          </td>
                          <td>
                            {/* <Button
                            variant="gold"
                            className="mr-sm-2 small-button"
                            onClick={() => window.open(t.targetUrl, '_blank')}
                          >
                            <i className="fas fa-external-link-alt"></i>
                          </Button> */}
                            <Button
                              onClick={() => this.sendNotification(t.label, null)}
                              variant="gold"
                              className="mr-sm-2 small-button"
                            >
                              <i className="fas fa-satellite-dish"></i>
                            </Button>

                            {/* this.state.targets.map((t, index) => {
                          console.log("t c'est: =>", t)
                          return (
                          <ul style={{padding: '0px'}} key={`title-icons-${index + 1}`}>
                            <li style={{float: 'left', width: 'auto', listStyle: 'outside none none'}}
                                className="trash-button"
                                key={t.label}>{t.label} {(t.targetUrl !== null && t.targetUrl !== '') &&
                            <i onClick={() => window.open(t.targetUrl, '_blank')}
                              className="ml-sm-2 fas fa-external-link-alt"> </i>}</li>
                            <li style={{float: 'left', width: 'auto', listStyle: 'outside none none', marginLeft: '5px'}}
                                className="trash-button"><i onClick={() => this.sendNotification(t.label, null)}
                                                            className="fas fa-satellite-dish"></i></li>
                            <br/>
                          </ul>)
                        }) */}

                          </td>
                        </tr>
                      })
                    }
                    </tbody>
                  </Table>
                  :
                  <p className="mt-sm-2 mb-sm-2 text-center"><strong>{this.props.t('frame.nozones')}</strong></p>
              }

              {this.state.selectedFrame !== null &&
              <Row className="justify-content-md-center">
                <Col md={12} className="legend-icons-targets">
                  <div>
                    <Button variant="gold"
                            className="mr-sm-2 small-button margin-legend-icons small-legend-icons" disabled>
                      <i className="fas fa-video small-legend-icons-translate-icons"></i>
                    </Button>
                    <span className="legend-text">{this.props.t('home.upload')}</span>
                  </div>
                  <div>
                    <Button variant="gold"
                            className="mr-sm-2 Button-With-Badge small-button margin-legend-icons small-legend-icons"
                            disabled>
                      <i className="fas fa-clock small-legend-icons-translate-icons"></i>
                    </Button>
                    <span className="legend-text">{this.props.t('home.schedule')}</span>
                  </div>
                  <div>
                    <Button variant="gold"
                            className="mr-sm-2 small-button margin-legend-icons small-legend-icons"
                            disabled>
                      <i className="fas fa-external-link-alt small-legend-icons-translate-icons"></i>
                    </Button>
                    <span className="legend-text">{this.props.t('home.externalLink')}</span>
                  </div>
                  <div>
                    <Button variant="gold" className="mr-sm-2 small-button margin-legend-icons small-legend-icons"
                            disabled>
                      <i className="fas fa-satellite-dish small-legend-icons-translate-icons"></i>
                    </Button>
                    <span className="legend-text">{this.props.t('home.notify')}</span>
                  </div>

                </Col>
                <Col md={12}>
                  <Table variant="custom" size="sm" responsive="md" borderless>
                    <thead>
                    <tr>
                      <th style={{width: '10px'}}></th>
                      <th
                        className={'clickable' + (this.state.sortField === 'index' ? ' sorted-' + this.state.sortOrder : '')}
                        onClick={() => this.changeSort('index')}>{this.props.t('home.index')}
                      </th>
                      <th></th>
                      <th
                        className={'clickable' + (this.state.sortField === 'target' ? ' sorted-' + this.state.sortOrder : '')}
                        onClick={() => this.changeSort('target')}>{this.props.t('home.target')}</th>
                      <th
                        className={'clickable' + (this.state.sortField === 'label' ? ' sorted-' + this.state.sortOrder : '')}
                        onClick={() => this.changeSort('label')}>{this.props.t('home.name')}</th>
                      <th>{this.props.t('home.targetUrl')}</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      this.state.editableZones.map((z, i) => {
                        return <tr key={z.index + z.target} className={i % 2 === 0 ? 'even' : 'odd'}>
                          <td style={{width: '10px'}}>
                            <Button variant="default" size="sm" onClick={() => {
                              this.setState({
                                showColorModal: true,
                                selectedZoneColor: z
                              })
                            }}>
                              <i className="fas fa-stop" style={{color: z.color}}></i>
                            </Button>
                            &nbsp;</td>
                          <td>{z.index}</td>
                          <td>
                            {z.uploadAccess === 1 &&
                            <Button variant="gold"
                                    className="mr-sm-2 small-button"
                                    onClick={() => this.setState({
                                      selectedZone: {
                                        frameId: this.state.selectedFrame.id,
                                        target: z.target,
                                        index: z.index,
                                        timeZone: z.timeZone
                                      },
                                      showVideoModal: true
                                    })}>
                              <i className="fas fa-video"></i>
                            </Button>
                            }
                            {z.scheduleAccess === 1 &&
                            <Button variant="gold"
                                    className="mr-sm-2 Button-With-Badge small-button"
                                    onClick={() => this.setState({
                                      selectedZone: {
                                        targetUrl: z.targetUrl,
                                        frameId: this.state.selectedFrame.id,
                                        target: z.target,
                                        index: z.index,
                                        timeZone: z.timeZone
                                      },
                                      showModal: true
                                    })}
                                    disabled={z.targetUrl === undefined || z.targetUrl === null || z.targetUrl === ''}>
                              <i className="fas fa-clock"></i>
                              {z.planifications > 0 && <span className="Button-Badge">{z.planifications}</span>}
                            </Button>
                            }
                            {z.previewAccess === 1 &&
                            <Button variant="gold"
                                    className="mr-sm-2 small-button"
                                    onClick={() => window.open(process.env.REACT_APP_API_URL + '/r/' + z.uniqueKey + '/test', '_blank')}
                                    disabled={z.uniqueKey === undefined || z.uniqueKey === null || z.uniqueKey === ''}>
                              <i className="fas fa-external-link-alt"></i>
                            </Button>
                            }
                            {z.notifyAccess === 1 &&
                            <Button onClick={() => this.sendNotification(z.target, z.label)} variant="gold"
                                    className="mr-sm-2 small-button"><i className="fas fa-satellite-dish"></i></Button>
                            }
                          </td>
                          <td>{z.target}</td>
                          <td>{z.label}</td>
                          <td className="pr-sm-4 pl-sm-4">
                            <Form.Row>
                              <Col md={z.saveAccess === 1 ? 11 : 12}>
                                <Form.Control
                                  disabled={z.saveAccess === 0}
                                  value={z.targetUrl}
                                  style={{
                                    minWidth: '200px',
                                  }}
                                  onChange={(event) => {
                                    const zones = this.state.editableZones.map(zz => {
                                      if (z.index === zz.index && z.target === zz.target) {
                                        return {
                                          ...zz,
                                          targetUrl: event.target.value,
                                        }
                                      }
                                      return zz;
                                    });
                                    this.setState({editableZones: zones});
                                  }}
                                  type="text"
                                  placeholder={this.props.t('home.targetUrl')}/>
                              </Col>
                              {z.saveAccess === 1 &&
                              <Col md={1}>
                                <Button
                                  disabled={(z.targetUrl === undefined || z.targetUrl === null || z.targetUrl === '')
                                  || this.state.zones.find(zz => z.index === zz.index && z.target === zz.target).targetUrl === z.targetUrl}
                                  onClick={() => this.updateZone(z)}
                                  variant="gold" className="small-button">
                                  <i className="fas fa-save"></i>
                                </Button>
                              </Col>
                              }
                            </Form.Row>
                          </td>
                        </tr>
                      })
                    }
                    </tbody>
                  </Table>
                </Col>
              </Row>

              }
            </Tab>
            <Tab eventKey="campaigns" title={this.props.t("frame.FANartZONE-VIPartZONE").toUpperCase()}
                 className="campaign-manager-tabs" style={{padding: '0'}}>
              <p style={{
                fontSize: '20px',
                color: '#B59A4F',
                fontWeight: '600',
                textAlign: 'justify',
                textJustify: 'inter-word'
              }}>{this.props.t('home.contactUsCampaign').toUpperCase()}</p>
              <CampaignContact selectedFrame={this.state.selectedFrame}/>
            </Tab>
          </Tabs>

          <Row>
            <Col md={12}>
              <Footer/>
            </Col>
          </Row>

          <ZonePlanification
            zoneId={this.state.selectedZone}
            showProvider={false}
            // externalUrl={this.state.selectedFrame === null ? "/" : this.state.targets[0].targetUrl}
            zone={this.state.selectedZoneColor}
            show={this.state.showModal}
            userMod
            onHide={() => {
              this.setState({showModal: false});
              this.selectFrame(this.state.selectedFrame.id);
            }}/>
          <VideoUpload showProvider={false} zoneId={this.state.selectedZone} show={this.state.showVideoModal}
                       onHide={() => {
                         this.setState({showVideoModal: false})
                         setTimeout(() => {
                           this.selectFrame(this.state.selectedFrame.id);
                         }, 1000);
                       }}/>
          <ZoneColorModal onHide={() => {
            this.setState({showColorModal: false});
            this.selectFrame(this.state.selectedFrame === null ? 0 : this.state.selectedFrame.id);
          }} show={this.state.showColorModal}
                          frameId={this.state.selectedFrame === null ? 0 : this.state.selectedFrame.id}
                          zone={this.state.selectedZoneColor}/>
        </Container>

      </div>
    );
  }

}

export default withTranslation('translate')(withAlert()(Home));
