import React from 'react';
import {withTranslation} from 'react-i18next';
import {Button, Col, Container, Row, Spinner, Table} from 'react-bootstrap';
import DisplayPlayer from './DisplayPlayer';
import * as API from '../../../API';
import * as moment from 'moment';
import './Campaign.css';
import {Delete} from "@material-ui/icons";
import {IconButton} from "@material-ui/core";

class CampaignParticipants extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      usersCampaign: [],
      showPlayer: false,
    }
    this.token = localStorage.getItem('myartadmin.token');
    this.deleteUserCampaign = this.deleteUserCampaign.bind(this);
  }

  componentDidMount() {
    API.getUserCampaigns(this.props.campaignId, this.token)
      .then(res => {
        this.setState({
          usersCampaign: res,
          selectedMedia: '',
          showPlayer: false,
        })
      }).catch(() => {
      // this.props.alert.error(this.props.t('accounts.loadError'));
    })
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.campaignId !== nextProps.campaignId) {
      API.getUserCampaigns(nextProps.campaignId, this.token)
        .then(res => {
          this.setState({
            usersCampaign: res,
            selectedMedia: '',
            showPlayer: false,
          })
        }).catch(() => {
        // this.props.alert.error(this.props.t('accounts.loadError'));
      })
    }
    return true;
  }

  handleSelectedMedia(media) {
    this.setState({
      selectedMedia: media,
      showPlayer: true,
    })
  }

  deleteUserCampaign(id) {
    API.deleteUserCampaign(this.props.campaignId, id, this.token).then(() => {
      API.getUserCampaigns(this.props.campaignId, this.token)
        .then(res => {
          this.setState({
            usersCampaign: res,
            selectedMedia: '',
            showPlayer: false,
          })
        }).catch(() => {
        // this.props.alert.error(this.props.t('accounts.loadError'));
      })
    });
  }

  render() {
    if (this.state.usersCampaign.length <= 0) {
      return (
        <Container className="frame-container">
          <Spinner animation="border" className="spinner-border-custom"/>
        </Container>
      )
    } else {
      return (
        <React.Fragment>
          <Container>
            {this.state.usersCampaign.length === 0 &&
            <Row>
              <Col md={{span: 6, offset: 3}}>
                <p className="mt-sm-4 text-center"><strong>{this.props.t('campaigns.participants')}</strong></p>
              </Col>
            </Row>
            }
            {this.state.usersCampaign.length > 0 &&
            <Row className="justify-content-md-center">
              <Col md={12} style={{padding: '0'}}>
                <Table variant="custom" size="sm" responsive="md" borderless>
                  <thead>
                  <tr>
                    <th></th>
                    <th>{this.props.t('campaigns.user.firstName')}</th>
                    <th>{this.props.t('campaigns.user.lastName')}</th>
                    <th>{this.props.t('campaigns.user.email')}</th>
                    <th>{this.props.t('campaigns.user.country')}</th>
                    <th>{this.props.t('campaigns.user.city')}</th>
                    <th>{this.props.t('campaigns.user.role')}</th>
                    <th>{this.props.t('campaigns.user.video')}</th>
                    <th>{this.props.t('campaigns.user.validated')}</th>
                    <th>{this.props.t('campaigns.user.newsletter')}</th>
                    <th>{this.props.t('campaigns.user.dataSpread')}</th>
                    <th>{this.props.t('campaigns.user.createdDate')}</th>
                    <th>{this.props.t('campaigns.user.isRead')}</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    this.state.usersCampaign.map((a, i) => {
                      return <tr key={a.id} className={i % 2 === 0 ? 'even' : 'odd'}>
                        <td><IconButton style={{color: '#B59A4F'}}
                                        onClick={() => this.deleteUserCampaign(a.id)}><Delete/></IconButton></td>
                        <td>{a.firstName}</td>
                        <td>{a.lastName}</td>
                        <td>{a.email}</td>
                        <td>{a.country}</td>
                        <td>{a.city}</td>
                        <td>{a.role}</td>
                        <td>
                          <Button onClick={() => this.handleSelectedMedia(a.video)} className="mr-2 small-button"
                                  variant="gold"><i className="fas fa-play"></i></Button>
                        </td>
                        <td>{a.validated === 0 ? 'N' : 'Y'}</td>
                        <td>{a.newsletter === 0 ? 'N' : 'Y'}</td>
                        <td>{a.dataSpread === 0 ? 'N' : 'Y'}</td>
                        <td>{moment(a.createdDate).format('L')}</td>
                        <td>{a.isRead === 0 ? 'N' : 'Y'}</td>
                      </tr>
                    })
                  }
                  </tbody>
                </Table>
              </Col>
            </Row>
            }
          </Container>
          <DisplayPlayer
            show={this.state.showPlayer}
            media={this.state.selectedMedia}
            title="Video"
            onHide={() => {
              this.setState({showPlayer: false});
            }}
          />
        </React.Fragment>
      )
    }
  }
}

export default withTranslation('translate')(CampaignParticipants);
