import React, {Component} from 'react';
import {LinkContainer} from 'react-router-bootstrap';
import {Button} from 'react-bootstrap';
import {withRouter} from 'react-router';
import {withTranslation} from 'react-i18next';

import './PageToggle.css';

class PageToggle extends Component {

  render() {

    return (
      <div className="PageToggle">
        <LinkContainer to="/admin/frames">
          <Button className="mr-2"
                  variant={this.props.location.pathname.indexOf('/admin/frames') !== -1 ? 'red' : 'red-link-dark'}>
            {this.props.t('navigation.frames')}
          </Button>
        </LinkContainer>
        <LinkContainer to="/admin/campaigns">
          <Button  className="mr-2"
            variant={this.props.location.pathname.indexOf('/admin/campaigns') !== -1 ? 'red' : 'red-link-dark'}>
            {this.props.t('navigation.campaigns')}
          </Button>
        </LinkContainer>
        <LinkContainer to="/admin/accounts">
          <Button
            variant={this.props.location.pathname.indexOf('/admin/accounts') !== -1 ? 'red' : 'red-link-dark'}>
            {this.props.t('navigation.accounts')}
          </Button>
        </LinkContainer>
      </div>
    );
  }
}

export default withRouter(withTranslation('translate')(PageToggle));

