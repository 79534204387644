import React, {Component} from 'react';
import {Button, ButtonGroup, Col, Container, Form, Row, Table} from 'react-bootstrap';
import {LinkContainer} from 'react-router-bootstrap';
import {withTranslation} from 'react-i18next';
import {withAlert} from 'react-alert';
import * as moment from 'moment';

import * as API from '../../../API';

require('moment/locale/fr');

class CampaignList extends Component {

  constructor(props, context) {
    super(props, context);

    this.token = localStorage.getItem('myartadmin.token');

    this.searchTimeout = null;

    this.state = {
      loading: false,
      campaigns: [],
      filters: {
        name: ''
      },
      sortField: 'login',
      sortOrder: 'ASC',
      offset: 0,
      totalCampaigns: 0
    }
    moment.locale(this.props.i18n.language);
  }

  componentDidMount() {
    if (this.token === undefined || this.token === null) {
      this.props.alert.error(this.props.t('campaigns.loadError'));
    } else {
      this.refreshCampaigns();
    }
  }

  componentDidUpdate(prevProps, prevState) {
    if (this.state.filters.name !== prevState.filters.name) {
      if (this.searchTimeout !== null) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(this.refreshCampaigns.bind(this), 500);
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.campaigns && (this.props.campaigns.data.length !== nextProps.campaigns.data.length)) {
      this.refreshCampaigns();
    }
    return true;
  }

  refreshCampaigns() {
    API.fetchCampaignsByFrameId(this.token, this.props.frameId).then(campaigns => {
      this.setState({totalCampaigns: campaigns.totalCampaigns, loading: false, campaigns: campaigns.data})
    }).catch(() => {
      this.props.alert.error(this.props.t('campaigns.loadError'));
    })
  }

  setFilter(field, value) {
    const filters = {...this.state.filters};
    filters[field] = value;
    this.setState({offset: 0, filters});
  }

  getState(state) {
    switch(state) {
      case 'ON_GOING':
        return this.props.t('campaigns.status.on-going');
      case 'TERMINATED':
        return this.props.t('campaigns.status.terminated');
      case 'NOT_STARTED':
        return this.props.t('campaigns.status.not-started');
      case 'ARCHIVED':
        return this.props.t('campaigns.status.archived');
      default:
        return this.props.t('campaigns.status.unknown');
    }
  }

  render() {
    return (
      <Container fluid={true} className="Common-Container common-container-padding">
        {this.props.filter &&
        <Row>
          <Col md={1} style={{padding: '0 0 0 10px'}}>
            <p style={{width: '100%'}} className="mt-sm-1">
              <strong>{this.props.t('campaigns.filter-title')}</strong>
            </p>
          </Col>
          <Col md={4}>
            <Form>
              <Form.Row>
                <Form.Group as={Col} controlId="campaigns-name-filter">
                  <Form.Control onChange={(event) => this.setFilter('name', event.target.value)} type="text"
                                placeholder={this.props.t('campaigns.name-filter')}/>
                </Form.Group>
              </Form.Row>
            </Form>
          </Col>
        </Row>
        }
        {this.state.campaigns.length === 0 &&
        <Row>
          <Col md={{span: 6, offset: 3}}>
            <p className="mt-sm-4 text-center"><strong>{this.props.t('campaigns.no-campaigns')}</strong></p>
          </Col>
        </Row>
        }
        {this.state.campaigns.length > 0 &&
        <Row className="justify-content-md-center">
          <Col md={12}>
            <Table variant="custom" size="sm" responsive="md" borderless>
              <thead>
              <tr>
                <th>{this.props.t('campaigns.status-title')}</th>
                <th>{this.props.t('campaigns.name')}</th>
                <th>FANs</th>
                <th>VIPs</th>
                <th></th>
                <th>{this.props.t('campaigns.form.targetLabel')}</th>
                <th>{this.props.t('campaigns.form.targetUrl')}</th>
                <th>{this.props.t('campaigns.start-date')}</th>
                <th>{this.props.t('campaigns.end-date')}</th>
                <th>{this.props.t('campaigns.type')}</th>
                {this.props.filter &&
                  <th>{this.props.t('campaigns.code-vip')}</th>}
                {this.props.filter &&
                  <th>{this.props.t('campaigns.cost')}</th>}
              </tr>
              </thead>
              <tbody>
              {
                this.state.campaigns.filter((c) => this.state.filters.name.length > 0 ? c.name.toLowerCase().indexOf(this.state.filters.name.toLowerCase()) >= 0 : true).map((a, i) => {
                  return <tr key={a.id} className={i % 2 === 0 ? 'even' : 'odd'}>
                    <td>{this.getState(a.state)}</td>
                    <td>{a.name}</td>
                    <td>{a.countFan}</td>
                    <td>{a.countVip}</td>
                    <td>
                      {/* <LinkContainer to={this.props.frameId ? '/admin/frames/' + this.props.frameId + '/campaign/' + a.id : '/admin/campaigns/' + a.id}> */}
                      <LinkContainer to={'/admin/campaigns/' + a.id}>
                        <Button variant="gold" size="sm" className="small-button"><i className="fas fa-edit" style={{transform: 'translate(1px, 3px)'}}></i></Button>
                      </LinkContainer>
                    </td>
                    <td>{a.targetLabel}</td>
                    <td>{a.targetUrl}</td>
                    <td>{moment(a.startDate).format('L')}</td>
                    <td>{moment(a.endDate).format('L')}</td>
                    <td>{a.type}</td>
                    {this.props.filter &&
                      <td>{a.codeVip}</td>}
                    {this.props.filter &&
                      <td>{a.cost}</td>}
                  </tr>
                })
              }
              </tbody>
            </Table>
          </Col>
          {this.state.totalCampaigns > 10 &&
          <Col md={4} className="text-right">
            <ButtonGroup>
              <Button disabled={this.state.offset === 0}
                      onClick={() => this.setState({offset: 0}, () => this.refreshCampaigns())}
                      variant="gold">&lt;&lt;</Button>
              <Button disabled={this.state.offset === 0}
                      onClick={() => this.setState({offset: Math.max(this.state.offset - 10, 0)}, () => this.refreshCampaigns())}
                      variant="gold">&lt;</Button>
              <Button variant="light">{this.state.offset + 1} - {this.state.offset + 10}</Button>
              <Button disabled={this.state.offset + 10 >= this.state.totalCampaigns}
                      onClick={() => this.setState({offset: this.state.offset + 10}, () => this.refreshCampaigns())}
                      variant="gold">&gt;</Button>
              <Button disabled={this.state.offset + 10 >= this.state.totalCampaigns}
                      onClick={() => this.setState({offset: (Math.floor(this.state.totalCampaigns / 10) * 10)}, () => this.refreshCampaigns())}
                      variant="gold">&gt;&gt;</Button>
            </ButtonGroup>
          </Col>
          }
        </Row>
        }
      </Container>
    );
  }

}

export default withTranslation('translate')(withAlert()(CampaignList));
