
import React, { Component } from 'react';
import { Button, Form, Modal, Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { withAlert } from 'react-alert';

import * as API from '../../../API';

class ZoneColorModal extends Component {

    constructor(props, context) {

        super(props, context);

        this.token = localStorage.getItem('myartadmin.token');

        this.state = {
            color: '#000000',
            imgFile: null,
            zoneImage: null
        }

    }

    render() {

        return (
            <Modal size="md" show={this.props.show} onHide={() => this.setState({
                color: '#000000',
                imgFile: null,
                zoneImage: null
            }, this.props.onHide)} onEnter={() => {
                if (this.props.zone !== null) {
                    if (this.props.zone.imageUrl) {
                        this.setState({
                            color: this.props.zone.color,
                            zoneImage: this.props.zone.imageUrl,
                        })
                    }
                    API.getZoneImage(this.props.frameId, this.props.zone.target, this.props.zone.index, this.token).then((image) => {
                        if (image !== null && image !== '') {
                            this.setState({
                                color: this.props.zone.color,
                                zoneImage: image,
                            })
                        } else {
                            this.setState({
                                color: this.props.zone.color
                            })
                        }
                    })
                }
            }}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.t('frame.zonecolortitle')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row>
                        <Col md={4}>
                            <Form.Group>
                                <Form.Label>{this.props.t('frame.zonecolorcolor')}</Form.Label>
                                <Form.Control
                                    value={this.state.color}
                                    onChange={(event) => this.setState({ color: event.target.value })}
                                    type="color"
                                />
                            </Form.Group>
                            <Form.Group>
                                <Form.Label>{this.props.t('frame.zonecolorfile')}</Form.Label>
                                <Form.Control
                                    id="frame-zone-file-upload"
                                    type="file"
                                    accept={".jpg, .png"}
                                    onChange={(event) => this.setState({ imgFile: event.target.files[0] !== undefined ? event.target.files[0] : null })}
                                    placeholder="image" />
                            </Form.Group>
                        </Col>
                        <Col md={8}>
                            {
                                this.state.zoneImage !== null && <img className="mt-sm-4" style={{ width: '90%' }} src={this.props.zone.imageUrl ? this.state.zoneImage : 'data:image/*;base64,' + this.state.zoneImage} alt="visuel" />
                            }
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={this.props.onHide}>{this.props.t('frame.close')}</Button>
                    <Button onClick={() => {
                        const promises = [];
                        if (this.state.color !== this.props.zone.color) {

                            promises.push(API.updateZone(this.props.frameId, this.props.zone.target, this.props.zone.index, {
                                targetUrl: this.props.zone.targetUrl,
                                label: this.props.zone.label,
                                color: this.state.color
							}, this.token));
                        }
                        if(this.state.imgFile !== null) {
                            promises.push(API.uploadZoneImage(this.props.frameId, this.props.zone.target, this.props.zone.index, this.state.imgFile, this.token));
                        }
                        Promise.all([promises]).then(() => {
                            this.props.onHide();
                        })
                    }} variant="gold" >{this.props.t('frame.validate')}</Button>
                </Modal.Footer>
            </Modal>
        );
    }

}

export default withTranslation('translate')(withAlert()(ZoneColorModal));
