import React, { Component } from 'react';
import { Container, Row, Col, Spinner, Button, Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { withAlert } from 'react-alert';
import PageToggle from '../PageToggle/PageToggle';
import * as API from '../../../API';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Switch from '@material-ui/core/Switch';
import { withStyles } from '@material-ui/core/styles';
import Toast from 'react-bootstrap/Toast'

import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ToggleButton from '@material-ui/lab/ToggleButton';

import './userRightsManager.css';

class UserRightsManager extends Component {

  constructor(props, context) {
    super(props, context);

    this.token = localStorage.getItem('myartadmin.token');
    this.frameId = this.props.match.params.frameId;

    this.state = {
      user: this.props.location.state.user,
      frameId: this.props.location.state.frameId,
      windowWidth: 0,
      showToast: false,
      showConfirmModal: false,
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
    this.handleChangeAll = this.handleChangeAll.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    API.getZoneAccessByUser(this.frameId, this.state.user.id, this.token)
    .then(res => this.setState({
      rights: res,
      targetLabel: res[0].targetLabel,
    }));
    API.getImage(this.frameId, this.token).then(res => this.setState({frameImage: (res === '' || res === null) ? null : res,}));
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ windowWidth: window.innerWidth });
  }

  refreshZones() {
    API.getZoneAccessByUser(this.frameId, this.state.user.id, this.token)
    .then(res => this.setState({
      rights: res,
      targetLabel: res[0].targetLabel,
    }));
  }

  setShow(value) {
    this.setState({
      showToast: value,
    })
  }

  handleChange(item) {
    console.log("item =>", item)
    if (item.authorized === 1) {
      console.log("delete");
      API.deleteAccessOneZone(this.frameId, this.state.user.id, item.id, this.token)
      .then(res => {
        if (res === 'OK') {
          API.getZoneAccessByUser(this.frameId, this.state.user.id, this.token)
    .then(res => {
      this.setState({
        rights: res,
        targetLabel: res[0].targetLabel,
        // showToast: true,
        // toastMessage: this.props.t('userManagement.accessDeleted'),
      });
      // this.refreshZones()
    });
        }
      })
    } else {
      console.log("add")
      API.AddAccessOneZone(this.frameId, this.state.user.id, [item.frameZoneId], this.token)
      .then(res => {
        if (res === 'Created') {
          API.getZoneAccessByUser(this.frameId, this.state.user.id, this.token)
          .then(res => {
            this.setState({
              rights: res,
              targetLabel: res[0].targetLabel,
              // showToast: true,
              // toastMessage: this.props.t('userManagement.accessAdded'),
            });
            // this.refreshZones();
          });
        }
      })
    }
  }

  handleChangeAll(targetName, e) {
    e.stopPropagation();
    e.preventDefault();
    let fullyAuthorized = true;
    console.log("test")
    this.state.rights.forEach(item => {
      if (targetName !== "") {
        if (item.targetLabel === targetName) {
          if (item.authorized === 0) {
            fullyAuthorized = false;
          }
        }
    } else {
      if (item.authorized === 0) {
        fullyAuthorized = false;
      }
    }})
    console.log("fullyAuthorized =>", fullyAuthorized)
    if (fullyAuthorized) {
      console.log("change all to false")
      const tmpZones = [];
      this.state.rights.forEach(item => {
        if (targetName !== "") {
          if (item.targetLabel === targetName) {
            tmpZones.push(item.frameZoneId);
          }
        } else {
          tmpZones.push(item.frameZoneId);
        }
      })
      API.deleteAllAccessZones(this.frameId, this.state.user.id, tmpZones, this.token)
      .then(res => {
        if (res === 'OK') {
          let tmpRights = [...this.state.rights];
          tmpRights.forEach(item => {
            if (targetName !== "") {
              if (item.targetLabel === targetName) {
                item.authorized = 0;
              }
            } else {
              item.authorized = 0;
            }
          })
          this.setState({
            rights: tmpRights,
            // showToast: true,
            // toastMessage: this.props.t('userManagement.allAccessDeleted'),
            showConfirmModal: false,
          })
          this.refreshZones();
        }
      })
    } else {
      console.log("change all to true")
      const tmpZones = [];
      this.state.rights.forEach(item => {
        if (targetName !== "") {
          if (item.targetLabel === targetName) {
            if (item.authorized === 0) {
              tmpZones.push(item.frameZoneId);
            }
          }
        } else {
          if (item.authorized === 0) {
            tmpZones.push(item.frameZoneId);
          }
        }
      })
      API.addAllAccessZones(this.frameId, this.state.user.id, tmpZones, this.token)
      .then(res => {
        if (res === 'OK' || res === 'Created') {
          let tmpRights = [...this.state.rights];
          tmpRights.forEach(item => {
            if (targetName !== "") {
              if (item.targetLabel === targetName) {
                item.authorized = 1;
              }
            } else {
              item.authorized = 1;
            }
          })
          this.setState({
            rights: tmpRights,
            // showToast: true,
            // toastMessage: this.props.t('userManagement.allAccessAdded'),
            showConfirmModal: false,
          });
          this.refreshZones();
        }
      })
    }
  }

  allAuthorized() {
    let fullyAuthorized = true;
    this.state.rights.forEach(item => {
      if (item.authorized === 0) {
        fullyAuthorized = false;
      }
    })
    return fullyAuthorized;
  }

  allTargetAuthorized(target) {
    let fullyAuthorized = true;
    this.state.rights.forEach(item => {
      if (item.targetLabel === target && item.authorized === 0) {
        fullyAuthorized = false;
      }
    })
    return fullyAuthorized;
  }

  handleCloseModal() {
    this.setState({
      showConfirmModal: false,
    })
  }

  handleOptionAccess(item, subItem, optionKey, currentValue) {
    const newValue = currentValue === null || currentValue === 0 ? 1 : 0;
    API.toggleOptionAccessZone(this.frameId, this.state.user.id, optionKey, newValue, subItem.id, this.token)
    .then(res => {
      if (res === 'OK') {
        let tmpRights = [...this.state.rights];
        const tmpIndex = tmpRights.findIndex(item => item.id === subItem.id);
        tmpRights[tmpIndex][optionKey] = newValue;
        this.setState({
          rights: tmpRights,
          // showToast: true,
          // toastMessage: this.props.t('userManagement.optionAccessModified'),
        })
        // this.refreshZones()
      }
    });
    
  }

  render() {

    const GoldSwitch = withStyles({
      switchBase: {
        color: '#B59A4F',
        '&$checked': {
          color: '#B59A4F',
        },
        '& + $track': {
          backgroundColor: 'rgb(175, 39, 25)',
        },
      },
      checked: {
        '&$checked + $track': {
          backgroundColor: 'green',
        },
      },
      track: {
        borderRadius: 26 / 2,
        border: `1px solid white`,
        backgroundColor: '#000',
        opacity: 1,
      },
    })(Switch);
    let result = new Map();
    if (this.state.rights) {
      this.state.rights.forEach(right => {
        // console.log('ZeRight', right);
        if (result.get(right.targetLabel)) {
          // console.log(result.get(right.targetLabel));
          result.get(right.targetLabel).push(right);
        } else {
          result.set(right.targetLabel, [right]);
          // console.log(result.get(right.targetLabel));
        }
      });
    }
    // console.log("new map result =>", result)
    // console.log("keys de result =>", result.keys())

    const CustomAccordionSummary = withStyles({
      root: {
        background: 'black',
        borderRadius: 3,
        color: 'white',
        height: '70px',
        padding: '0 30px 0 10px',
        border: '1px solid white',
      },
      expandIcon: {
        color: 'white'
      }
    })(AccordionSummary);

    const CustomAccordionDetails = withStyles({
      root: {
        background: 'black',
        borderRadius: 3,
        color: 'white',
        border: '1px solid white',
        padding: '0',
      },
    })(AccordionDetails);

    const CustomToggleButton = withStyles({
      root: {
        borderRadius: 3,
        color: 'white',
        height: '35px',
        width: '35px',
      },
    })(ToggleButton);

    const newResult = [];
    // const useStyles = makeStyles((theme) => ({
    //   root: {
    //     width: '100%',
    //   },
    //   heading: {
    //     fontSize: theme.typography.pxToRem(15),
    //     fontWeight: theme.typography.fontWeightRegular,
    //   },
    // }));
    // const classes = useStyles();

    for (let item of result.keys()) {
      console.log(item);
      newResult.push(
        <div style={{height: '100%'}} key={`main-accordion-target-${item}`}>
          <Accordion>
            <CustomAccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography
                style={{fontWeight: '600', fontSize: '18px', transform: 'translateY(5px)', width: '100px', textOverflow: 'ellipsis', overflow: 'hidden', whiteSpace: 'nowrap'}}>
                {item}
              </Typography>
              <GoldSwitch
                checked={this.allTargetAuthorized(item)} ////to modify on target
                color="primary"
                onClick={(e) => this.handleChangeAll(item, e)}
                name={`zone-right-${item}`}
              />
            </CustomAccordionSummary>
            <CustomAccordionDetails>
              <div className="user-rights-accordion-item-container">
                {result.get(item).map((subItem, index) => (
                  <div
                    key={`accordion-item-right-${subItem.zoneLabel}-${index}`}
                    className="user-rights-accordion-item"
                    style={{
                      backgroundColor: index % 2 === 0 && '#3d2d00',
                      borderBottom: index === result.get(item).length -1 && 'none',
                    }}
                  >
                    <div style={{display: 'flex', flexDirection: this.state.windowWidth >= 768 ? 'row' : 'column'}}>
                      <div style={{display: 'flex', flexDirection: 'row'}}>
                        <div className="user-rights-accordion-item-text" style={{width: this.state.windowWidth >= 768 ? '250px' : '170px'}}>
                          {subItem.zoneLabel}
                        </div>
                        <GoldSwitch
                          checked={subItem.authorized === 1 ? true : false}
                          color="primary"
                          onChange={() => this.handleChange(subItem)}
                          name={`zone-right-${item.targetLabel}-${item.zoneLabel}`}
                        />
                      </div>
                      {subItem.id !== null &&
                        <div style={{ width: this.state.windowWidth >= 768 ? '300px' : '220px', display: 'flex', flexDirection: 'row', justifyContent: 'space-around', margin: this.state.windowWidth < 768 && '20px 0'}}>
                          <CustomToggleButton
                            style={{border:'1px solid white'}}
                            value="saveAccess"
                            selected={!subItem.saveAccess ? false : true}
                            onChange={() => this.handleOptionAccess(item, subItem, "saveAccess", subItem.saveAccess)}
                          >
                            <i className="fas fa-save" style={{color: !subItem.saveAccess ? 'rgb(175, 39, 25, 1)' : 'green'}}></i>
                          </CustomToggleButton>
                          <CustomToggleButton
                            style={{border:'1px solid white'}}
                            value="scheduleAccess"
                            selected={!subItem.scheduleAccess ? false : true}
                            onChange={() => this.handleOptionAccess(item, subItem, "scheduleAccess", subItem.scheduleAccess)}
                          >
                            <i className="fas fa-clock" style={{color: !subItem.scheduleAccess ? 'rgb(175, 39, 25, 1)' : 'green'}}></i>
                          </CustomToggleButton>
                          <CustomToggleButton
                            style={{border:'1px solid white'}}
                            value="notifyAccess"
                            selected={!subItem.notifyAccess ? false : true}
                            onChange={() => this.handleOptionAccess(item, subItem, "notifyAccess", subItem.notifyAccess)}
                          >
                            <i className="fas fa-satellite-dish" style={{color: !subItem.notifyAccess ? 'rgb(175, 39, 25, 1)' : 'green'}}></i>
                          </CustomToggleButton>
                          <CustomToggleButton
                            style={{border:'1px solid white'}}
                            value="previewAccess"
                            selected={!subItem.previewAccess ? false : true}
                            onChange={() => this.handleOptionAccess(item, subItem, "previewAccess", subItem.previewAccess)}
                          >
                            <i className="fas fa-external-link-alt" style={{color: !subItem.previewAccess ? 'rgb(175, 39, 25, 1)' : 'green'}}></i>
                          </CustomToggleButton>
                          <CustomToggleButton
                            style={{border:'1px solid white'}}
                            value="uploadAccess"
                            selected={!subItem.uploadAccess ? false : true}
                            onChange={() => this.handleOptionAccess(item, subItem, "uploadAccess", subItem.uploadAccess)}
                          >
                            <i className="fas fa-video" style={{color: !subItem.uploadAccess ? 'rgb(175, 39, 25, 1)' : 'green'}}></i>
                          </CustomToggleButton>
                        </div>
                      }
                    </div>
                  </div>
                ))}
              </div>
            </CustomAccordionDetails>
          </Accordion>
        </div>
      )
    }

      return (
        <Container className="Common-Container">
          <Modal show={this.state.showConfirmModal} onHide={this.handleCloseModal}>
            <Modal.Header closeButton>
              <Modal.Title>{this.props.t('userManagement.confirmation')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>{this.props.t("userManagement.areYouSure")}{ this.state.addOrDel === true ? this.props.t('userManagement.deleteAllAccessUser') : this.props.t('userManagement.addAllAccessUser')}</Modal.Body>
            <Modal.Footer>
              <Button variant="secondary" onClick={this.handleCloseModal}>
                {this.props.t("userManagement.no")}
              </Button>
              <Button variant="primary" onClick={(e) => this.handleChangeAll("", e)}>
              {this.props.t("userManagement.yes")}
              </Button>
            </Modal.Footer>
          </Modal>
          <div style={{ position: 'fixed', bottom: '140px', right: '20px', zIndex: '999'}}>
            <Toast onClose={() => this.setShow(false)} show={this.state.showToast} delay={3000} autohide>
              <Toast.Header>
                  <img
                  src="holder.js/20x20?text=%20"
                  className="rounded mr-2"
                  alt=""
                  />
                  <strong className="mr-auto">{this.props.t("userManagement.thanks")}</strong>
                  <small>{this.props.t("userManagement.toastTime")}</small>
              </Toast.Header>
              <Toast.Body style={{ color: 'black'}}>
                  {this.state.toastMessage}
              </Toast.Body>
            </Toast>
            {/* <Col xs={6}>
                <Button onClick={() => this.setShow(true)}>Show Toast</Button>
            </Col> */}
          </div>
          <Row>
            <Col md={12}>
              <PageToggle />
            </Col>
          </Row>
          <Row>
            <Col md={12} style={{display: 'flex', flexDirection: this.state.windowWidth >= 768 ? 'row' : 'column', marginTop: this.state.windowWidth >= 768 && '2rem', fontFamily: "Helvetica Neue, Roboto, sans-serif"}}>
              <h4>{this.props.t('userManagement.userRightsManagement').toUpperCase()}</h4>
              <h4 style={{marginLeft: this.state.windowWidth >= 768 && '1rem'}}>{this.state.user.login.toUpperCase()}</h4>
              <Button className="mr-2"
                  variant={this.props.location.pathname.indexOf('/admin/frames') !== -1 ? 'gold-link-dark' : 'gold-link'}
                  onClick={() => this.props.history.goBack()}
                  style={{
                    height: '40px',
                    marginLeft: this.state.windowWidth >= 768 &&  '1rem',
                    transform: 'translateY(-5px)',
                    fontFamily: "Helvetica Neue, Roboto, sans-serif",
                    fontWeight: '600',
                  }}
              >
            {this.props.t('navigation.goBack')}
          </Button>
            </Col>
          </Row>
          {!this.state.rights ?
            <Container className="frame-container">
              <Spinner animation="border" className="spinner-border-custom" />
            </Container>
          :
          <React.Fragment>
            <Row style={{margin: '1rem 0'}}>
              <Col md={4}>
                <h3 style={{fontWeight: '800', fontFamily: "Helvetica Neue, Roboto, sans-serif"}}>{this.state.targetLabel}</h3>
              </Col>
              <Col md={6}>
              </Col>
            </Row>
            <div className={this.state.windowWidth >= 768 ? 'main-container-user-rights' : 'main-container-user-rights-responsive'}>
              <div style={{ padding: '0.5rem 1rem'}}>
              {
                this.state.frameImage !== null &&
                <img className="mt-sm-4" src={'data:image/*;base64,' + this.state.frameImage}
                    alt="visuel" style={{width: this.state.windowWidth >= 768 ? '300px' : '95%'}} />
              }
              </div>
              <div  style={{fontFamily: "Helvetica Neue, Roboto, sans-serif", padding: this.state.windowWidth >= 768 ? '1.5rem 1.5rem 1.5rem 2rem' : '1.5rem 0.5rem 1.5rem 0.5rem', margin: '0 1rem', display: 'flex', flexDirection: 'column', minHeight: this.state.windowWidth >= 768 ? '430px' : '215px', width: '-webkit-fill-available', boxShadow: 'rgba(182, 154, 79, 0.75) 0px 0px 15px 5px'}}>
                <div style={{display: 'flex', flexDirection: 'row', padding: '0.5rem', width: '100%', borderBottom: '1px solid white'}}>
                  <div style={{width: '80%', fontWeight: '600'}}>{this.props.t('userManagement.activateAll')}</div>
                  <GoldSwitch
                    checked={this.allAuthorized()}
                    color="primary"
                    // onChange={() => this.setState({showConfirmModal: true, addOrDel: this.allAuthorized()})}
                    onChange={(e) => this.handleChangeAll("", e)}
                    name={`zone-right-tick-all`}
                  />
                </div>
                <div style={{padding: '1.5rem 0rem 1.5rem 0rem', display: 'flex', flexDirection: 'column'}}>

                {newResult}
                
                {/* {this.state.rights.map((item, index) => (
                  <FormControlLabel
                    key={`zone-right-${index + 1}`}
                    style={{
                      marginRight: '0',
                    }}
                    control={
                      <div style={{display: 'flex', flexDirection: 'row', padding: '0.5rem', height: '75px', width: '100%', backgroundColor: index % 2 === 1 ? '#3d2d00' : '#000'}}>
                      <div style={{width: '80%', transform: 'translateY(7px)'}}>{item.targetLabel}-{item.zoneLabel}</div>
                      <GoldSwitch
                        checked={item.authorized === 1 ? true : false}
                        color="primary"
                        onChange={() => this.handleChange(item)}
                        name={`zone-right-${item.targetLabel}-${item.zoneLabel}`}
                      />
                      </div>
                      }
                  />
                  ))
                } */}
                </div>
              </div>
            </div>
          </React.Fragment>
          }

        </Container>
      );
  }

}

export default withTranslation('translate')(withAlert()(UserRightsManager));
