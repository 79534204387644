import React, { Component } from 'react';
import { Button, Form, Modal, Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { withAlert } from 'react-alert';
import { withRouter } from 'react-router';

import Datetime from 'react-datetime';

import { FlexibleWidthXYPlot, XAxis, HorizontalRectSeries, Hint } from 'react-vis';

import * as moment from 'moment';

import * as API from '../../API'
import './zonePlanification.css';

class ZonePlanification extends Component {

  constructor(props, context) {
    super(props, context);

    this.token = localStorage.getItem('myartadmin.token');

    this.state = {
      filterStartDate: moment().startOf('isoWeek').toDate(),
      filterEndDate: moment().add(5, 'week').endOf('isoWeek').toDate(),
      startDate: moment().startOf('day').toDate(),
      endDate: moment().startOf('day').add(1, 'day').toDate(),
      targetUrl: '',
      videoTitle: '',
      videoDescription: '',
      videoFile: '',
      planifications: [],
      hint: false,
      mode: 'targetUrl',
      uploading: false,
      previsu: " ",
      algorithm: 'FIFO',
    }
    this.getSelectedCampaign = this.getSelectedCampaign.bind(this);
  }

  initializeModal() {
    let tmpCampaigns = {};
    if(!this.props.userMod) {
      tmpCampaigns.data = this.props.campaigns.data.filter((item) => item.frameTargetId === this.props.zoneId.targetId);
      tmpCampaigns.data.reverse();
    }
    this.setState({
      startDate: moment().startOf('day').toDate(),
      endDate: moment().startOf('day').add(1, 'day').toDate(),
      targetUrl: '',
      mode: 'targetUrl',
      planifications: [],
      upload: false,
      videoDescription: '',
      videoTitle: '',
      videoFile: '',
      algorithm: 'FIFO',
      campaignTypeSelected: this.props.campaigns && tmpCampaigns.data[0] ? tmpCampaigns.data[0].type.split('/')[0] : 'FAN',
      campaignType: this.props.campaigns && tmpCampaigns.data[0] ? tmpCampaigns.data[0].type.split('/') : ['FAN', 'VIP', 'FAMILY'],
      selectedCampaign: this.props.campaigns ? tmpCampaigns.data[0] : null,
      campaigns: !this.props.userMod && tmpCampaigns,
      uploading: false
    }, () => this.refreshPlanifications());
  }

  getSelectedCampaign(name) {
    const tmp = [...this.state.campaigns.data];
    const tmpCampaign = tmp.filter(item => item.name === name);
    this.setState({
      selectedCampaign: tmpCampaign[0],
      campaignType: tmpCampaign[0].type.split('/'),
    })
  }

  refreshPlanifications() {
    API.fetchZonePlanification(this.props.zoneId.frameId, this.props.zoneId.target, this.props.zoneId.index,
      moment.tz(moment(this.state.filterStartDate).format('YYYY-MM-DDTHH:mm'), 'YYYY-MM-DDTHH:mm', this.props.zoneId.timeZone).toISOString(),
      moment.tz(moment(this.state.filterEndDate).format('YYYY-MM-DDTHH:mm'), 'YYYY-MM-DDTHH:mm', this.props.zoneId.timeZone).toISOString(),
      this.token).then(planifications => {
      this.setState({ planifications })
    })
  }

  addNewPlanification() {
    if (this.state.mode === 'campagne') {
      let tmpCampaignZone = {
        campaignId: this.state.selectedCampaign.id,
        frameZoneId: this.props.zoneId.id,
        type: this.state.campaignTypeSelected,
        algorithm: this.state.algorithm,
        startDate: this.state.startDate,
        endDate: this.state.endDate,
      };
      API.addCampaignPlanification(tmpCampaignZone, this.props.zoneId.frameId, this.token)
        .then(() => this.initializeModal());
    } else if (this.state.mode !== 'targetUrl') {
      this.setState({
        uploading: true
      }, () => {
        API.uploadVideo(this.props.zoneId.frameId, this.props.zoneId.target, this.props.zoneId.index,
          this.state.videoFile, this.state.videoTitle, this.state.videoDescription, this.props.mode,
          moment.tz(moment(this.state.startDate).format('YYYY-MM-DDTHH:mm'), 'YYYY-MM-DDTHH:mm', this.props.zoneId.timeZone).toISOString(),
          moment.tz(moment(this.state.endDate).format('YYYY-MM-DDTHH:mm'), 'YYYY-MM-DDTHH:mm', this.props.zoneId.timeZone).toISOString(),
          this.token)
          .then(() => {
            alert(this.props.t('home.videouploadalert'));
            this.initializeModal();
          });
      })
    } else {
      API.addZonePlanification(this.props.zoneId.frameId, this.props.zoneId.target, this.props.zoneId.index, {
        beginDate: moment.tz(moment(this.state.startDate).format('YYYY-MM-DDTHH:mm'), 'YYYY-MM-DDTHH:mm', this.props.zoneId.timeZone).toISOString(),
        endDate: moment.tz(moment(this.state.endDate).format('YYYY-MM-DDTHH:mm'), 'YYYY-MM-DDTHH:mm', this.props.zoneId.timeZone).toISOString(),
        targetUrl: this.state.targetUrl
      }, this.token)
        .then(() => this.initializeModal())
    }
  }

  deletePlanification(v) {
    if (v.targetUrl === null) {
      if (window.confirm(this.props.t('home.confirmDeleteCampaign') + '\n' + v.campaignName)) {
        API.deleteZonePlanification(this.props.zoneId.frameId, this.props.zoneId.target, this.props.zoneId.index, v.targetUrl, this.token)
          .then(() => this.refreshPlanifications())
      }
    } else {
      if (window.confirm(this.props.t('home.confirmDeletePlanif') + '\n' + v.targetUrl)) {
        API.deleteZonePlanification(this.props.zoneId.frameId, this.props.zoneId.target, this.props.zoneId.index, v.targetUrl, this.token)
          .then(() => this.refreshPlanifications())
      }
    }
  }

  getXValue(weeks, date, isBegin) {
    const beginDate = moment(date);
    let index = weeks.indexOf(beginDate.isoWeek());
    if (!isBegin && index < 0 && beginDate.isoWeek() > weeks[weeks.length - 1]) {
      return weeks.length;
    }
    const numeral = parseInt(((beginDate.isoWeekday() - 1) * 10 / 6).toFixed());
    // console.log("index, numeral =>", index, numeral, parseFloat(index + (numeral / 10)))
    if (parseFloat(index + (numeral / 10)) < 0) {
      return isBegin ? 0 : weeks.length
    } else {
      return parseFloat(index + (numeral / 10));
    }
  }

  render() {
    const numberOfLines = this.state.planifications.length + 1;
    const weeks = [];
    if (moment(this.state.filterEndDate).isoWeek() < moment(this.state.filterStartDate).isoWeek()) {
      for (let i = moment(this.state.filterStartDate).isoWeek(); i <= moment().weeksInYear(); i++) {
        weeks.push(i);
      }
      for (let i = 1; i <= moment(this.state.filterEndDate).isoWeek(); i++) {
        weeks.push(i);
      }
    } else {
      for (let i = moment(this.state.filterStartDate).isoWeek(); i <= moment(this.state.filterEndDate).isoWeek(); i++) {
        weeks.push(i);
      }
    }
    // reset scaling ?
    // const isNotFullWeeks = (moment(this.state.filterEndDate).diff(this.state.filterStartDate, 'days')) !== (weeks.length * 7) - 1;
    // const diffDays = ((weeks.length * 7)) - (moment(this.state.filterEndDate).diff(this.state.filterStartDate, 'days'));
    const data = this.state.planifications.map((p, i) => {
      const correspondingCampaign = !this.state.campaigns.data ? undefined : this.state.campaigns.data.find(item => item.id === p.campaignId);
      const dataElement = {
        x0: moment(moment(p.beginDate).format('YYYY-MM-DD')).isSameOrBefore(moment(this.state.filterStartDate)) ? 0 : this.getXValue(weeks, p.beginDate, true),
        x: this.getXValue(weeks, p.endDate, false),
        y0: (i / 2) + 0.5,
        y: (i / 2) + 0.05,
        beginDate: moment(p.beginDate).tz(this.props.zoneId.timeZone).format('L HH:mm'),
        endDate: moment(p.endDate).tz(this.props.zoneId.timeZone).format('L HH:mm'),
        targetUrl: p.targetUrl,
        campaignName: correspondingCampaign !== undefined && correspondingCampaign.name,
        global: false,
        color : p.superPlanification ? '#af2719' : p.campaignId ? '#000000' : undefined
      }
      if (dataElement.x === dataElement.x0) {
        dataElement.x = dataElement.x + 0.1;
      }
      return dataElement;
    });

    if (this.props.zoneId) {
      const xVal = this.getXValue(weeks, moment(this.state.filterEndDate), false);
      data.push({
        x0: 0,
        color: '#19af3f',
        x: xVal < 1 ? 1 : xVal,
        y0: (data.length / 2) + 0.5,
        y: (data.length / 2),
        beginDate: moment(this.state.filterStartDate).format('L HH:mm'),
        endDate: moment(this.state.filterEndDate).format('L HH:mm'),
        targetUrl: this.props.zoneId.targetUrl,
        global: true
      });
    }

    return (

      <Modal size="lg"
             show={this.props.show}
             onEnter={this.initializeModal.bind(this)}
             onHide={this.props.onHide}>

        <Modal.Header closeButton>
          <Button variant="gold"
                  className="mr-sm-2 small-button"
                  disabled
                  style={{ cursor: 'auto', opacity: '1'}}>
            <i className="fas fa-clock"></i>
          </Button>
          <Modal.Title>{this.props.t('home.planifications')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col>
              <Form.Group>
                <Datetime
                  dateFormat="DD/MM/YYYY"
                  timeFormat="HH:mm"
                  closeOnSelect={true}
                  value={this.state.filterStartDate}
                  onChange={(d) => {
                    if (typeof d !== 'string') {
                      this.setState({ filterStartDate: d.toDate() }, () => this.refreshPlanifications())
                    }
                  }} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Datetime
                  dateFormat="DD/MM/YYYY"
                  timeFormat="HH:mm"
                  closeOnSelect={true}
                  value={this.state.filterEndDate}
                  className="dateTime-picker"
                  onChange={(d) => {
                    if (typeof d !== 'string') {
                      this.setState({ filterEndDate: d.toDate() }, () => this.refreshPlanifications())
                    }
                  }} />
              </Form.Group>
            </Col>
          </Row>
          <div  style={{height: '1px', borderTop: '1px solid rgb(0,0,0,0.1)'}} />
          <Row>
            <Col>
              <Form.Group>
                {/* <Form.Label>{this.props.t('home.planificationTo')}</Form.Label> */}
                <Form.Label>Planification</Form.Label>
                <Form.Control value={this.state.mode} onChange={(event) => this.setState({ mode: event.target.value })} as="select">
                  <option value="targetUrl">URL</option>
                  {!this.props.showProvider &&
                  <option value="videos">Video</option>
                  }
                  {this.props.showProvider &&
                  <option value="videos">Videas</option>
                  }
                  {/* {this.props.showProvider &&
                  <option value="youtube">Youtube</option>
                  } */}
                  {this.props.showProvider && this.state.campaigns && this.state.campaigns.data.filter(item => item.state !== 'ARCHIVED').length > 0 &&
                  <option value="campagne">Campagne</option>
                  }
                </Form.Control>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>{this.props.t('home.planificationFrom')}</Form.Label>
                <Datetime
                  dateFormat="DD/MM/YYYY"
                  timeFormat="HH:mm"
                  closeOnSelect={true}
                  value={this.state.startDate}
                  className="dateTime-picker-middle"
                  onChange={(d) => {
                    if (typeof d !== 'string') {
                      this.setState({ startDate: d.toDate() })
                    }
                  }} />
              </Form.Group>
            </Col>
            <Col>
              <Form.Group>
                <Form.Label>{this.props.t('home.planificationTo')}</Form.Label>
                <Datetime
                  dateFormat="DD/MM/YYYY"
                  timeFormat="HH:mm"
                  closeOnSelect={true}
                  className="dateTime-picker"
                  value={this.state.endDate}
                  onChange={(d) => {
                    if (typeof d !== 'string') {
                      this.setState({ endDate: d.toDate() })
                    }
                  }} />
              </Form.Group>
            </Col>
          </Row>
          {this.state.mode === 'targetUrl' &&
          <Row>
            <Col>
              <Form.Group>
                <Form.Label>{this.props.t('home.targetUrl')}</Form.Label>
                <Form.Control
                  id="new-frame-externalId"
                  onChange={(event) => this.setState({ targetUrl: event.target.value })}
                  value={this.state.targetUrl}
                  type="url"
                  placeholder={this.props.t('home.targetUrl')}
                  autoCapitalize="off"
                />
              </Form.Group>
            </Col>
          </Row>
          }
          {(this.state.mode === 'videos') &&
          <React.Fragment>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>{this.props.t('home.videouploadtitle')}</Form.Label>
                  <Form.Control
                    onChange={(event) => this.setState({ videoTitle: event.target.value })}
                    value={this.state.videoTitle}
                    type="text"
                    placeholder={this.props.t('home.videouploadtitle')}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>{this.props.t('home.videouploaddesc')}</Form.Label>
                  <Form.Control
                    onChange={(event) => this.setState({ videoDescription: event.target.value })}
                    value={this.state.videoDescription}
                    type="text"
                    placeholder={this.props.t('home.videouploaddesc')}
                  />
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>{this.props.t('home.videouploadfile')}</Form.Label>
                  <Form.Control
                    id="new-frame-externalId"
                    onChange={(event) => {
                      this.setState({ videoFile: event.target.files[0] !== undefined ? event.target.files[0] : '' })
                    }}
                    type="file"
                    placeholder={this.props.t('home.videouploadfile')}
                  />
                </Form.Group>
              </Col>
            </Row>
          </React.Fragment>
          }
          {this.state.mode === 'campagne' &&
          <React.Fragment>
            <Row>
              <Col>
                <Form.Group controlId="selectCampaign">
                  <Form.Label>Selectionner la campagne</Form.Label>
                  <Form.Control as="select" custom onChange={e => this.getSelectedCampaign(e.target.value)}>
                    {this.state.campaigns.data.filter(item => item.state !== "ARCHIVED").map((campaign, index) => (
                      <option key={index + 1}>{campaign.name}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="CampaignType">
                  <Form.Label>Type de Campagne</Form.Label>
                  <Form.Control as="select" custom onChange={e => this.setState({ campaignTypeSelected: e.target.value})}>
                    {this.state.campaignType.map((type, index) => (
                      <option key={index + 1}>{type}</option>
                    ))}
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            <Row>
              <Col>
                <Form.Group controlId="selectCampaign">
                  <Form.Label>Mode de lecture</Form.Label>
                  <Form.Control as="select" custom onChange={e => this.setState({ algorithm: e.target.value})}>
                    <option>FIFO</option>
                    <option>FILO</option>
                    <option>RANDOM</option>
                    <option>PLAYLIST</option>
                  </Form.Control>
                </Form.Group>
              </Col>
            </Row>
            {/* <Row>
            <Col>
            <Form.Group>
                <Form.Check
                  type='checkbox'
                  id={`default-checkbox`}
                  label='lecture en boucle'
                />
              </Form.Group>
            </Col>
            <Col>
            <Form.Group>
                <Form.Check
                  type='checkbox'
                  id={`default-checkbox`}
                  label='notifier de la lecture'
                />
              </Form.Group>
            </Col>
          </Row> */}
          </React.Fragment>
          }
          {this.state.mode === 'campagne' ?
            <Button
              onClick={this.addNewPlanification.bind(this)} variant="gold">
              {this.props.t('home.add')}
            </Button>
            :
            <Button disabled={this.state.uploading || (this.state.mode === 'targetUrl' && this.state.targetUrl === '')
            || (this.state.mode !== 'targetUrl' && (this.state.videoTitle === '' || this.state.videoDescription === '' || this.state.videoFile === ''))
            || moment(this.state.startDate).isSameOrAfter(moment(this.state.endDate))}
                    onClick={this.addNewPlanification.bind(this)} variant="gold">
              {this.props.t('home.add')}
            </Button>
          }
          <hr />
          <FlexibleWidthXYPlot
            height={Math.min(250, numberOfLines / 2 * 50 + 50)}
            // xDomain={[0, isNotFullWeeks ? (weeks.length) - diffDays / 7 : (weeks.length)]}
            xDomain={[0, weeks.length]}
            yDomain={[numberOfLines / 2 + 0.5, 0]}>
            <XAxis
              tickValues={weeks.map(w => {
                const numberOfValues = weeks.map(w => w - weeks[0]).length;
                let modulo = 1;
                modulo = numberOfValues > 100 ? 20 : modulo;
                modulo = numberOfValues > 50 ? 10 : modulo;
                modulo = numberOfValues > 20 ? 5 : modulo;
                modulo = numberOfValues > 10 ? 3 : modulo;
                modulo = numberOfValues > 5 ? 2 : modulo;
                const value = w - weeks[0];
                return value % modulo === 0 ? value : '';
              })}
              tickFormat={v => v === '' ? '' : 'S' + (v + weeks[0])}
              style={{
                line: { stroke: '#000000' },
                ticks: { stroke: '#000000' },
                text: { stroke: 'none', fill: '#000000' }
              }} />

            <HorizontalRectSeries
              color='#B59A4F'
              colorType='literal'
              data={data}
              onValueClick={(v) => {
                if (!v.global && v.targetUrl !== undefined) {
                  this.deletePlanification(v);
                }
              }}
              onValueMouseOut={() => this.setState({ hint: false })}
              onValueMouseOver={(v) => this.setState({ hint: v , previsu: v})}
            />
            {this.state.hint &&
            <Hint value={this.state.hint} >
              <div style={{ background: 'black', color: 'white', fontSize: '0.8em', textAlign: 'center', padding: '3px', borderRadius: '4px', }}>
                {this.props.t('home.planificationFrom') + ' ' + this.state.hint.beginDate} <br />
                {this.props.t('home.planificationTo') + ' ' + this.state.hint.endDate}<br />
                {this.state.hint.targetUrl !== null ? this.state.hint.targetUrl : this.state.hint.campaignName}
              </div>
            </Hint>
            }

          </FlexibleWidthXYPlot>
        </Modal.Body>
        <Modal.Footer>
          {this.state.previsu !== " " &&
          <Col style={{color: 'black', paddingBottom: '30px'}}>

            <Button variant="gold"
                    className="mr-sm-2 small-button"
                    disabled={this.state.previsu.targetUrl === "" || this.state.previsu.targetUrl == null}
                    onClick={() => window.open(this.state.previsu.targetUrl, '_blank')}>
              <i className="fas fa-external-link-alt"></i>
            </Button> <br />

            {this.props.t('home.planificationFrom') + ' ' + this.state.previsu.beginDate} <br />
            {this.props.t('home.planificationTo') + ' ' + this.state.previsu.endDate} <br />

          </Col>
          }
          <Button onClick={this.props.onHide} variant="secondary">{this.props.t('frame.close')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

}

export default withRouter(withTranslation('translate')(withAlert()(ZonePlanification)));
