import React from 'react';
import {CartesianGrid, Legend, Line, LineChart, ResponsiveContainer, Tooltip, XAxis, YAxis} from 'recharts';
import {Container, Form, Spinner} from 'react-bootstrap';
import * as API from '../../../API';
import {withTranslation} from 'react-i18next';

class Stats extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      initialData: [],
      data: [],
      labels: [],
      zones: [],
      selectedLabel: this.props.t('frame.selectOne'),
      selectedZone: this.props.t('frame.selectOne'),
    }
    this.token = localStorage.getItem('myartadmin.token');
  }

  componentDidMount() {
    this.initView(this.props);
  }

  initView(props) {
    let labels = [props.t('frame.selectOne')];
    props.targetsLabel.map(item => labels.push(item.label));
    this.setState({
      labels,
    })
    const zones = [];
    labels.map(item => item !== this.props.t('frame.selectOne') && zones.push({
      label: item,
      zones: [this.props.t('frame.selectOne')]
    }));
    props.zones.map(item => zones.map(zone => item.target === zone.label && zone.zones.push(`${item.index.toString()}-${item.label}`)))
    this.setState({
      zones,
    })
    API.getFrameTotalActivation(props.frameId, this.token)
      .then((res) => {
        res.map(item => item.redirectDate = item.redirectDate.slice(0, 10))
        this.setState({data: res, initialData: res})
      })
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (nextProps.frameId !== this.props.frameId) {
      this.initView(nextProps);
    }
    if (nextState.selectedLabel !== this.state.selectedLabel && nextState.selectedLabel !== this.props.t('frame.selectOne')) {
      API.getFrameActivationByLabel(this.props.frameId, nextState.selectedLabel, this.token)
        .then(res => {
          res.map(item => item.redirectDate = item.redirectDate.slice(0, 10))
          this.setState({data: res})
        })
    }
    if (nextState.selectedZone !== this.state.selectedZone && nextState.selectedZone !== this.props.t('frame.selectOne')) {
      API.getFrameActivationByZoneIndex(this.props.frameId, this.state.selectedLabel, nextState.selectedZone, this.token)
        .then(res => {
          res.map(item => item.redirectDate = item.redirectDate.slice(0, 10))
          this.setState({data: res})
        })
    }
    if (nextState.selectedLabel !== this.state.selectedLabel && nextState.selectedLabel === this.props.t('frame.selectOne')) {
      this.setState({
        data: this.state.initialData,
      })
    }
    return true;
  }

  showTooltipData = (data) => {
    const zeData = this.state.data.find((d) => d.redirectDate === data.label)
    if (zeData)
      return (
        <div style={{backgroundColor: 'rgb(48,48,48)', color: 'rgb(211,175,95)', borderRadius: '10px'}}>
          <div style={{padding: '1rem'}}>
            <div>Date : <b>{zeData.redirectDate}</b></div>
            <div>Activations : <b>{zeData.redirectCount}</b></div>
          </div>
        </div>
      )
    return;
  }

  render() {
    if (!this.props.frameId && !this.props.targets && !this.props.zones) {
      return (
        <Container className="frame-container">
          <Spinner animation="border" classNAME="spinner-border-custom"/>
        </Container>
      )
    } else {
      return (
        <React.Fragment>
          <h4 style={{margin: '1rem 0 1rem 0', fontWeight: 'bold', color: '#B59A4F'}}>TIMELINE</h4>
          <div style={{marginBottom: '2rem', display: 'flex', flexDirection: 'row', width: '100%'}}>
            <div style={{display: 'flex', flexDirection: 'column', width: '50%', marginRight: '2rem'}}>
              <h4>{this.props.t('frame.target')}</h4>
              <Form.Control as="select"
                            value={this.state.selectedLabel}
                            onChange={(event) => this.setState({
                              selectedLabel: event.target.value,
                              selectedZone: this.props.t('frame.selectOne')
                            })}
              >
                {this.state.labels.map((label, index) => <option key={`label-item-${index + 1}`}>{label}</option>)}
              </Form.Control>
            </div>
            {this.state.selectedLabel !== this.props.t('frame.selectOne') &&
            <React.Fragment>
              <div style={{display: 'flex', flexDirection: 'column', width: '50%'}}>
                <h4>{this.props.t('frame.zone')}</h4>
                <Form.Control as="select"
                              value={this.state.selectedZone}
                              onChange={(event) => this.setState({selectedZone: event.target.value})}
                >
                  {this.state.zones.map(zone => zone.label === this.state.selectedLabel &&
                    zone.zones.map(zoneNumber => <option key={`zone-item-${zoneNumber}`}>{zoneNumber}</option>))
                  }
                </Form.Control>
              </div>
            </React.Fragment>
            }
          </div>
          <ResponsiveContainer width="100%" height={200}>
            <LineChart
              // width={600}
              // height={300}
              data={this.state.data}
              margin={{top: 5, right: 30, left: 0, bottom: 5}}
            >
              <XAxis dataKey="redirectDate" style={{fontSize: '14px'}} tickFormatter={(data) => data.slice(0, 7)}/>
              <YAxis/>
              <CartesianGrid strokeDasharray="3 3"/>
              <Tooltip content={this.showTooltipData}/>
              <Legend/>
              <Line name="Activations" type="monotone" dataKey="redirectCount" stroke="#B59A4F"/>
            </LineChart>
          </ResponsiveContainer>
        </React.Fragment>
      )
    }
  }
}

export default withTranslation('translate')(Stats);
