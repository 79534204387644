import React, { Component } from 'react';
import {Container, Row, Col, Table, Button, Form, ButtonGroup, Spinner} from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { withTranslation } from 'react-i18next';
import { withAlert } from 'react-alert';
import PageToggle from '../PageToggle/PageToggle';
import '../Frame/Frame.css';

import CreateFrame from './CreateFrame';

import * as API from '../../../API'

class Frames extends Component {

  constructor(props, context) {
    super(props, context);

    this.token = localStorage.getItem('myartadmin.token');

    this.searchTimeout = null;

    this.state = {
      showModal: false,
      loading: false,
      frames: [],
      filters: {
        id: '',
        name: '',
        account: '',
        externalId: ''
      },
      sortField: 'id',
      sortOrder: 'ASC',
      offset: 0,
      totalFrames: 0
    }

  }

  componentDidMount() {

    if (this.token === undefined || this.token === null) {
      this.props.alert.error(this.props.t('frames.loadError'));
    } else {
      this.refreshFrames();
    }
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state.filters.id !== prevState.filters.id
      || this.state.filters.name !== prevState.filters.name
      || this.state.filters.account !== prevState.filters.account
      || this.state.filters.externalId !== prevState.filters.externalId) {
      if (this.searchTimeout !== null) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(() => this.refreshFrames(), 500);
    }

  }

  refreshFrames() {
    API.fetchFrames(this.state.filters, this.state.sortField, this.state.sortOrder, this.state.offset, this.token)
      .then(result => {
        this.setState({ totalFrames: result.totalFrames, loading: false, frames: result.data })
      }).catch(() => {
		this.props.alert.error(this.props.t('frames.loadError'));
      })
  }

  setFilter(field, value) {
    const filters = { ...this.state.filters };
    filters[field] = value;
    this.setState({ offset: 0, filters });
  }

  changeSort(field) {
    if (this.state.sortField === field) {
      this.setState({ sortOrder: this.state.sortOrder === 'ASC' ? 'DESC' : 'ASC' }, () => this.refreshFrames());
    } else {
      this.setState({ sortField: field, sortOrder: 'ASC' }, () => this.refreshFrames());
    }
  }

  render() {

    if (this.state.loading) {
      return (
        <Container className="frame-container">
          <Spinner animation="border" className="spinner-border-custom" />
        </Container>
      )
    }

    return (
      <Container className="Common-Container">
        <Row>
          <Col md={12}>
            <PageToggle />
          </Col>
        </Row>
        <Row>
          <Col md={3}>
            <h1>{this.props.t('frames.title')}</h1>
          </Col>
          <Col md={9} className="text-right-nav">
            <Button className="mr-sm-2" style={{marginBottom: '0.5rem', marginRight: '0.5rem'}} onClick={() => this.props.history.push('/admin/super-planifications')}
              variant="gold"><i className="fas fa-clock"></i> {this.props.t('frames.superPlanification')}</Button>
            <Button className="mr-sm-2" style={{marginBottom: '0.5rem', marginRight: '0.5rem'}} onClick={() => this.props.history.push('/admin/redirections')}
              variant="gold"><i className="fas fa-table"></i> {this.props.t('frames.synthetisis')}</Button>
            <Button style={{marginBottom: '0.5rem', marginRight: '0.5rem'}} onClick={() => this.setState({ showModal: true })}
              variant="gold"><i className="fas fa-plus"></i> {this.props.t('frames.new')}</Button>
          </Col>
        </Row>
        <Row>
          <Col md={1} style={{ padding: '0 0 0 10px' }}>
            <p style={{ width: '100%' }} className="mt-sm-1">
              <strong>{this.props.t('frames.filter-title')}</strong>
            </p>
          </Col>
          <Col md={6}>
            <Form>
              <Form.Row>
                <Form.Group as={Col} controlId="frames-id-filter">
                  <Form.Control value={this.state.filters.id}
                    onChange={(event) => this.setFilter('id', event.target.value)}
                    type="number"
                    placeholder={this.props.t('frames.idfilter')} />
                </Form.Group>
                <Form.Group as={Col} controlId="frames-name-filter">
                  <Form.Control value={this.state.filters.name}
                    onChange={(event) => this.setFilter('name', event.target.value)}
                    type="text"
                    placeholder={this.props.t('frames.name-filter')} />
                </Form.Group>
                <Form.Group as={Col} controlId="frames-account-filter">
                  <Form.Control value={this.state.filters.account}
                    onChange={(event) => this.setFilter('account', event.target.value)}
                    type="text"
                    placeholder={this.props.t('frames.accountfilter')} />
                </Form.Group>
                <Form.Group as={Col} controlId="frames-folder-filter">
                  <Form.Control value={this.state.filters.externalId}
                    onChange={(event) => this.setFilter('externalId', event.target.value)}
                    type="text"
                    placeholder={this.props.t('frames.folderfilter')} />
                </Form.Group>
              </Form.Row>
            </Form>
          </Col>
        </Row>
        {this.state.frames.length === 0 &&
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <p className="mt-sm-4 text-center"><strong>{this.props.t('frames.noframes')}</strong></p>
            </Col>
          </Row>
        }
        {this.state.frames.length > 0 &&
          <Row className="justify-content-md-center">
            <Col md={12}>
              <Table variant="custom" size="sm" responsive="md" borderless>
                <thead>
                  <tr>
                    <th className={'clickable' + (this.state.sortField === 'id' ? ' sorted-' + this.state.sortOrder : '')}
                      onClick={() => this.changeSort('id')}>
                      {this.props.t('frames.id')}
                    </th>
                    <th className={'clickable' + (this.state.sortField === 'external_id' ? ' sorted-' + this.state.sortOrder : '')}
                      onClick={() => this.changeSort('external_id')}>
                      {this.props.t('frames.externalId')}
                    </th>
                    <th className={'clickable' + (this.state.sortField === 'label' ? ' sorted-' + this.state.sortOrder : '')}
                      onClick={() => this.changeSort('label')}>
                      {this.props.t('frames.name')}
                    </th>
                    <th className={'clickable' + (this.state.sortField === 'redirectCount' ? ' sorted-' + this.state.sortOrder : '')}
                      onClick={() => this.changeSort('redirectCount')} >
                      {this.props.t('frames.activations')}
                    </th>
                    <th>{this.props.t('frames.targets')}</th>
                    <th>{this.props.t('frames.accounts')}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.frames.map((t, i) => {
                      return <tr key={t.id} className={i % 2 === 0 ? 'even' : 'odd'}>
                        <td>{t.id}</td>
                        <td>{t.externalId}</td>
                        <td style={{whiteSpace:'nowrap'}}>{t.label}</td>
                        <td style={{backgroundColor: '#B59A4F'}}>{t.redirectCount}</td>
                        <td>{t.targets.map(t => t.label).join(', ')}</td>
                        <td>{t.accounts.map(a => a.login).join(', ')}</td>
                        <td>
                          <LinkContainer to={'/admin/frames/' + t.id}>
                            <Button variant="gold" size="sm" className="small-button"><i className="fas fa-edit" style={{transform: 'translate(1px, 3px)'}}></i></Button>
                          </LinkContainer>
                        </td>
                      </tr>
                    })
                  }
                </tbody>
              </Table>
            </Col>
            {this.state.totalFrames > 10 &&
              <Col md={4} className="text-right">
                <ButtonGroup>
                  <Button disabled={this.state.offset === 0} onClick={() => this.setState({ offset: 0 }, () => this.refreshFrames())} variant="gold">&lt;&lt;</Button>
                  <Button disabled={this.state.offset === 0} onClick={() => this.setState({ offset: Math.max(this.state.offset - 10, 0) }, () => this.refreshFrames())} variant="gold">&lt;</Button>
                  <Button variant="light">{this.state.offset + 1} - {Math.min(this.state.offset + 10, this.state.totalFrames)}</Button>
                  <Button disabled={this.state.offset + 10 >= this.state.totalFrames} onClick={() => this.setState({ offset: this.state.offset + 10 }, () => this.refreshFrames())} variant="gold">&gt;</Button>
                  <Button disabled={this.state.offset + 10 >= this.state.totalFrames} onClick={() => this.setState({ offset: (Math.floor(this.state.totalFrames / 10) * 10) }, () => this.refreshFrames())} variant="gold">&gt;&gt;</Button>
                </ButtonGroup>
              </Col>
            }
          </Row>
        }
        <CreateFrame show={this.state.showModal} onHide={() => this.setState({ showModal: false })} />
      </Container>
    );
  }

}

export default withTranslation('translate')(withAlert()(Frames));
