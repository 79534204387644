import React, { Component } from 'react';
import { Container, Row, Col, Table, Button, Form } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { withAlert } from 'react-alert';

import * as moment from 'moment';

import Datetime from 'react-datetime';

import PageToggle from '../PageToggle/PageToggle';

import * as API from '../../../API';

class SuperPlanification extends Component {

  constructor(props, context) {

    super(props, context);

    this.token = localStorage.getItem('myartadmin.token');

    this.state = {
      selected: '0',
      planifications: [],
      framesPlanifications: [],
      startDate: moment().startOf('day').toDate(),
      endDate: moment().startOf('day').add(1, 'day').toDate(),
      name: '',
      targetUrl: '',
      videoTitle: '',
      videoDescription: '',
      videoFile: '',
      mode: 'targetUrl',
      uploading: false
    }

  }

  initializeContainer() {
    this.setState({
      targetUrl: '',
      mode: 'targetUrl',
      upload: false,
      videoDescription: '',
      videoTitle: '',
      videoFile: '',
      uploading: false
    }, () => this.refreshData());
  }

  componentDidMount() {
    this.refreshData();
  }

  refreshData() {
    API.fetchSuperPlanifications(this.token).then(planifications => {
      this.setState({ planifications })
    }).catch(() => {
      this.props.alert.error(this.props.t('superplanification.loadError'));
    });
  }

  selectPlanification(planificationName) {
    if (planificationName === "0") {
      this.setState({
        selected: '0',
        framesPlanifications: []
      })
    } else {
      this.setState({
        selected: this.state.planifications.find(p => p.name === planificationName)
      }, () => {
        API.fetchSuperPlanification(planificationName, this.token).then(framesPlanifications => {
          this.setState({ framesPlanifications })
        }).catch(() => {
          this.props.alert.error(this.props.t('superplanification.loadError'));
        });
      })
    }

  }

  addSuperPlanification() {

	if (this.state.mode !== 'targetUrl') {
		this.setState({
		  uploading: true
		}, () => {
		  API.uploadVideoForSuperPlanification(this.token, this.state.videoFile, this.state.videoTitle, this.state.videoDescription, this.state.mode,
			moment(this.state.startDate).toISOString(),
			moment(this.state.endDate).toISOString(),
			this.state.name).then(() => {
				alert(this.props.t('home.videouploadalert'));
				this.initializeContainer();
			  });
		})
	  } else {
		API.addSuperPlanification({
			beginDate: moment(this.state.startDate).toISOString(),
			endDate: moment(this.state.endDate).toISOString(),
			targetUrl: this.state.targetUrl,
			name: this.state.name
		}, this.token).then(() => {
			this.setState({
		  			startDate: moment().startOf('day').toDate(),
		  			endDate: moment().startOf('day').add(1, 'day').toDate(),
			  		name: '',
			  		targetUrl: ''
				}, this.refreshData)
		}).catch(() => {
			this.props.alert.error(this.props.t('superplanification.createError'));
		})
	  }
  }

  deletePlanification(frameId, targetLabel, zoneIdx) {
    if (window.confirm(this.props.t('home.confirmDeletePlanif'))) {
      API.deleteZonePlanification(frameId, targetLabel, zoneIdx, this.state.selected.targetUrl, this.token)
        .then(() => this.selectPlanification(this.state.selected.name))
    }
  }

  deleteSuperPlanification() {
    if (window.confirm(this.props.t('home.confirmDeletePlanif'))) {
      API.deleteSuperPlanification(this.state.selected.name, this.token)
        .then(() => {
          this.refreshData();
          this.selectPlanification("0")
        });
    }
  }


  render() {

    return (


      <Container className="Common-Container"
	  onEnter={this.initializeContainer.bind(this)}>
        <Row>
          <Col md={12}>
            <PageToggle />
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <h1>{this.props.t('superplanification.title')}</h1>
          </Col>
        </Row>
        <Row>
          <Col md={1} style={{ padding: '0 0 0 10px' }}>
            <p style={{ width: '100%' }} className="mt-sm-1">
              <strong>{this.props.t('superplanification.create')}</strong>
            </p>
          </Col>
          <Col md={8}>
            <Form>
              <Form.Row>
                <Form.Group as={Col} controlId="superplan-name">
                  <Form.Control value={this.state.name} onChange={(event) => this.setState({ name: event.target.value })} type="text" placeholder={this.props.t('superplanification.name')} />
                </Form.Group>
			</Form.Row>
			<Form.Row>
				<Form.Group as={Col} controlId="superplan-url">
                  <Form.Label>{this.props.t('home.planificationTo')}</Form.Label>
                  <Form.Control value={this.state.mode} onChange={(event) => this.setState({ mode: event.target.value })} as="select">
                    <option value="targetUrl">URL</option>
                  	  {!this.props.showProvider &&
                    	<option value="videos">Videas</option>
                  	  }
                  	  {!this.props.showProvider &&
                    	<option value="youtube">Youtube</option>
                  	  }
                  </Form.Control>
              	</Form.Group>

				  {this.state.mode === 'targetUrl' &&
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>{this.props.t('home.targetUrl')}</Form.Label>
                  <Form.Control
                    id="new-frame-externalId"
                    onChange={(event) => this.setState({ targetUrl: event.target.value })}
                    value={this.state.targetUrl}
                    type="text"
                    placeholder={this.props.t('home.targetUrl')}
                  />
                </Form.Group>
              </Col>
            </Row>
          }
		  {this.state.mode !== 'targetUrl' &&
            <Row>
              <Col>
                <Form.Group>
                  <Form.Label>{this.props.t('home.videouploadtitle')}</Form.Label>
                  <Form.Control
                    onChange={(event) => this.setState({ videoTitle: event.target.value })}
                    value={this.state.videoTitle}
                    type="text"
                    placeholder={this.props.t('home.videouploadtitle')}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>{this.props.t('home.videouploaddesc')}</Form.Label>
                  <Form.Control
                    onChange={(event) => this.setState({ videoDescription: event.target.value })}
                    value={this.state.videoDescription}
                    type="text"
                    placeholder={this.props.t('home.videouploaddesc')}
                  />
                </Form.Group>
              </Col>
              <Col>
                <Form.Group>
                  <Form.Label>{this.props.t('home.videouploadfile')}</Form.Label>
                  <Form.Control
                    id="new-frame-externalId"
                    onChange={(event) => {
                      this.setState({ videoFile: event.target.files[0] !== undefined ? event.target.files[0] : '' })
                    }}
                    type="file"
                    placeholder={this.props.t('home.videouploadfile')}
                  />
                </Form.Group>
              </Col>
            </Row>
          }
              </Form.Row>
              <Form.Row>
                <Form.Group style={{ color: '#000000' }} as={Col} controlId="superplan-to">
                  <Datetime
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                    closeOnSelect={true}
                    value={this.state.startDate}
                    onChange={(d) => {
                      if (typeof d !== 'string') {
                        this.setState({ startDate: d.toDate() })
                      }
                    }} />
                </Form.Group>
                <Form.Group style={{ color: '#000000' }} as={Col} controlId="superplan-from">
                  <Datetime
                    dateFormat="DD/MM/YYYY"
                    timeFormat="HH:mm"
                    closeOnSelect={true}
                    value={this.state.endDate}
                    onChange={(d) => {
                      if (typeof d !== 'string') {
                        this.setState({ endDate: d.toDate() })
                      }
                    }} />
                </Form.Group>
                <Form.Group as={Col} controlId="superplan-url">
                  <Button disabled={this.state.name === ''} onClick={this.addSuperPlanification.bind(this)} variant="gold">{this.props.t('superplanification.create')}</Button>
                </Form.Group>
              </Form.Row>
            </Form>
          </Col>
        </Row>
        <hr style={{ borderTop: '1px #ffffff solid' }} />
        {this.state.planifications.length > 0 &&
          <Row className="mb-4">
            <Col md={{ span: 5, offset: 7 }}>
              <Form.Control as="select" value={this.state.selected === '0' ? '0' : this.state.selected.name} onChange={(event) => this.selectPlanification(event.target.value)}>
                <option value="0">---</option>
                {
                  this.state.planifications.map(p => {
                    return <option key={p.name} value={p.name}>{p.name}</option>;
                  })
                }
              </Form.Control>
            </Col>
          </Row>
        }
        {
          this.state.framesPlanifications.length > 0 &&
          <Row className="mb-4">
            <Col md={12}>
              <Button onClick={() => this.deleteSuperPlanification()} variant="gold"><i className="fas fa-trash"></i> {this.props.t('superplanification.deletesuper')}</Button>
            </Col>
            <Col md={12}>
              <Table variant="custom" size="sm" responsive="md" borderless>
                <thead>
                  <tr>
                    <th>{this.props.t('superplanification.frameLabel')}</th>
                    <th>{this.props.t('superplanification.target')}</th>
                    <th>{this.props.t('superplanification.zone')}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {this.state.framesPlanifications.map((fp, i) => {
                    return <tr key={i} className={i % 2 === 0 ? 'even' : 'odd'}>
                      <td>{fp.frameLabel}</td>
                      <td>{fp.targetLabel}</td>
                      <td>{fp.zoneIdx}</td>
                      <td><Button onClick={() => this.deletePlanification(fp.frameId, fp.targetLabel, fp.zoneIdx)} variant="gold"><i className="fas fa-trash"></i></Button></td>
                    </tr>
                  })
                  }
                </tbody>
              </Table>
            </Col>
          </Row>
        }

      </Container>
    );
  }

}

export default withTranslation('translate')(withAlert()(SuperPlanification));
