import React, { Component } from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import Footer from '../Footer/Footer'
import { withAlert } from 'react-alert'
import ResetForm from './ResetForm';

import './ResetPassword.css';

class ResetPassword extends Component {



  render() {

    return (
      <div className="Signin" >
        <div className="Signin-Form-Container">
          <Container fluid>
            <Row>
              <Col>
                <p className="Signin-Copyright text-center">{this.props.t('resetpassword.help')}</p>
              </Col>
            </Row>
            <Row>
              <Col md={12}>
                <ResetForm />
              </Col>
            </Row>

          </Container>
        </div>
        <Footer />
      </div>
    );
  }

}

export default withTranslation('translate')(withAlert()(ResetPassword));