
import React, { Component } from 'react';
import { Row, Col, Button, Form, Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { withAlert } from 'react-alert';

class Communicate extends Component {

    constructor(props, context) {

        super(props, context);

        this.state = {
            addedNumbers: []
        }

    }

    render() {

        return (
            <Modal size="lg" show={this.props.show} onHide={() => this.setState({ addedNumbers: [] }, this.props.onHide)}>
                <Modal.Header closeButton>
                    <Modal.Title>{this.props.t('frame.communicate')}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Row className="mb-sm-2">
                        <Col md={12}>
                            <h5>{this.props.t('frame.communicateNumbers')}</h5>
                        </Col>
                        <Col md={4}>
                            <Form.Row>
                                <Col md={10}>
                                    <Form.Control
                                        onChange={(event) => this.setState({ newCommunicateNumber: event.target.value })}
                                        value={this.state.newCommunicateNumber || ''}
                                        type="text"
                                        placeholder={this.props.t('frame.communicateNumbers')}
                                    />
                                </Col>
                                <Col md={2}>
                                    <Button variant="gold"
                                        className="small-button"
                                        disabled={this.state.newCommunicateNumber !== undefined && this.state.newCommunicateNumber.length < 2}
                                        onClick={() => this.setState({ newCommunicateNumber: '', addedNumbers: this.state.addedNumbers.concat(this.state.newCommunicateNumber) })}><i className="fas fa-save"></i></Button>
                                </Col>
                            </Form.Row>
                        </Col>
                        <Col md={8}>
                            <p style={{ border: '1px dashed #808080', padding: '5px', borderRadius: '2px', display: 'block', height: '50px', overflowY: 'auto' }}>{this.state.addedNumbers.length > 0 ? this.state.addedNumbers.join(', ') : ''}</p>
                        </Col>
                    </Row>
                    <Row>
                        <Col md={12}>
                            <h5>{this.props.t('frame.communicateContent')}</h5>
                            <textarea style={{ width: '400px', height: '100px' }} placeholder={this.props.t('frame.communicateContentSample')}></textarea>
                        </Col>
                    </Row>
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled variant="secondary">{this.props.t('frame.validate')}</Button>
                    <Button onClick={() => this.setState({ addedNumbers: [] }, this.props.onHide)} variant="gold">{this.props.t('frame.close')}</Button>
                </Modal.Footer>
            </Modal>
        );
    }

}

export default withTranslation('translate')(withAlert()(Communicate));