import React from "react";
import {CircularProgress} from "@material-ui/core";
import {makeStyles} from "@material-ui/core/styles";

const styles = makeStyles(({
  fullWidth: {
    position: 'absolute',
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    minHeight: '400px',
  },
  loader: {
    width: '100%',
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: "column",
  },
}));

export default function Loading(props) {
  const classes = styles();
  return (
    <div className={classes.fullWidth}>
      <div className={classes.loader}>
        <CircularProgress color="primary"/>
        <span>{props.message}</span>
      </div>
    </div>
  );
}
