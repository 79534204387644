import React, {Component} from 'react';
import {Button, Col, Container, Row} from 'react-bootstrap';
import {withTranslation} from 'react-i18next';
import {withAlert} from 'react-alert';
import {Redirect} from "react-router-dom";
import * as API from '../../../API';
import CampaignForm from "./CampaignForm";
import './Campaign.css';

class Campaign extends Component {

  constructor(props, context) {
    super(props, context);

    this.token = localStorage.getItem('myartadmin.token');
    this.campaignId = this.props.match.params.campaignId;

    this.state = {
      campaign: {},
      loading: true,
      isFormOk: false,
    }
    this.handleOnChange = this.handleOnChange.bind(this);
    this.isFormOk = this.isFormOk.bind(this);
    this.checkAndSend = this.checkAndSend.bind(this);
  }

  componentDidMount() {

    if (this.token === undefined || this.token === null) {
      this.props.alert.error(this.props.t('campaigns.loadError'));
    } else {
      //this.props.history.push('/home')
      this.refreshCampaign();
    }
  }

  removeCampaign() {
    const promises = [];
    promises.push(API.removeCampaign(this.campaignId, this.token));

    Promise.all(promises).then(() => {
      this.refreshCampaign();
      this.props.alert.success(this.props.t('campaigns.removeSuccess'));
      this.setState({redirect: "/admin/campaigns"});
    })
      .catch(() => {
        this.props.alert.error(this.props.t('campaigns.removeError'));
      });
  }

  globalSave() {
    this.checkAndSend();
  }

  refreshCampaign() {
    API.fetchCampaign(this.campaignId, this.token).then(campaign => {
      this.setState({
        campaign: campaign,
        loading: false,
      })
    }).catch(() => {
      //this.props.alert.error(this.props.t('accounts.loadError'));
    })
  }

  handleOnChange(key, value) {
    this.setState({
      campaign: {
        ...this.state.campaign,
        [key]: value,
      }
    });
  }

  isFormOk(value) {
    // console.log('isFormOk', value);
    this.setState({
      isFormOk: value,
    });
  }

  checkAndSend() {
    this.setState({...this.state, loading: true});
    const {
      id, name, startDate, endDate, cost, durationVideoFan, durationVideoVip, durationVideoFamily, vip, fan, family, codeVip, vipUnique, fanUnique, familyUnique, tags, landingUrl,
      uniqueKey,
      translations,
      imageForm,
      imageHeader,
      logo,
      imageFormUrl,
      headerUrl,
      logoUrl,
    } = this.state.campaign;
    let tmpType;
    tmpType = vip === true ? 'VIP' : '';
    tmpType += fan ? tmpType.length > 0 ? '/FAN' : 'FAN' : '';
    tmpType += family ? tmpType.length > 0 ? '/FAMILY' : 'FAMILY' : '';
    const tmp = {
      id,
      uniqueKey,
      name,
      startDate,
      endDate,
      type: tmpType,
      cost,
      durationVideoFan,
      durationVideoVip,
      durationVideoFamily,
      tags: tags,
      landingUrl: landingUrl,
      translations,
      imageFormUrl,
      headerUrl,
      logoUrl,
    };
    if (vip) {
      tmp.vip = true;
      tmp.codeVip = codeVip;
      tmp.vipUnique = vipUnique;
    }
    if (family) {
      tmp.family = true;
      tmp.familyUnique = familyUnique;
    }
    if (fan) {
      tmp.fan = true;
      tmp.fanUnique = fanUnique;
    }
    API.updateCampaign(tmp, this.token, imageForm, imageHeader, logo).then(() => {
      this.refreshCampaign();
      this.props.alert.success(this.props.t('campaigns.updateSuccess'));
    });
  }

  state = {redirect: null};

  render() {

    if (this.state.redirect) {
      return <Redirect to={this.state.redirect}/>
    }
    if (this.state.loading) {
      return <div></div>;
    }

    return (
      <Container className="Common-Container" style={{paddingTop: '0'}}>
        <Row>
          <Col md={{span: 6, offset: 6}} className="text-right">
            <Button onClick={this.globalSave.bind(this)} className="mr-sm-2 small-button" style={{marginRight: '0.5rem'}} variant="gold" title="Enregistrer" disabled={!this.state.isFormOk}>
              <i className="fas fa-save"></i>
            </Button>
            <Button onClick={(e) => {
              if (window.confirm(this.props.t('campaign.confirmDeletion'))) this.removeCampaign(this)
            }} className="mr-sm-2 small-button" variant="gold" title="Supprimer">
              <i className="fas fa-eraser"></i>
            </Button>
          </Col>
        </Row>
        <Row>
          <Col>
            <h2>{this.props.t('campaigns.title')}</h2>
            <CampaignForm campaign={this.state.campaign} onHandleChange={this.handleOnChange} isFormOk={this.isFormOk} checkAndSend={this.checkAndSend} />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default withTranslation('translate')(withAlert()(Campaign));
