import React, {Component} from 'react';
import {Modal} from 'react-bootstrap';
import {withTranslation} from 'react-i18next';
import {withAlert} from 'react-alert';
import * as moment from 'moment';
import Toast from 'react-bootstrap/Toast'

import * as API from '../../../API';
import CampaignForm from "./CampaignForm";
import Loading from "../../loader/Loading";

class CampaignCreation extends Component {

  constructor(props, context) {

    super(props, context);

    this.state = {
      campaign: {
        uniqueKey: '',
        name: '',
        startDate: moment().startOf('day').toDate(),
        endDate: moment().startOf('day').add(1, 'day').toDate(),
        vip: false,
        fan: false,
        family: false,
        cost: '',
        durationVideoFan: '20',
        durationVideoVip: '20',
        durationVideoFamily: '20',
        codeVip: '',
        vipUnique: true,
        fanUnique: true,
        familyUnique: true,
        tags: [],
        landingUrl: '',
        translations: [{lang: 'FR', vip: 'Description VIP', fan: 'Description FAN', family: 'Description Famille'}],
        imageForm: null,
        imageHeader: null,
        logo: null,
      },
      isFormOk: false,
      showToast: false,
      saving: false,
    }
    this.resetState = this.resetState.bind(this);
    this.checkAndSend = this.checkAndSend.bind(this);
    this.setShow = this.setShow.bind(this);
    this.handleOnChange = this.handleOnChange.bind(this);
    this.isFormOk = this.isFormOk.bind(this);
  }

  setShow(value) {
    this.setState({
      showToast: value,
    })
  }

  resetState() {
    const tmp = {
      campaign: {
        uniqueKey: '',
        name: '',
        startDate: moment().startOf('day').toDate(),
        endDate: moment().startOf('day').add(1, 'day').toDate(),
        vip: false,
        fan: false,
        family: false,
        cost: '',
        durationVideoFan: '20',
        durationVideoVip: '20',
        durationVideoFamily: '20',
        codeVip: '',
        vipUnique: true,
        fanUnique: true,
        familyUnique: true,
        tags: [],
        landingUrl: '',
        translations: [{lang: 'FR', vip: 'Description VIP', fan: 'Description FAN', family: 'Description Famille'}],
        imageForm: null,
        imageHeader: null,
        logo: null,
      },
      isFormOk: false,
      showToast: false,
      saving: false,
    };
    this.setState(tmp);
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.campaignTarget.label !== nextProps.campaignTarget.label) {
      this.resetState();
    }
    return true;
  }

  handleOnChange(key, value) {
    if (key === 'uniqueKey') {
      const tmpValue = process.env.REACT_APP_FAN_ART_ZONE + '/' + value;
      this.setState({
        campaign: {
          ...this.state.campaign,
          landingUrl: tmpValue,
          [key]: value,
        }
      });
    } else {
      this.setState({
        campaign: {
          ...this.state.campaign,
          [key]: value,
        },
      });
    }
  }

  isFormOk(value) {
    this.setState({
      isFormOk: value,
    });
  }

  checkAndSend() {
    this.setState({...this.state, saving: true});
    const {
      name, startDate, endDate, cost, durationVideoFan, durationVideoVip, durationVideoFamily, vip, fan, family, codeVip, vipUnique, fanUnique, familyUnique, tags, landingUrl,
      uniqueKey,
      translations,
      imageForm,
      imageHeader,
      logo,
    } = this.state.campaign;
    let tmpType;
    tmpType = vip === true ? 'VIP' : '';
    tmpType += fan ? tmpType.length > 0 ? '/FAN' : 'FAN' : '';
    tmpType += family ? tmpType.length > 0 ? '/FAMILY' : 'FAMILY' : '';
    const tmp = {
      uniqueKey,
      name,
      startDate,
      endDate,
      type: tmpType,
      cost,
      durationVideoFan,
      durationVideoVip,
      durationVideoFamily,
      tags: tags,
      landingUrl: landingUrl,
      translations,
    };
    if (vip) {
      tmp.codeVip = codeVip;
      tmp.vipUnique = vipUnique;
    }
    if (family) {
      tmp.familyUnique = familyUnique;
    }
    if (fan) {
      tmp.fanUnique = fanUnique;
    }
    const tmpTarget = {
      frameId: this.props.frameId,
      label: this.props.campaignTarget.label,
    }
    API.createCampaign(tmp, tmpTarget, this.props.token, imageForm, imageHeader, logo).then(() => {
      this.resetState();
      this.setShow(true);
      this.props.onHide();
    });
  }

  render() {
    return (
      <React.Fragment>
        <div style={{position: 'fixed', top: '140px', right: '20px'}}>
          <Toast onClose={() => this.setShow(false)} show={this.state.showToast} delay={3000} autohide>
            <Toast.Header>
              <img
                src="holder.js/20x20?text=%20"
                className="rounded mr-2"
                alt=""
              />
              <strong className="mr-auto">Merci !</strong>
              <small>Just now</small>
            </Toast.Header>
            <Toast.Body style={{color: 'black'}}>
              {this.props.t('campaigns.form.created')}
            </Toast.Body>
          </Toast>
        </div>
        <Modal size="md" show={this.props.show}
               onHide={this.props.onHide}
        >
          <Modal.Header closeButton>
            <Modal.Title>{this.props.t('campaigns.form.modal-title') + this.props.campaignTarget.label}</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div style={{position: 'fixed', top: '140px', right: '20px'}}>
              <Toast onClose={() => this.setShow(false)} show={this.state.showToast} delay={3000} autohide>
                <Toast.Header>
                  <img
                    src="holder.js/20x20?text=%20"
                    className="rounded mr-2"
                    alt=""
                  />
                  <strong className="mr-auto">{this.props.t('common.thank')}</strong>
                </Toast.Header>
                <Toast.Body style={{color: 'black'}}>
                  {this.props.t('campaigns.form.created')}
                </Toast.Body>
              </Toast>
            </div>
            {this.state.saving ?
              <Loading message={"Enregistrement en cours..."}/>
              :
              <CampaignForm
                onHandleChange={this.handleOnChange}
                campaign={this.state.campaign}
                isFormOk={this.isFormOk}
                checkAndSend={this.checkAndSend}
                token={this.props.token}
              />
            }
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }

}

export default withTranslation('translate')(withAlert()(CampaignCreation));
