import React from 'react';
import PropTypes from 'prop-types';
import {makeStyles} from '@material-ui/core/styles';
import TreeView from '@material-ui/lab/TreeView';
import TreeItem from '@material-ui/lab/TreeItem';
import Typography from '@material-ui/core/Typography';
import MailIcon from '@material-ui/icons/Mail';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import * as API from '../../../API';
import {Container, Spinner} from 'react-bootstrap';
import './campaignManager.css';
import DisplayPlayer from './DisplayPlayer';
import {withTranslation} from 'react-i18next';

const useTreeItemStyles = makeStyles((theme) => ({
  root: {
    // color: 'theme.palette.text.secondary',
    // '&:hover > $content': {
    //   backgroundColor: '#B59A4F',
    // },
    '&:focus > $content, &$selected > $content': {
      backgroundColor: `#B59A4F`,
      color: 'var(--tree-view-color)',
    },
    // '&:focus > $content $label, &:hover > $content $label, &$selected > $content $label': {
    //   backgroundColor: 'transparent',
    // },
  },
  content: {
    color: 'white',
    borderTopRightRadius: theme.spacing(2),
    borderBottomRightRadius: theme.spacing(2),
    paddingRight: theme.spacing(1),
    fontWeight: theme.typography.fontWeightMedium,
    '$expanded > &': {
      fontWeight: theme.typography.fontWeightRegular,
    },
  },
  group: {
    marginLeft: 0,
    '& $content': {
      paddingLeft: theme.spacing(2),
    },
  },
  expanded: {},
  selected: {},
  label: {
    fontWeight: 'inherit',
    color: 'inherit',
  },
  labelRoot: {
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(0.5, 0),
  },
  labelIcon: {
    marginRight: theme.spacing(1),
  },
  labelText: {
    fontWeight: 'inherit',
    flexGrow: 1,
  },
}));

function StyledTreeItem(props) {
  const classes = useTreeItemStyles();
  const {labelText, labelIcon: LabelIcon, labelInfo, color, bgColor, ...other} = props;
  return (
    <TreeItem
      label={
        <div className={classes.labelRoot}>
          <LabelIcon color="inherit" className={classes.labelIcon}/>
          <Typography variant="body2" className={classes.labelText}>
            {labelText}
          </Typography>
          <Typography variant="caption" color="inherit">
            {labelInfo}
          </Typography>
        </div>
      }
      style={{
        '--tree-view-color': color,
        '--tree-view-bg-color': bgColor,
      }}
      classes={{
        root: classes.root,
        content: classes.content,
        expanded: classes.expanded,
        selected: classes.selected,
        group: classes.group,
        label: classes.label,
      }}
      {...other}
    />
  );
}

StyledTreeItem.propTypes = {
  bgColor: PropTypes.string,
  color: PropTypes.string,
  labelIcon: PropTypes.elementType.isRequired,
  labelInfo: PropTypes.string,
  labelText: PropTypes.string.isRequired,
};

class CampaignVideosManager extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tree: [],
      selectedMedias: [],
      videoUrl: '',
      showPlayer: false,
      videoTitle: '',
      expanded: ['parent-1'],
      classes: makeStyles({
        root: {
          height: 264,
          flexGrow: 1,
          maxWidth: 400,
        }
      })
    }
    this.token = localStorage.getItem('myartadmin.token');
    API.fetchCampaignVideos(this.props.folderId, this.token)
      .then(tree => {
        this.setState({
          tree: tree && tree.children.length > 0 ? [tree] : null,
          loading: false,
        })
      }).catch(() => {
      // this.props.alert.error(this.props.t('accounts.loadError'));
    })
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.props.folderId !== nextProps.folderId) {
      API.fetchCampaignVideos(nextProps.folderId, this.token)
        .then(tree => {
          this.setState({
            tree: tree && tree.children.length > 0 ? [tree] : null,
            loading: false,
          })
        }).catch(() => {
        // this.props.alert.error(this.props.t('accounts.loadError'));
      })
    }
    return true;
  }

  handleSelectFolder(e, videosArray) {
    this.setState({
      selectedMedias: videosArray,
    })
  }

  handleExpand(e, item) {
    const tmpExpanded = [...this.state.expanded];
    if (!tmpExpanded.includes(item)) {
      tmpExpanded.push(item);
    } else {
      tmpExpanded.splice(tmpExpanded.findIndex(stateItem => stateItem === item), 1)
    }
    this.setState({
      expanded: tmpExpanded,
    })
  }

  handleRecursiveTree(item, primaryIndex) {
    if (item.children && item.children.length > 0) {
      return (
        <StyledTreeItem
          nodeId={`parent-${item.name}`}
          labelText={item.name}
          labelIcon={MailIcon}
          key={`parent-${item.name}`}
        >
          {item.children.map(subItem => this.handleRecursiveTree(subItem, primaryIndex))}
        </StyledTreeItem>
      )
    } else {
      return (
        <StyledTreeItem
          nodeId={`child-${item.name}`}
          labelText={item.name}
          labelIcon={MailIcon}
          key={`child-${item.name}`}
          labelInfo={item.medias.length.toString()}
          onLabelClick={(e) => this.handleSelectFolder(e, item.medias)}
        />
      )
    }
  }

  handleSelectedMedia(media) {
    console.log("media =>", media)
    API.getStreamFromMedia(media.uid, this.token)
      .then(res => this.setState({videoUrl: res, videoTitle: media.name, showPlayer: true}))
    this.setState({
      selectedMedia: media,
      showPlayer: true,
    })
  }

  render() {
    if (!this.state.tree || this.state.tree.length <= 0) {
      if (this.state.loading) {
        return (
          <Container className="frame-container">
            <Spinner animation="border" className="spinner-border-custom"/>
          </Container>
        )
      } else {
        return (
          <Container className="frame-container">
            <div>{this.props.t('frame.no-video')}</div>
          </Container>
        )
      }
    } else {
      return (
        <React.Fragment>
          <div className="campaign-video-manager-main-container">
            <div className="campaign-video-manager-tree-container">
              <h3>{this.props.t('campaigns.folders')}</h3>
              <div className="campaign-video-manager-tree-subcontainer">
                <TreeView
                  className={this.state.classes.root}
                  defaultExpanded={['parent-1']}
                  expanded={this.state.expanded}
                  defaultCollapseIcon={<ArrowDropDownIcon/>}
                  defaultExpandIcon={<ArrowRightIcon/>}
                  defaultEndIcon={<div style={{width: 24}}/>}
                  onNodeSelect={(e, v) => this.handleExpand(e, v)}
                >
                  {this.state.tree.map((item, primaryIndex) => {
                    return (
                      <StyledTreeItem
                        nodeId={`parent-${primaryIndex + 1}`}
                        labelText={item.name}
                        labelIcon={MailIcon}
                        key={`parent-${primaryIndex + 1}`}
                      >
                        {item.children.length > 0 &&
                        item.children.map(subItem => (
                          this.handleRecursiveTree(subItem, primaryIndex)
                        ))
                        }
                      </StyledTreeItem>
                    )
                  })
                  }
                </TreeView>
              </div>
            </div>
            <div className="campaign-video-manager-video-container">
              <h3>{this.props.t('campaigns.content')}</h3>
              <div className="campaign-video-manager-video-subcontainer">
                <div className="campaign-video-manager-video-thumbnail-container">
                  {this.state.selectedMedias.map((media, index) => (
                    <div className="campaign-video-media-list-container" key={`media-item-${index + 1}`}>
                      <div
                        className="campaign-video-media-list-item"
                        onClick={() => this.handleSelectedMedia(media)}
                        style={{
                          background: `url('${media.thumbnail}')`,
                          backgroundSize: 'cover',
                          backgroundRepeat: 'no-repeat',
                          backgroundPosition: 'center'
                        }}
                      />
                      <span className="campaign-video-media-list-item-title">{media.name}</span>
                    </div>
                  ))

                  }
                </div>
              </div>
            </div>
          </div>
          <DisplayPlayer
            show={this.state.showPlayer}
            media={this.state.videoUrl}
            title={this.state.videoTitle}
            onHide={() => {
              this.setState({showPlayer: false});
            }}/>
        </React.Fragment>
      )
    }
    ;
  }
}

export default withTranslation('translate')(CampaignVideosManager);
