import React, { Component } from 'react';
import { Row, Col, Button, Navbar, Nav, Container, Dropdown } from 'react-bootstrap';
import { withRouter } from 'react-router';
import { withTranslation } from 'react-i18next';

import frLogo from './fr.png';
import enLogo from './en.png';
import esLogo from './es.png';

import './Navigation.css';

import adpLogo from './ads.png';
import MasterPassword from './MasterPassword';
import PageToggle from '../PageToggle/PageToggle';

class Navigation extends Component {

  constructor(props, context) {
    super(props, context);

    this.login = localStorage.getItem('myartadmin.login');

    this.state = {
	  showModal : false,
    showMenu: false,
    windowWidth: 0,
	}
  this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
	this.showMenu = this.showMenu.bind(this);
	this.closeMenu = this.closeMenu.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({ windowWidth: window.innerWidth });
  }

  showMenu(event) {
	event.preventDefault();

	this.setState({ showMenu: true }, () => {
	  document.addEventListener('click', this.closeMenu);
	});
  }

  closeMenu() {
	this.setState({ showMenu: false }, () => {
	  document.removeEventListener('click', this.closeMenu);
    });
  }

  signout() {
    localStorage.removeItem('myartadmin.token');
    localStorage.removeItem('myartadmin.login');
    this.props.history.push('/');
  }

  handleLanguageFlag() {
    // console.log("language =>", this.props.i18n.language)
    if(this.props.i18n.language.indexOf("-") > 0) {
      this.props.i18n.language = this.props.i18n.languages[1];
    }
    switch(this.props.i18n.language) {
      case 'fr':
        return <img className="flag" alt="fr lang" onClick={() => this.props.i18n.changeLanguage('fr')} src={frLogo} />
      case 'en':
        return <img className="flag" alt="en lang" onClick={() => this.props.i18n.changeLanguage('en')} src={enLogo} />
      case "es":
        return <img className="flag" alt="es lang" onClick={() => this.props.i18n.changeLanguage('es')} src={esLogo} />
      default:
        return <img className="flag" alt="en lang" onClick={() => this.props.i18n.changeLanguage('en')} src={enLogo} />
    }
  }

  render() {

    return (
		<div>
      <Navbar expand="lg" variant="custom" fixed="top" style={{width: '100vw', height: '80px', zIndex: '90'}}>
        <Container>
          <Navbar.Brand>
            <img src={adpLogo} alt="adp logo" className="image-size-navigation-bar" />
          </Navbar.Brand>
          <div style={{position: 'relative', display: 'flex'}}>
            {this.state.windowWidth <= 399 ?
              <React.Fragment>
                <Button variant="success" href="tel:+33665512312"><i className="fas fa-phone"></i></Button>
                  <Dropdown drop='down'>
                    <Dropdown.Toggle variant="gold" id="dropdown-basic" style={{height: '40px', width: '50px', marginLeft: '0.5rem'}}>
                      <i className="fas fa-bars"></i>
                    </Dropdown.Toggle>

                    <Dropdown.Menu className="settings-dropdown-content-container">
                      {this.login === 'admin' &&
                        <Dropdown.Item href="#/action-1" className="settings-dropdown-item">
                          <Button onClick={() => this.setState({ showModal: true })}  style={{width: '45px'}} variant="gold"><i className="fas fa-lock"></i></Button>
                          <MasterPassword show={this.state.showModal} onHide={() => this.setState({ showModal: false })} />
                        </Dropdown.Item>
                      }
                      <Dropdown.Item href="#/action-1" className="settings-dropdown-item">
                        <Button onClick={this.showMenu} variant="gold" style={{width: '45px'}}> {this.handleLanguageFlag()} </Button>
                      </Dropdown.Item>
                      <Dropdown.Item href="#/action-2" className="settings-dropdown-item">
                        <Button onClick={this.signout.bind(this)} style={{width: '45px'}} variant="gold"><i className="fas fa-sign-out-alt"></i> </Button>
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </React.Fragment>
                :
                <React.Fragment>
                  <Nav className="mr-2">
                    <Button variant="success" href="tel:+33665512312"><i className="fas fa-phone"></i></Button>
                  </Nav>
                  {this.login === 'admin' &&
                    <Nav className="mr-2">
                      <Button onClick={() => this.setState({ showModal: true })} variant="gold"><i className="fas fa-lock"></i></Button>
                      <MasterPassword show={this.state.showModal} onHide={() => this.setState({ showModal: false })} />
                    </Nav>
                  }
                  <Nav className="mr-2">
                    <Button onClick={this.showMenu} variant="gold" style={{width: '45px'}}> {this.handleLanguageFlag()} </Button>
                  </Nav>

                  <Nav>
                    <Button onClick={this.signout.bind(this)} variant="gold"><i className="fas fa-sign-out-alt"></i> </Button>
                  </Nav>
                </React.Fragment>
            }
          </div>
        </Container>
      </Navbar>
	  {
			this.state.showMenu
				? (
					<Navbar variant="custom" fixed="top" className="language-selector">
					<Container>
						<Row style={{flex: 1, flexDirection: 'row-reverse'}}>
						<Col className="mb-sm-4 text-right padding-language-content">
							<div  className="menu btn btn-gold">
								<img className="flag" alt="fr lang" onClick={() => this.props.i18n.changeLanguage('fr')} src={frLogo} />
								<img className="flag" alt="en lang" onClick={() => this.props.i18n.changeLanguage('en')} src={enLogo} />
							<img className="flag" alt="es lang" onClick={() => this.props.i18n.changeLanguage('es')} src={esLogo} />
							</div>
						</Col>
						</Row>
					</Container>
					</Navbar>
				)
				: (
					null
				)
			}
	  </div>
    );
  }
}

export default withRouter(withTranslation('translate')(Navigation));
