import React, {Component} from 'react';
import {Button, Modal} from 'react-bootstrap';
import {withTranslation} from 'react-i18next';
import {withAlert} from 'react-alert';


class DisplayPlayer extends Component {

  constructor(props, context) {

    super(props, context);

    this.state = {
    }
  }

  render() {
    return (
      <React.Fragment>
        <Modal size="xl" show={this.props.show}
               onHide={this.props.onHide}
        >
          <Modal.Header closeButton>
              <Modal.Title>{this.props.title}</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{
              height: '80vh',
          }}>
            <iframe
                width="100%"
                height="100%"
                src={this.props.media}
                title="campaign-video-manager-player"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen />
          </Modal.Body>
        </Modal>
      </React.Fragment>
    );
  }

}

export default withTranslation('translate')(withAlert()(DisplayPlayer));
