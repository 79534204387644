import React, {Component} from 'react';
import {Button, Col, Container, Dropdown, Form, Row, Spinner, Tab, Table, Tabs} from 'react-bootstrap';
import {withTranslation} from 'react-i18next';
import {withAlert} from 'react-alert';
import PageToggle from '../PageToggle/PageToggle';
import RedirectChart from '../../Home/RedirectChart';
import CampaignCreation from '../Campaigns/CampaignCreation';
import './Frame.css';

import momentTZ from 'moment-timezone';

import Communicate from './Communicate';
import ZonePlanification from '../../ZonePlanification/ZonePlanification';
import VideoUpload from '../../VideoUpload/VideoUpload';
import ZoneColorModal from './ZoneColorModal';

import * as API from '../../../API';
import CampaignList from "../Campaigns/CampaignList";
import CampaignVideosManager from "../Campaigns/CampaignVideosManager";

import Stats from './Stats';
import Autocomplete from '@material-ui/lab/Autocomplete';

class Frame extends Component {

  constructor(props, context) {
    super(props, context);

    this.targetNameRegex = /^([a-zA-Z0-9_-])+$/;

    this.token = localStorage.getItem('myartadmin.token');
    this.frameId = this.props.match.params.frameId;

    this.state = {
      loading: true,
      showModal: false,
      showPlanifModal: false,
      showCampaignModal: false,
      campaignTarget: '',
      targets: [],
      zones: [],
      users: [],
      label: '',
      externalId: '',
      newTarget: '',
      newTargetTz: 'Europe/Paris',
      newAccount: '',
      newZoneLabel: '',
      newZoneTarget: '',
      newZoneColor: '#000000',
      selectedFile: null,
      frameImage: null,
      sortField: null,
      sortOrder: null,
      showColorModal: false,
      selectedZoneColor: null,
      managingTargetNames: [],
    }
  }

  componentDidMount() {
    if (this.token === undefined || this.token === null) {
      this.props.alert.error(this.props.t('frames.loadError'));
    } else {
      API.fetchAllUsers(this.token).then((users) => this.setState({users: users}));
      this.refreshFrame();
    }
  }

  refreshFrame() {
    Promise.all([
      API.fetchFrame(this.frameId, this.token),
      API.fetchFrameAccounts(this.frameId, this.token),
      API.fetchTargets(this.frameId, this.token),
      API.fetchZones(this.frameId, this.token),
      API.getImage(this.frameId, this.token),
      API.getCampaigns(this.token),
    ]).then(values => {
      this.setState({
        frame: values[0],
        accounts: values[1],
        targets: values[2],
        editableTargets: values[2].map(t => {
          return {...t}
        }),
        zones: values[3],
        editableZones: values[3].map(z => {
          const newZone = {...z};
          newZone.timeZone = values[2].find(t => t.label === z.target).timeZone;
          return newZone;
        }),
        frameImage: (values[4] === '' || values[4] === null) ? null : values[4],
        label: this.state.loading ? values[0].label : this.state.label,
        externalId: this.state.loading ? values[0].externalId : this.state.externalId,
        loading: false,
        sortField: null,
        sortOrder: null,
        campaigns: values[5],
      })

    }).catch(() => {
      this.props.alert.error(this.props.t('frames.loadError'));
    })
  }

  promptDeleteFrame() {
    if (window.confirm(this.props.t('frame.confirmDeleteFrame'))) {
      API.deleteFrame(this.frameId, this.token)
        .then(() => {
          this.props.history.push('/admin/frames');
          this.props.alert.success(this.props.t('frame.frameDeleted'));
        })
        .catch(() => {
          this.props.alert.error(this.props.t('frame.deleteFrameError'));
        });
    }
  }

  promptDuplicateFrame() {
    if (window.confirm(this.props.t('frame.confirmDuplicateFrame'))) {
      API.duplicateFrame(this.frameId, this.token)
        .then((newFrame) => {
          this.props.history.push('/admin/frames');
          this.props.history.push('/admin/frames/' + newFrame.id);
          this.props.alert.success(this.props.t('frame.frameDuplicated'));
        })
        .catch(() => {
          this.props.alert.error(this.props.t('frame.duplicateFrameError'));
        });
    }
  }

  globalSave() {
    var promises = [];
    if (this.state.label !== this.state.frame.label || this.state.externalId !== this.state.frame.externalId) {
      promises.push(API.updateFrame(this.frameId, {
        label: this.state.label,
        externalId: this.state.externalId
      }, this.token));
    }
    if (this.state.selectedFile !== null) {
      promises.push(API.uploadImage(this.frameId, this.state.selectedFile, this.token));
    }
    this.state.editableTargets.forEach(t => {
      if (t.targetUrl !== undefined && t.targetUrl !== null && t.targetUrl !== ''
        && (this.state.targets.find(tt => t.label === tt.label).targetUrl !== t.targetUrl ||
          this.state.targets.find(tt => t.label === tt.label).timeZone !== t.timeZone)) {
        promises.push(API.updateTargetUrl(this.frameId, t.label, t.targetUrl, t.timeZone, this.token));
      }
    });
    this.state.editableZones.forEach(z => {
      if ((z.targetUrl !== undefined && z.targetUrl !== null && z.targetUrl !== '')
        && z.label !== undefined && z.label !== null && z.label !== '' &&
        (this.state.zones.find(zz => z.index === zz.index && z.target === zz.target).targetUrl !== z.targetUrl
          || this.state.zones.find(zz => z.index === zz.index && z.target === zz.target).label !== z.label
          || this.state.zones.find(zz => z.index === zz.index && z.target === zz.target).color !== z.color)) {
        promises.push(API.updateZone(this.frameId, z.target, z.index, {
          targetUrl: z.targetUrl,
          label: z.label,
          color: z.color
        }, this.token))
      }
    });
    Promise.all(promises).then(() => {
      this.refreshFrame();
      this.props.alert.success(this.props.t('frame.updateSuccess'))
    }).catch(() => {
      this.props.alert.error(this.props.t('frame.renameError'));
    });
  }

  saveFrame() {
    API.updateFrame(this.frameId, {
      label: this.state.label,
      externalId: this.state.externalId
    }, this.token)
      .then(() => {
        this.refreshFrame();
        this.props.alert.success(this.props.t('frame.updateSuccess'));
      })
      .catch(() => {
        this.props.alert.error(this.props.t('frame.renameError'));
      });
  }

  removeTarget(target) {
    if (window.confirm(this.props.t('frame.confirmRemoveTarget'))) {
      API.removeFrameTarget(this.frameId, target, this.token)
        .then(() => {
          this.refreshFrame();
          this.props.alert.success(this.props.t('frame.targetRemoved'));
        })
        .catch(() => {
          this.props.alert.error(this.props.t('frame.removetargetError'));
        });
    }
  }

  addNewTarget() {
    this.setState({
      loadingCreationTarget: true,
    })
    API.addFrameTarget(this.frameId, {label: this.state.newTarget, timeZone: this.state.newTargetTz}, this.token)
      .then(() => {
        this.setState({newTarget: '', newTargetTz: 'Europe/Paris', loadingCreationTarget: false})
        this.refreshFrame();
        this.props.alert.success(this.props.t('frame.targetAdded'));
      })
      .catch(() => {
        this.props.alert.error(this.props.t('frame.addtargetError'));
      });
  }

  addNewAccount() {
    API.addFrameAccount(this.frameId, this.state.newAccount.id, this.token)
      .then(() => {
        this.setState({newAccount: ''})
        this.refreshFrame();
        this.props.alert.success(this.props.t('frame.accountAdded'));
      })
      .catch(() => {
        this.props.alert.error(this.props.t('frame.addaccountError'));
      });
  }

  removeAccount(a) {
    if (window.confirm(this.props.t('frame.confirmRemoveAccount'))) {
      API.removeFrameAccount(this.frameId, a, this.token)
        .then(() => {
          this.refreshFrame();
          this.props.alert.success(this.props.t('frame.accountRemoved'));
        })
        .catch(() => {
          this.props.alert.error(this.props.t('frame.removeaccountError'));
        });
    }

  }

  addNewZone() {
    this.setState({
      loadingCreationZone: true,
    })
    API.addFrameZone(this.frameId, this.state.newZoneTarget, this.state.newZoneLabel, this.state.newZoneColor, this.token)
      .then(() => {
        this.setState({newZoneTarget: '', newZoneLabel: '', newZoneColor: '#000000', loadingCreationZone: false})
        this.refreshFrame();
        this.props.alert.success(this.props.t('frame.zoneAdded'));
      })
      .catch(() => {
        this.props.alert.error(this.props.t('frame.addtargetError'));
      });
  }

  removeZone(t, i) {
    if (window.confirm(this.props.t('frame.confirmRemoveZone'))) {
      API.removeFrameZone(this.frameId, t, i, this.token).then(() => {
        this.refreshFrame();
        this.props.alert.success(this.props.t('frame.zoneRemoved'));
      })
        .catch(() => {
          this.props.alert.error(this.props.t('frame.removezoneError'));
        });
    }
  }

  sendFile() {
    API.uploadImage(this.frameId, this.state.selectedFile, this.token)
      .then(() => {
        this.setState({selectedFile: null})
        this.refreshFrame();
        this.props.alert.success(this.props.t('frame.imageAdded'));
      })
  }

  sendNotification(label, zoneName) {
    API.sendNotification(this.frameId, this.state.frame.label, label, zoneName, this.token)
      .then(() => {
        this.props.alert.success(this.props.t('frame.notificationSent'));
      })
  }

  updateZone(zone) {
    API.updateZone(this.frameId, zone.target, zone.index, {
      targetUrl: zone.targetUrl,
      label: zone.label,
      color: zone.color
    }, this.token)
      .then(() => this.refreshFrame())
  }

  saveTargetUrl(target) {
    API.updateTargetUrl(target.id, target.label, target.targetUrl, target.timeZone, target.blinklCampaign, target.blinklProvider, this.token)
      .then(() => this.refreshFrame())
  }

  changeSort(field) {
    if (this.state.sortField === null) {
      const tmpeditableZones = this.state.editableZones.sort((ez1, ez2) => {
        return ('' + ez1[field]).localeCompare(ez2[field]);
      });
      this.setState({sortField: field, sortOrder: 'ASC', editableZones: tmpeditableZones});
    } else if (this.state.sortField === field && this.state.sortOrder === 'ASC') {
      const tmpeditableZones = this.state.editableZones.sort((ez1, ez2) => {
        return ('' + ez2[field]).localeCompare(ez1[field]);
      });
      this.setState({sortOrder: 'DESC', editableZones: tmpeditableZones});
    } else {
      const tmpeditableZones = this.state.editableZones.sort((ez1, ez2) => {
        if (ez1.target !== ez2.target) {
          return ('' + ez1.target).localeCompare(ez2.target);
        }
        return ez1.index - ez2.index;
      });
      this.setState({sortField: null, sortOrder: null, editableZones: tmpeditableZones});
    }
  }

  handleUserRightsView(user) {
    this.props.history.push({
      pathname: `/admin/frames/${this.frameId}/users/${user.id}`,
      state: {user}
    })
  }

  handleModifyTargetName(targetLabel, isSaving, id, t) {
    if (!isSaving) {
      let tmp = [...this.state.managingTargetNames];
      tmp.push({
        label: targetLabel,
        modifiedContent: targetLabel,
        targetId: id,
        targetUrl: t.targetUrl,
        timeZone: t.timeZone
      });
      this.setState({
        managingTargetNames: tmp,
      })
    } else {
      console.log("save")
      let tmp = [...this.state.managingTargetNames];
      const index = tmp.findIndex(item => item.label === targetLabel);
      API.modifyTargetLabel(this.frameId, tmp[index].targetId, tmp[index].modifiedContent, tmp[index].targetUrl, tmp[index].timeZone, this.token)
        .then(res => console.log("res =>", res))
      tmp.splice(index, 1);
      this.setState({
        managingTargetNames: tmp,
      })
    }
  }

  handleModifyInputChange(value, index) {
    let tmp = [...this.state.managingTargetNames];
    tmp[index].modifiedContent = value;
    this.setState({
      managingTargetNames: tmp,
    })
  }

  render() {
    if (this.state.loading) {
      return (
        <Container className="frame-container">
          <Spinner animation="border" className="spinner-border-custom"/>
        </Container>
      )
    }

    const CustomAutoComplete = React.forwardRef(({children, onChange}, ref) => (
      <Autocomplete
        id="users-autoComplete"
        value={this.state.newAccount}
        onChange={(event, newValue) => this.setState({newAccount: newValue})}
        options={this.state.users}
        getOptionLabel={(option) => option.login !== undefined && option.login !== "" ? option.login : ''}
        style={{width: '100%'}}
        renderInput={(params) =>
          <div ref={params.InputProps.ref}>
            <input
              type="text"
              {...params.inputProps}
              autoComplete="new-password"
              style={{
                width: '100%',
                height: 'calc(1.5em + .75rem + 2px)',
                padding: '.375rem .75rem',
                fontSize: '1rem',
                fontWeight: '400',
                lineHeight: '1.5',
                color: '#495057',
                backgroundColor: '#fff',
                backgroundClip: 'padding-box',
                border: '1px solid #ced4da',
                borderRadius: '.25rem',
                transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
                outline: 'white',
              }}
            />
          </div>
        }
      />
    ));
    console.log("managingInputs =>", this.state.managingTargetNames)
    return (
      <Container className="Common-Container">
        <Row>
          <Col md={12}>
            <PageToggle/>
          </Col>
        </Row>
        {/* <Row>
          <Col md={{span: 6, offset: 6}} className="text-right mb-4" style={{marginTop: '1rem'}}> */}
        {/* <Button onClick={this.globalSave.bind(this)} className="mr-2" variant="gold"><i className="fas fa-save"></i></Button> */}
        {/* <Button onClick={this.promptDuplicateFrame.bind(this)} className="mr-2"
                    variant="gold">{this.props.t('frame.duplicate')}</Button>
            <Button onClick={this.promptDeleteFrame.bind(this)} className="mr-2"
                    variant="gold">{this.props.t('frame.delete')}</Button> */}
        {/* <Button onClick={() => this.setState({showModal: true})} className="mr-2"
                    variant="gold">{this.props.t('frame.communicate')}</Button> */}
        {/* </Col>
        </Row> */}
        <Row className="frame-parent-row">
          <div style={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'space-between',
            width: '100%',
            paddingRight: '30px'
          }}>
            <h3 style={{
              padding: '0 30px',
              fontWeight: '800',
              fontFamily: 'Helvetica Neue, Roboto, sans-serif'
            }}>{this.state.label.toUpperCase()}</h3>
            <div style={{display: 'flex', flexDirection: 'row', height: '40px', paddingRight: '8px'}}>
              <Button onClick={this.globalSave.bind(this)} className="mr-2 small-button" variant="gold"><i
                className="fas fa-save"></i></Button>
              <Dropdown drop='down'>
                <Dropdown.Toggle variant="gold" id="dropdown-basic" style={{height: '40px', width: '50px'}}>
                  <i className="fas fa-cog"/>
                </Dropdown.Toggle>

                <Dropdown.Menu className="settings-dropdown-content-container">
                  <Dropdown.Item href="#/action-1" className="settings-dropdown-item">
                    <Button onClick={this.promptDuplicateFrame.bind(this)}
                            variant="gold">{this.props.t('frame.duplicate')}</Button>
                  </Dropdown.Item>
                  <Dropdown.Item href="#/action-2" className="settings-dropdown-item">
                    <Button onClick={this.promptDeleteFrame.bind(this)}
                            variant="gold">{this.props.t('frame.delete')}</Button>
                  </Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </div>
          </div>
          <Tabs variant="pills" defaultActiveKey="informations" id="frame-tabs" className="campaign-manager-tabs" fill>
            <Tab eventKey="informations" title={this.props.t('frame.informationsAndData').toUpperCase()}
                 className="campaign-manager-tabs">
              {/* <Col md={3} className="mb-4"> */}
              <h4 style={{
                marginBottom: '1.5rem',
                color: '#B59A4F',
                fontWeight: 'bold'
              }}>{this.props.t('frame.informations').toUpperCase()}</h4>
              <div className="informations-container">
                <div className="information-left-container">
                  <section className="mb-sm-4">
                    <h5>{this.props.t('frame.image').toUpperCase()}</h5>
                    <Form.Row>
                      <Col md={10}>
                        {/* <Form.File
                              id="frame-file-upload"
                              label={this.props.t('frame.choseFile')}
                              custom
                              accept={".jpg, .png"}
                              onChange={(event) => this.setState({selectedFile: event.target.files[0] !== undefined ? event.target.files[0] : null})}
                              placeholder="image"/> */}
                        <label
                          className="upload-photo-informations"
                          for="upload-photo"
                        >{this.props.t('frame.choseFile')}</label>
                        <input type="file" name="photo" id="upload-photo"
                               onChange={(event) => this.setState({selectedFile: event.target.files[0] !== undefined ? event.target.files[0] : null})}/>
                      </Col>
                      <Col md={2}>
                        <Button variant="gold"
                                className="small-button"
                                disabled={this.state.selectedFile === null}
                                onClick={this.sendFile.bind(this)}><i className="fas fa-save"></i></Button>
                      </Col>
                      {this.state.selectedFile &&
                      <div style={{paddingLeft: '5px'}}>{this.state.selectedFile.name}</div>
                        // :
                        // <div style={{paddingLeft: '5px'}}>{this.props.t('frame.noFileChosen')}</div>
                      }
                    </Form.Row>
                    {
                      this.state.frameImage !== null &&
                      <img className="mt-sm-4" src={this.state.frame.imageUrl ? this.state.frame.imageUrl : 'data:image/*;base64,' + this.state.frameImage}
                           alt="visuel" style={{width: '90%'}}/>
                    }
                  </section>
                </div>
                <div style={{width: '100%'}}>
                  <section className="mb-sm-4 margin-spacing-top">
                    <h5>{this.props.t('frame.name').toUpperCase()}</h5>
                    <Form.Row>
                      <Col md={10}>
                        <Form.Control
                          onChange={(event) => this.setState({label: event.target.value})}
                          value={this.state.label}
                          type="text"
                          placeholder={this.props.t('frame.name')}
                        />
                      </Col>
                      <Col md={2}>
                        <Button variant="gold"
                                className="small-button"
                                disabled={this.state.frame.label === this.state.label || this.state.label.length < 4}
                                onClick={this.saveFrame.bind(this)}><i className="fas fa-save"></i></Button>
                      </Col>
                    </Form.Row>
                  </section>
                  <section className="mb-sm-4 margin-spacing-top">
                    <h5>{this.props.t('frame.externalId').toUpperCase()}</h5>
                    <Form.Row>
                      <Col md={10}>
                        <Form.Control
                          onChange={(event) => this.setState({externalId: event.target.value})}
                          value={this.state.externalId}
                          type="text"
                          placeholder={this.props.t('frame.externalId')}
                        />
                      </Col>
                      <Col md={2}>
                        <Button variant="gold"
                                className="small-button"
                                disabled={this.state.frame.externalId === this.state.externalId}
                                onClick={this.saveFrame.bind(this)}><i className="fas fa-save"></i></Button>
                      </Col>
                    </Form.Row>
                  </section>
                  <section className="margin-spacing-top">
                    <h5>{this.props.t('frame.accounts').toUpperCase()}</h5>
                    <Form.Row autoComplete="new-password">
                      <Col md={10}>
                        <Form.Control
                          style={{}}
                          as={CustomAutoComplete}
                        />

                        {/* <Form.Control
                          as="select"
                          value={this.state.newAccount}
                          placeholder={this.props.t('frame.newaccount')}
                          onChange={(event) => this.setState({newAccount: event.target.value})}>
                          <option value=''>---</option>
                          {
                            this.state.users.filter(u => this.state.accounts
                              .find(a => a.id === u.id) === undefined)
                              .map(t => {
                                return <option key={t.id} value={t.id}>{t.login}</option>;
                              })
                          }
                        </Form.Control> */}
                      </Col>
                      <Col md={2}>
                        <Button variant="gold"
                                className="small-button"
                                disabled={this.state.newAccount === ''}
                                onClick={this.addNewAccount.bind(this)}><i className="fas fa-plus"></i></Button>
                      </Col>
                    </Form.Row>
                    <ul className="mt-sm-4 margin-spacing-top">
                      {
                        this.state.accounts.map(a => {
                          return (
                            <li key={a.id} className="trash-button">
                              {a.login}
                              <i onClick={() => this.handleUserRightsView(a)}
                                 className="ml-sm-2 fas fa-pen margin-spacing-pen-horizontal"></i>
                              <i onClick={() => this.removeAccount(a.id)}
                                 className="ml-sm-2 fas fa-trash"></i>
                            </li>);
                        })
                      }
                    </ul>
                  </section>
                </div>
              </div>
              <section>
                {(this.state.targets.length > 0) &&
                <Row className="mt-2 pt-4">
                  <Col md={12} className="mb-sm-2">
                    <h4 className="Uppercase-Title"
                        style={{marginBottom: '2rem'}}>{this.props.t('home.charttitle')}</h4>
                  </Col>
                  {this.state.targets.map(t => {
                    return <Col key={t.label} md={12} style={{marginBottom: '2rem'}}>
                      <h4 style={{marginBottom: '0'}}>{t.label}</h4>
                      <RedirectChart zones={this.state.zones.filter(z => z.target === t.label)} frameId={this.frameId}
                                     targetlabel={t.label}/>
                    </Col>
                  })
                  }
                </Row>
                }
              </section>
              <section>
                <Stats frameId={this.frameId} targetsLabel={this.state.targets} zones={this.state.zones}/>
              </section>
              {/* </Col> */}
            </Tab>
            <Tab eventKey="targets" title={this.props.t('frame.targetsAndZones').toUpperCase()}
                 className="campaign-manager-tabs">
              <section className="mb-sm-4">
                <h4>{this.props.t('frame.targets').toUpperCase()}</h4>
                <Form.Row>
                  <Col>
                    <Form.Control
                      type="text"
                      value={this.state.newTarget}
                      placeholder={this.props.t('frame.newtarget')}
                      onChange={(event) => {
                        if (event.target.value === '' || this.targetNameRegex.test(event.target.value)) {
                          this.setState({newTarget: event.target.value});
                        }
                      }}
                    />
                  </Col>
                  <Col>
                    <Form.Control
                      as="select"
                      value={this.state.newTargetTz}
                      placeholder={this.props.t('frame.timezone')}
                      onChange={(event => this.setState({newTargetTz: event.target.value}))}>
                      {
                        momentTZ.tz.names().map(tz => {
                          return <option key={tz} value={tz}>{tz}</option>;
                        })
                      }
                    </Form.Control>
                  </Col>
                  <Col>
                    <Button
                      variant="gold"
                      className="small-button"
                      disabled={this.state.newTarget === '' || this.state.loadingCreationTarget}
                      onClick={this.addNewTarget.bind(this)}>
                      {this.state.loadingCreationTarget ?
                        <Spinner animation="border" size="sm"/>
                        :
                        <i className="fas fa-plus"></i>
                      }
                    </Button>
                  </Col>
                </Form.Row>
                {
                  this.state.targets.length > 0 ?
                    <Table variant="custom" size="sm" responsive="md" borderless>
                      <thead>
                      <tr>
                        <th>{this.props.t('frame.target')}</th>
                        <th>{this.props.t('frame.timezone')}</th>
                        <th>{this.props.t('frame.targetUrl')}</th>
                        <th>{this.props.t('frame.blinkl')}</th>
                        <th></th>
                      </tr>
                      </thead>
                      <tbody>
                      {
                        this.state.editableTargets.map((t, i) => {
                          return <tr key={t.label} className={i % 2 === 0 ? 'even' : 'odd'}>
                            <td>
                              {t.label}
                            </td>
                            {/* to enable once we will be able to change targetName (one day ?) */}
                            {/* {this.state.managingTargetNames.findIndex(item => item.label === t.label) === -1 ?
                                <td>
                                  {t.label}
                                  <i onClick={() => this.handleModifyTargetName(t.label, false, t.id, t)}
                                    className="ml-sm-2 fas fa-pen" style={{marginRight: '5px', cursor: 'pointer', width: '30px'}}>
                                  </i>
                                </td>
                                :
                                <td style={{display: 'flex', flexDirection: 'row'}}>
                                  <Form.Control
                                    onChange={(event) => this.handleModifyInputChange(event.target.value, this.state.managingTargetNames.findIndex(item => item.label === t.label))}
                                    value={this.state.managingTargetNames[this.state.managingTargetNames.findIndex(item => item.label === t.label)].modifiedContent}
                                    type="text"
                                    size="sm"
                                  />
                                  <Button size="sm"
                                        onClick={() => this.handleModifyTargetName(t.label, true)} variant="gold">
                                  <i className="fas fa-save"></i>
                                </Button>
                                </td>
                              } */}
                            <td>
                              <Form.Control
                                as="select"
                                size="sm"
                                value={t.timeZone}
                                placeholder={this.props.t('frame.timezone')}
                                onChange={(event) => {
                                  const editableTargets = this.state.editableTargets.map(tt => {
                                    if (t.label === tt.label) {
                                      return {
                                        ...tt,
                                        timeZone: event.target.value,
                                      }
                                    }
                                    return tt;
                                  });
                                  this.setState({editableTargets});
                                }}>
                                {
                                  momentTZ.tz.names().map(tz => {
                                    return <option key={tz} value={tz}>{tz}</option>;
                                  })
                                }
                              </Form.Control>
                            </td>
                            <td>
                              <Form.Control
                                onChange={(event) => {
                                  const editableTargets = this.state.editableTargets.map(tt => {
                                    if (t.label === tt.label) {
                                      return {
                                        ...tt,
                                        targetUrl: event.target.value,
                                      }
                                    }
                                    return tt;
                                  });
                                  this.setState({editableTargets});
                                }}
                                value={t.targetUrl === null ? '' : t.targetUrl}
                                type="text"
                                size="sm"
                              />
                            </td>
                            <td>
                              <Form.Label>Id Campagne</Form.Label>
                              <Form.Control
                                onChange={(event) => {
                                  const editableTargets = this.state.editableTargets.map(tt => {
                                    if (t.label === tt.label) {
                                      return {
                                        ...tt,
                                        blinklCampaign: event.target.value,
                                      }
                                    }
                                    return tt;
                                  });
                                  this.setState({editableTargets});
                                }}
                                value={t.blinklCampaign === null ? '' : t.blinklCampaign}
                                type="text"
                                size="sm"
                              />
                              <Form.Label>Id Provider</Form.Label>
                              <Form.Control
                                onChange={(event) => {
                                  const editableTargets = this.state.editableTargets.map(tt => {
                                    if (t.label === tt.label) {
                                      return {
                                        ...tt,
                                        blinklProvider: event.target.value,
                                      }
                                    }
                                    return tt;
                                  });
                                  this.setState({editableTargets});
                                }}
                                value={t.blinklProvider === null ? '' : t.blinklProvider}
                                type="text"
                                size="sm"
                              />
                            </td>
                            <td>
                              <Button size="sm"
                                      className="small-button"
                                      disabled={(t.targetUrl === undefined || t.targetUrl === null || t.targetUrl === '')
                                      || (this.state.targets.find(tt => t.label === tt.label).targetUrl === t.targetUrl
                                        && this.state.targets.find(tt => t.label === tt.label).timeZone === t.timeZone
                                        && this.state.targets.find(tt => t.label === tt.label).blinklCampaign === t.blinklCampaign
                                        && this.state.targets.find(tt => t.label === tt.label).blinklProvider === t.blinklProvider
                                      )}
                                      onClick={() => this.saveTargetUrl(t)} variant="gold">
                                <i className="fas fa-save"></i>
                              </Button>
                              <Button onClick={() => this.setState({showCampaignModal: true, campaignTarget: t})}
                                      variant="gold" size="sm" className="ml-sm-2 small-button">
                                <i className="fas fa-air-freshener"></i>
                              </Button>
                              <Button onClick={() => this.removeTarget(t.label)} variant="gold" size="sm"
                                      className="ml-sm-2 small-button">
                                <i className="fas fa-trash"></i>
                              </Button>
                              <Button onClick={() => this.sendNotification(t.label, null)} variant="gold" size="sm"
                                      className="ml-sm-2 small-button">
                                <i className="fas fa-satellite-dish"></i>
                              </Button>
                              <Button variant="gold" size="sm" className="ml-sm-2 small-button"
                                      onClick={() => window.open(t.targetUrl, '_blank')}
                                      disabled={t.targetUrl === undefined || t.targetUrl === null || t.targetUrl === ''}>
                                <i className="fas fa-external-link-alt"></i>
                              </Button>
                            </td>
                          </tr>
                        })
                      }
                      </tbody>
                    </Table>
                    :
                    <p className="mt-sm-2 mb-sm-2 text-center"><strong>{this.props.t('frame.nozones')}</strong></p>
                }
              </section>
              <section className="mt-4">
                <h4>{this.props.t('frame.zones').toUpperCase()}</h4>
                <p><strong>{this.props.t('frame.zonecount')} ({this.state.zones.length}){
                  this.state.targets.map(target => {
                    return <span
                      key={target.label}> - {target.label} ({this.state.zones.filter(z => z.target === target.label).length})</span>
                  })
                }</strong></p>
                <Form.Row>
                  <Col>
                    <Form.Control
                      as="select"
                      value={this.state.newZoneTarget}
                      placeholder={this.props.t('frame.newzone')}
                      onChange={(event) => this.setState({newZoneTarget: event.target.value})}>
                      <option value=''>---</option>
                      {
                        this.state.targets.map(t => {
                          return <option key={t.label} value={t.label}>{t.label}</option>;
                        })
                      }
                    </Form.Control>
                  </Col>
                  <Col>
                    <Form.Control
                      value={this.state.newZoneLabel}
                      onChange={(event) => this.setState({newZoneLabel: event.target.value})}
                      type="text"
                      placeholder={this.props.t('frame.newzonename')}/>
                  </Col>
                  <Col>
                    <Form.Control
                      value={this.state.newZoneColor}
                      onChange={(event) => this.setState({newZoneColor: event.target.value})}
                      type="color"
                    />
                  </Col>
                  <Col>
                    <Button
                      disabled={this.state.newZoneLabel.length < 2 || this.state.newZoneTarget === '' || this.state.newZoneColor === '' || this.state.loadingCreationZone}
                      variant="gold"
                      className="small-button"
                      onClick={this.addNewZone.bind(this)}>
                      {this.state.loadingCreationZone ?
                        <Spinner animation="border" size="sm"/>
                        :
                        <i className="fas fa-plus"></i>
                      }
                    </Button>
                  </Col>
                </Form.Row>
                {
                  this.state.zones.length > 0 ?
                    <div>
                      <Table variant="custom" size="sm" responsive="md" borderless>
                        <thead>
                        <tr>
                          <th className={'frames_th_checkbox'}></th>
                          <th
                            className={'frames_th_index clickable' + (this.state.sortField === 'index' ? ' sorted-' + this.state.sortOrder : '')}
                            onClick={() => this.changeSort('index')}>{this.props.t('frame.index')}</th>
                          <th></th>
                          <th
                            className={'frames_th_target clickable' + (this.state.sortField === 'target' ? ' sorted-' + this.state.sortOrder : '')}
                            onClick={() => this.changeSort('target')}>{this.props.t('frame.target')}</th>
                          <th
                            className={'frames_th_name clickable' + (this.state.sortField === 'label' ? ' sorted-' + this.state.sortOrder : '')}
                            onClick={() => this.changeSort('label')}>{this.props.t('frame.name')}</th>
                          <th>{this.props.t('frame.staticUrl')}</th>
                        </tr>
                        </thead>
                        <tbody>
                        {
                          this.state.editableZones.map((z, i) => {
                            return <tr key={z.index + z.target} className={i % 2 === 0 ? 'even' : 'odd'}>
                              <td>
                                <Button variant="default" size="small" className="small-button" onClick={() => {
                                  this.setState({
                                    showColorModal: true,
                                    selectedZoneColor: z
                                  })
                                }}>
                                  <i className="fas fa-stop" style={{color: z.color}}></i>
                                </Button>
                              </td>
                              <td>{z.index}</td>
                              <td>
                                <Button size="sm"
                                        disabled={(z.targetUrl === undefined || z.targetUrl === null || z.targetUrl === '')
                                        || (z.label === undefined || z.label === null || z.label === '')
                                        || (this.state.zones.find(zz => z.index === zz.index && z.target === zz.target).targetUrl === z.targetUrl
                                          && this.state.zones.find(zz => z.index === zz.index && z.target === zz.target).label === z.label
                                          && this.state.zones.find(zz => z.index === zz.index && z.target === zz.target).color === z.color)}
                                        onClick={() => this.updateZone(z)} variant="gold" className="small-button"><i
                                  className="fas fa-save"></i></Button>

                                <Button size="sm" variant="gold"
                                        className="ml-sm-2 Button-With-Badge small-button"
                                        onClick={() => this.setState({
                                          selectedZone: {
                                            targetId: z.targetId,
                                            targetUrl: z.targetUrl,
                                            frameId: this.frameId,
                                            id: z.id,
                                            target: z.target,
                                            index: z.index,
                                            timeZone: z.timeZone
                                          },
                                          showPlanifModal: true
                                        })}
                                        disabled={z.targetUrl === undefined || z.targetUrl === null || z.targetUrl === ''}>
                                  <i className="fas fa-clock"></i>
                                  {z.planifications > 0 && <span className="Button-Badge">{z.planifications}</span>}
                                </Button>
                                <Button className="ml-sm-2 small-button"
                                        onClick={() => this.removeZone(z.target, z.index)}
                                        variant="gold" size="sm"><i className="fas fa-trash"></i></Button>
                                <Button onClick={() => this.sendNotification(z.target, z.label)} variant="gold"
                                        size="sm"
                                        className="ml-sm-2 small-button"><i
                                  className="fas fa-satellite-dish"></i></Button>
                                <Button variant="gold" size="sm" className="ml-sm-2 small-button"
                                        onClick={() => window.open(process.env.REACT_APP_API_URL + '/r/' + z.uniqueKey + '/test', '_blank')}
                                        disabled={z.uniqueKey === undefined || z.uniqueKey === null || z.uniqueKey === ''}>
                                  <i className="fas fa-external-link-alt"></i>
                                </Button>
                                <Button size="sm" className="ml-sm-2 small-button" variant="gold"
                                        onClick={() => this.setState({
                                          selectedZone: {
                                            frameId: this.frameId,
                                            id: z.id,
                                            target: z.target,
                                            index: z.index,
                                            timeZone: z.timeZone
                                          },
                                          showVideoModal: true
                                        })}>
                                  <i className="fas fa-video"></i>
                                </Button>
                              </td>
                              <td>{z.target}</td>
                              <td>
                                <Form.Control
                                  size="sm"
                                  onChange={(event) => {
                                    const zones = this.state.editableZones.map(zz => {
                                      if (z.index === zz.index && z.target === zz.target) {
                                        return {
                                          ...zz,
                                          label: event.target.value,
                                        }
                                      }
                                      return zz;
                                    });
                                    this.setState({editableZones: zones});
                                  }}
                                  value={z.label}
                                  type="text"
                                />
                              </td>
                              <td>
                                <Form.Control
                                  size="sm"
                                  style={{
                                    minWidth: '200px',
                                  }}
                                  onChange={(event) => {
                                    const zones = this.state.editableZones.map(zz => {
                                      if (z.index === zz.index && z.target === zz.target) {
                                        return {
                                          ...zz,
                                          targetUrl: event.target.value,
                                        }
                                      }
                                      return zz;
                                    });
                                    this.setState({editableZones: zones});
                                  }}
                                  value={z.targetUrl === null ? '' : z.targetUrl}
                                  type="text"
                                />
                                <Form.Control
                                  className="mt-sm-1"
                                  style={{backgroundColor: '#e6e6e6', fontStyle: 'italic'}}
                                  size="sm"
                                  onClick={(event) => {
                                    event.target.select();
                                    event.target.setSelectionRange(0, event.target.value.length);
                                    document.execCommand("copy");
                                  }}
                                  onChange={() => {
                                  }}
                                  value={process.env.REACT_APP_API_URL + '/r/' + z.uniqueKey}
                                  type="text"
                                />
                              </td>
                            </tr>
                          })
                        }
                        </tbody>
                      </Table>
                    </div>
                    :
                    <p className="mt-sm-2 mb-sm-2 text-center"><strong>{this.props.t('frame.nozones')}</strong></p>
                }
              </section>
            </Tab>
            <Tab eventKey="campaigns" title={this.props.t("frame.campaigns").toUpperCase()}
                 className="campaign-manager-tabs">
              <section>
                <h4>{this.props.t('campaigns.title-list').toUpperCase()}</h4>
                {/* <CampaignManager filter={false} frameId={this.frameId} /> */}
                <CampaignList
                  filter={false}
                  frameId={this.frameId}
                  campaigns={this.state.campaigns}
                />
              </section>
            </Tab>
            {/* <Tab eventKey="zones" title={this.props.t("frame.zones").toUpperCase()} className="campaign-manager-tabs"> */}
            {/* <section className="mt-4">
                  <h4>{this.props.t('frame.zones')}</h4>
                  <p><strong>{this.props.t('frame.zonecount')} ({this.state.zones.length}){
                    this.state.targets.map(target => {
                      return <span
                        key={target.label}> - {target.label} ({this.state.zones.filter(z => z.target === target.label).length})</span>
                    })
                  }</strong></p>
                  <Form.Row>
                    <Col>
                      <Form.Control
                        as="select"
                        value={this.state.newZoneTarget}
                        placeholder={this.props.t('frame.newzone')}
                        onChange={(event) => this.setState({newZoneTarget: event.target.value})}>
                        <option value=''>---</option>
                        {
                          this.state.targets.map(t => {
                            return <option key={t.label} value={t.label}>{t.label}</option>;
                          })
                        }
                      </Form.Control>
                    </Col>
                    <Col>
                      <Form.Control
                        value={this.state.newZoneLabel}
                        onChange={(event) => this.setState({newZoneLabel: event.target.value})}
                        type="text"
                        placeholder={this.props.t('frame.newzonename')}/>
                    </Col>
                    <Col>
                      <Form.Control
                        value={this.state.newZoneColor}
                        onChange={(event) => this.setState({newZoneColor: event.target.value})}
                        type="color"
                      />
                    </Col>
                    <Col>
                      <Button
                        disabled={this.state.newZoneLabel.length < 2 || this.state.newZoneTarget === '' || this.state.newZoneColor === ''}
                        variant="gold"
                        onClick={this.addNewZone.bind(this)}><i className="fas fa-plus"></i></Button>
                    </Col>
                  </Form.Row>
                  {
                    this.state.zones.length > 0 ?
                      <div>
                        <Table variant="custom" size="sm" responsive="md" borderless>
                          <thead>
                          <tr>
                            <th className={'frames_th_checkbox'}></th>
                            <th
                              className={'frames_th_index clickable' + (this.state.sortField === 'index' ? ' sorted-' + this.state.sortOrder : '')}
                              onClick={() => this.changeSort('index')}>{this.props.t('frame.index')}</th>
                            <th
                              className={'frames_th_target clickable' + (this.state.sortField === 'target' ? ' sorted-' + this.state.sortOrder : '')}
                              onClick={() => this.changeSort('target')}>{this.props.t('frame.target')}</th>
                            <th
                              className={'frames_th_name clickable' + (this.state.sortField === 'label' ? ' sorted-' + this.state.sortOrder : '')}
                              onClick={() => this.changeSort('label')}>{this.props.t('frame.name')}</th>
                            <th>{this.props.t('frame.staticUrl')}</th>
                            <th></th>
                          </tr>
                          </thead>
                          <tbody>
                          {
                            this.state.editableZones.map((z, i) => {
                              return <tr key={z.index + z.target} className={i % 2 === 0 ? 'even' : 'odd'}>
                                <td>
                                  <Button variant="default" size="small" onClick={() => {
                                    this.setState({
                                      showColorModal: true,
                                      selectedZoneColor: z
                                    })
                                  }}>
                                    <i className="fas fa-stop" style={{color: z.color}}></i>
                                  </Button>
                                </td>
                                <td>{z.index}</td>
                                <td>{z.target}</td>
                                <td>
                                  <Form.Control
                                    size="sm"
                                    onChange={(event) => {
                                      const zones = this.state.editableZones.map(zz => {
                                        if (z.index === zz.index && z.target === zz.target) {
                                          return {
                                            ...zz,
                                            label: event.target.value,
                                          }
                                        }
                                        return zz;
                                      });
                                      this.setState({editableZones: zones});
                                    }}
                                    value={z.label}
                                    type="text"
                                  />
                                </td>
                                <td>
                                  <Form.Control
                                    size="sm"
                                    onChange={(event) => {
                                      const zones = this.state.editableZones.map(zz => {
                                        if (z.index === zz.index && z.target === zz.target) {
                                          return {
                                            ...zz,
                                            targetUrl: event.target.value,
                                          }
                                        }
                                        return zz;
                                      });
                                      this.setState({editableZones: zones});
                                    }}
                                    value={z.targetUrl === null ? '' : z.targetUrl}
                                    type="text"
                                  />
                                  <Form.Control
                                    className="mt-sm-1"
                                    style={{backgroundColor: '#e6e6e6', fontStyle: 'italic'}}
                                    size="sm"
                                    onClick={(event) => {
                                      event.target.select();
                                      event.target.setSelectionRange(0, event.target.value.length);
                                      document.execCommand("copy");
                                    }}
                                    onChange={() => {
                                    }}
                                    value={process.env.REACT_APP_API_URL + '/r/' + z.uniqueKey}
                                    type="text"
                                  />
                                </td>
                                <td>
                                  <Button size="sm"
                                          disabled={(z.targetUrl === undefined || z.targetUrl === null || z.targetUrl === '')
                                          || (z.label === undefined || z.label === null || z.label === '')
                                          || (this.state.zones.find(zz => z.index === zz.index && z.target === zz.target).targetUrl === z.targetUrl
                                            && this.state.zones.find(zz => z.index === zz.index && z.target === zz.target).label === z.label
                                            && this.state.zones.find(zz => z.index === zz.index && z.target === zz.target).color === z.color)}
                                          onClick={() => this.updateZone(z)} variant="gold"><i className="fas fa-save"></i></Button>

                                  <Button size="sm" variant="gold"
                                          className="ml-sm-2 Button-With-Badge"
                                          onClick={() => this.setState({
                                            selectedZone: {
                                              targetId: z.targetId,
                                              targetUrl: z.targetUrl,
                                              frameId: this.frameId,
                                              id: z.id,
                                              target: z.target,
                                              index: z.index,
                                              timeZone: z.timeZone
                                            },
                                            showPlanifModal: true
                                          })}
                                          disabled={z.targetUrl === undefined || z.targetUrl === null || z.targetUrl === ''}>
                                    <i className="fas fa-clock"></i>
                                    {z.planifications > 0 && <span className="Button-Badge">{z.planifications}</span>}
                                  </Button>
                                  <Button className="ml-sm-2" onClick={() => this.removeZone(z.target, z.index)}
                                          variant="gold" size="sm"><i className="fas fa-trash"></i></Button>
                                  <Button onClick={() => this.sendNotification(z.target, z.label)} variant="gold" size="sm"
                                          className="ml-sm-2"><i className="fas fa-satellite-dish"></i></Button>
                                  <Button variant="gold" size="sm" className="ml-sm-2"
                                          onClick={() => window.open(z.targetUrl, '_blank')}
                                          disabled={z.targetUrl === undefined || z.targetUrl === null || z.targetUrl === ''}>
                                    <i className="fas fa-external-link-alt"></i>
                                  </Button>
                                  <Button size="sm" className="ml-sm-2" variant="gold"
                                          onClick={() => this.setState({
                                            selectedZone: {
                                              frameId: this.frameId,
                                              id: z.id,
                                              target: z.target,
                                              index: z.index,
                                              timeZone: z.timeZone
                                            },
                                            showVideoModal: true
                                          })}>
                                    <i className="fas fa-video"></i>
                                  </Button>
                                </td>
                              </tr>
                            })
                          }
                          </tbody>
                        </Table>
                      </div>
                      :
                      <p className="mt-sm-2 mb-sm-2 text-center"><strong>{this.props.t('frame.nozones')}</strong></p>
                  }
                </section> */}
            {/* </Tab> */}
            {/* <Tab eventKey="chart" title={this.props.t("frame.chart").toUpperCase()} className="campaign-manager-tabs">
                  <section>
                    {(this.state.targets.length > 0) &&
                        <Row className="mt-4 pt-4">
                          <Col md={12} className="mb-sm-2">
                            <h4 className="Uppercase-Title">{this.props.t('home.charttitle')}</h4>
                          </Col>
                          {this.state.targets.map(t => {
                            return <Col key={t.label} md={{span: 8, offset: 2}}>
                              <h4>{t.label}</h4>
                              <RedirectChart zones={this.state.zones.filter(z => z.target === t.label)} frameId={this.frameId} targetlabel={t.label}/>
                            </Col>
                          })
                        }
                      </Row>
                    }
                  </section>
              </Tab> */}
            <Tab eventKey="videos" title={this.props.t("frame.videos").toUpperCase()} className="campaign-manager-tabs">
              <CampaignVideosManager folderId={this.state.frame.folderId}/>
            </Tab>
          </Tabs>
        </Row>
        <ZonePlanification zoneId={this.state.selectedZone}
                           showProvider={true}
          //externalUrl={this.state.selectedFrame === null ? "/" : this.state.targets[0].targetUrl}
                           zone={this.state.selectedZoneColor}
                           show={this.state.showPlanifModal}
                           campaigns={this.state.campaigns}
                           onHide={() => {
                             this.setState({showPlanifModal: false});
                             this.refreshFrame();
                           }}/>
        <Communicate show={this.state.showModal} onHide={() => this.setState({showModal: false})}/>
        <VideoUpload showProvider={true} zoneId={this.state.selectedZone} show={this.state.showVideoModal}
                     onHide={() => {
                       this.setState({showVideoModal: false})
                       setTimeout(() => {
                         this.refreshFrame();
                       }, 1000);
                     }}/>
        <ZoneColorModal onHide={() => {
          this.setState({showColorModal: false});
          this.refreshFrame();
        }} show={this.state.showColorModal} frameId={this.props.match.params.frameId}
                        zone={this.state.selectedZoneColor}/>
        <CampaignCreation
          show={this.state.showCampaignModal}
          campaignTarget={this.state.campaignTarget}
          token={this.token}
          frameId={this.frameId}
          onHide={() => {
            this.setState({showCampaignModal: false});
            this.refreshFrame();
          }}/>
      </Container>
    );
  }

}

export default withTranslation('translate')(withAlert()(Frame));
