import React, {Component} from 'react';
import {Button, Col, Container, Nav, Navbar, Row} from 'react-bootstrap';
import {withTranslation} from 'react-i18next';
import {withAlert} from 'react-alert';
import {BrowserView, isAndroid, isIOS, isIOS13, MobileView} from "react-device-detect";
import Footer from '../Footer/Footer';
import adpLogo from '../Home/adp.png';
import frLogo from '../Admin/Navigation/fr.png';
import enLogo from '../Admin/Navigation/en.png';
import esLogo from '../Admin/Navigation/es.png';

class AdsApp extends Component {

  constructor(props, context) {

    super(props, context);

    this.token = localStorage.getItem('myartadmin.token');
    this.login = localStorage.getItem('myartadmin.login');

    this.state = {
      showMenuLang: false,
      showMenuContact: false,
    }

    this.showMenuLang = this.showMenuLang.bind(this);
    this.closeMenuLang = this.closeMenuLang.bind(this);

    this.showMenuContact = this.showMenuContact.bind(this);
    this.closeMenuContact = this.closeMenuContact.bind(this);
  }

  componentDidMount() {
    if (isAndroid) {
      window.open('https://play.google.com/store/apps/details?id=fr.artdesignstory.artdesignstory_app.dev&hl=fr&ah=sbX-QgqfBMT2M_EZ0vh6_Vo7O5U');
    }
    if (isIOS || isIOS13) {
      window.open('https://apps.apple.com/us/app/artdesignstory/id1536423226');
    }
  }

  showMenuLang(event) {
    event.preventDefault();
    this.setState({showMenuLang: true}, () => {
      document.addEventListener('click', this.closeMenuLang);
    });
  }

  closeMenuLang() {
    this.setState({showMenuLang: false}, () => {
      document.removeEventListener('click', this.closeMenuLang);
    });
  }

  showMenuContact(event) {
    event.preventDefault();
    this.setState({showMenuContact: true}, () => {
      document.addEventListener('click', this.closeMenuContact);
    });
  }

  closeMenuContact() {
    this.setState({showMenuContact: false}, () => {
      document.removeEventListener('click', this.closeMenuContact);
    });
  }

  signout() {
    localStorage.removeItem('myartadmin.token');
    localStorage.removeItem('myartadmin.login');
    this.props.history.push('/');
  }

  handleLanguageFlag() {
    // console.log("language =>", this.props.i18n.language)
    if (this.props.i18n.language.indexOf("-") > 0) {
      this.props.i18n.language = this.props.i18n.languages[1];
    }
    switch (this.props.i18n.language) {
      case 'fr':
        return <img className="flag" alt="fr lang" onClick={() => this.props.i18n.changeLanguage('fr')} src={frLogo}/>
      case 'en':
        return <img className="flag" alt="en lang" onClick={() => this.props.i18n.changeLanguage('en')} src={enLogo}/>
      case "es":
        return <img className="flag" alt="es lang" onClick={() => this.props.i18n.changeLanguage('es')} src={esLogo}/>
      default:
        return <img className="flag" alt="en lang" onClick={() => this.props.i18n.changeLanguage('en')} src={enLogo}/>
    }
  }

  render() {
    return (
      <div>
        <Container className="Common-Container">

          <Navbar variant="custom" fixed="top">
            <Container>
              <Navbar.Brand className="mr-auto">
                <img src={adpLogo} alt="adp logo" style={{height: '40px'}}/>
              </Navbar.Brand>
              <Nav className="mr-2">
                <Button onClick={this.showMenuContact} variant="success"><i className="fas fa-phone"></i> </Button>
              </Nav>

              <Nav className="mr-2">
                <Button onClick={this.showMenu} variant="gold"
                        style={{width: '45px'}}> {this.handleLanguageFlag()} </Button>
              </Nav>

              <Nav>
                <Button variant="gold" onClick={this.signout.bind(this)}><i className="fas fa-sign-out-alt"></i>
                </Button>
              </Nav>
            </Container>
          </Navbar>

          {
            this.state.showMenuLang
              ? (
                <Navbar variant="custom" fixed="top" style={{top: '66px', height: '40px'}}>
                  <Container>
                    <Row style={{flex: 1, flexDirection: 'row-reverse'}}>
                      <Col className="mb-sm-4 text-right">
                        <div className="menu btn btn-gold">
                          <img className="flag" alt="fr lang" onClick={() => this.props.i18n.changeLanguage('fr')}
                               src={frLogo}/>
                          <img className="flag" alt="en lang" onClick={() => this.props.i18n.changeLanguage('en')}
                               src={enLogo}/>
                          <img className="flag" alt="es lang" onClick={() => this.props.i18n.changeLanguage('es')}
                               src={esLogo}/>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Navbar>
              )
              : (
                null
              )

          }

          {
            this.state.showMenuContact
              ? (
                <Navbar variant="custom" fixed="top" style={{top: '66px', height: '40px', marginBottom: '8px'}}>
                  <Container>
                    <Row style={{flex: 1, flexDirection: 'row-reverse', padding: '10px'}}>
                      <Nav className="mr-2">
                        <div className="menu btn btn-gold">
                          <a className="fas fa-envelope-square" style={{color: 'black'}}
                             href="mailto:Hello@ArtDesignPainting.paris"></a>
                        </div>
                        <div className="menu btn btn-gold">
                          <a className="fas fa-mobile-alt" style={{color: 'black'}} href="tel:+33665512312"></a>
                        </div>
                      </Nav>
                    </Row>
                  </Container>
                </Navbar>
              )
              : (
                null
              )
          }

          <BrowserView>
            <Container className="Common-Container Home">
              <Row>
                <Col xs={6}>
                  <div className="text-center" style={{fontWeight: 'bold'}}>
                    <Row>
                      <Col xs={12}>
                        <a href="https://apps.apple.com/us/app/artdesignstory/id1536423226"
                           style={{color: '#B59A4F', fontWeight: 'bold'}}><i
                          className="fab fa-app-store-ios fa-5x text-center"></i></a>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        Apple Store
                      </Col>
                    </Row>
                  </div>
                </Col>
                <Col xs={6}>
                  <div className="text-center" style={{fontWeight: 'bold'}}>
                    <Row>
                      <Col xs={12}>
                        <a style={{color: '#B59A4F', fontWeight: 'bold'}}
                           href="https://play.google.com/store/apps/details?id=fr.artdesignstory.artdesignstory_app.dev&hl=fr&ah=sbX-QgqfBMT2M_EZ0vh6_Vo7O5U"><i
                          className="fab fa-google-play fa-5x text-center"></i></a>
                      </Col>
                    </Row>
                    <Row>
                      <Col xs={12}>
                        Play Store
                      </Col>
                    </Row>
                  </div>
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Footer/>
                </Col>
              </Row>
            </Container>
          </BrowserView>
          <MobileView>
            <Container className="Common-Container Home">
              <Row>
                <Col xs={12}>
                  {isIOS || isIOS13 ?
                    <div className="text-center" style={{fontWeight: 'bold'}}>
                      <Row>
                        <Col xs={12}>
                          <a href="https://apps.apple.com/fr/app/artdesignstory/id1536423226"
                             style={{color: '#B59A4F', fontWeight: 'bold'}}><i
                            className="fab fa-app-store-ios fa-5x text-center"></i></a>
                        </Col>
                      </Row>
                      <Row>
                        <Col xs={12}>
                          {this.props.t('app-link.download')}
                        </Col>
                      </Row>
                    </div>
                    :
                    isAndroid ?
                      <div className="text-center" style={{fontWeight: 'bold'}}>
                        <Row>
                          <Col xs={12}>
                            <a
                              href="https://play.google.com/store/apps/details?id=fr.artdesignstory.artdesignstory_app.dev&hl=fr&ah=sbX-QgqfBMT2M_EZ0vh6_Vo7O5U"
                              style={{color: '#B59A4F', fontWeight: 'bold'}}><i
                              className="fa fa-google-play fa-5x text-center"></i></a>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            {this.props.t('app-link.download')}
                          </Col>
                        </Row>
                      </div>
                      :
                      <div className="text-center" style={{fontWeight: 'bold'}}>
                        <Row>
                          <Col xs={12}>
                            <a href="/home" style={{color: '#B59A4F', fontWeight: 'bold'}}><i
                              className="fa fa-home fa-5x text-center"></i></a>
                          </Col>
                        </Row>
                        <Row>
                          <Col xs={12}>
                            {this.props.t('common.home')}
                          </Col>
                        </Row>
                      </div>
                  }
                </Col>
              </Row>
              <Row>
                <Col md={12}>
                  <Footer/>
                </Col>
              </Row>
            </Container>
          </MobileView>
        </Container>
      </div>
    )
      ;

  }

}

export default withTranslation('translate')(withAlert()(AdsApp));
