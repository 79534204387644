import React, { Component } from 'react';
import { Container, Row, Col, Form, Button, Navbar, Nav } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import Footer from '../Footer/Footer'
import { withAlert } from 'react-alert'

import * as API from '../../API';

import adpLogo from './ads.png';

import frLogo from '../Admin/Navigation/fr.png';
import enLogo from '../Admin/Navigation/en.png';
import esLogo from '../Admin/Navigation/es.png';

import './Signin.css';

class Signin extends Component {

  constructor(props, context) {

    super(props, context);

    this.state = {
      lock: false,
      login: '',
      password: '',
      showMenuLang: false,
    }
    this.showMenuLang = this.showMenuLang.bind(this);
    this.closeMenuLang = this.closeMenuLang.bind(this);
  }

  showMenuLang(event) {
    event.preventDefault();
    this.setState({ showMenuLang: true }, () => {
      document.addEventListener('click', this.closeMenuLang);
    });
    }

    closeMenuLang() {
    this.setState({ showMenuLang: false }, () => {
      document.removeEventListener('click', this.closeMenuLang);
      });
    }

  submitSignin(event) {
    event.preventDefault();
    this.setState({ lock: true }, () => {
      API.signin(this.state.login, this.state.password).then(jsonResponse => {
        localStorage.setItem('myartadmin.token', jsonResponse.token);
        localStorage.setItem('myartadmin.login', jsonResponse.account.login);
        localStorage.setItem('myartadmin.role', jsonResponse.account.role);
        if (jsonResponse.account.role === 'USER') {
          if (jsonResponse.account.passwordReset === 0) {
            this.props.history.push('/home');
          } else {
            this.props.history.push('/pwd');
          }
        } else {
          this.props.history.push('/admin/frames');
        }
      }).catch(() => {
        this.setState({ lock: false });
        this.props.alert.error(this.props.t('signin.signinError'));
      })
    });
  }

  handleLanguageFlag() {
    // console.log("language =>", this.props.i18n.language)
    if(this.props.i18n.language.indexOf("-") > 0) {
      this.props.i18n.language = this.props.i18n.languages[1];
    }
    switch(this.props.i18n.language) {
      case 'fr':
        return <img className="flag" alt="fr lang" onClick={() => this.props.i18n.changeLanguage('fr')} src={frLogo} />
      case 'en':
        return <img className="flag" alt="en lang" onClick={() => this.props.i18n.changeLanguage('en')} src={enLogo} />
      case "es":
        return <img className="flag" alt="es lang" onClick={() => this.props.i18n.changeLanguage('es')} src={esLogo} />
      default:
        return <img className="flag" alt="en lang" onClick={() => this.props.i18n.changeLanguage('en')} src={enLogo} />
    }
  }

  render() {
    this.handleLanguageFlag()
    return (
      <div className="Signin" >
        <Navbar variant="custom" fixed="top">
          <Navbar.Brand style={{ width: '85%'}}>
            </Navbar.Brand>
          <Nav>
		  		  <Button onClick={this.showMenuLang} variant="gold" style={{width: '45px'}}> {this.handleLanguageFlag()} </Button>
          </Nav>
          {this.state.showMenuLang &&
          <Navbar variant="custom" fixed="top" style={{top: '66px', height: '40px', }}>
            <Container>
              <Row style={{flex: 1, flexDirection: 'row-reverse', marginBottom:'8px'}}>
                <Col className="mb-sm-4 text-right">
                  <div  className="menu btn btn-gold">
                    <img className="flag" alt="fr lang" onClick={() => this.props.i18n.changeLanguage('fr')} src={frLogo} />
                    <img className="flag" alt="en lang" onClick={() => this.props.i18n.changeLanguage('en')} src={enLogo} />
                    <img className="flag" alt="es lang" onClick={() => this.props.i18n.changeLanguage('es')} src={esLogo} />
                  </div>
                </Col>
                </Row>
            </Container>
            </Navbar>
          }
        </Navbar>
        {/* <Navbar variant="custom" fixed="top" style={{top: '66px', height: '40px', }}>

				</Navbar> */}
        <div className="Signin-Form-Container">
          <Container fluid>
            <Row>
              <Col md={12}>
                <img src={adpLogo} alt="Logo" className="Signin-Form-Logo" />
              </Col>
              <Col md={12}>
                <Form className="Signin-Form" onSubmit={this.submitSignin.bind(this)}>
                  <Form.Group controlId="form-signin-login">
                    <Form.Label>{this.props.t('signin.login')}</Form.Label>
                    <Form.Control required
                      value={this.state.login}
                      onChange={(event) => this.setState({ login: event.target.value })}
                      type="text"
                      minLength="4"
                      placeholder={this.props.t('signin.login')} />
                  </Form.Group>

                  <Form.Group controlId="form-signin-password">
                    <Form.Label>{this.props.t('signin.password')}</Form.Label>
                    <Form.Control
                      required
                      value={this.state.password}
                      onChange={(event) => this.setState({ password: event.target.value })}
                      type="password"
                      placeholder={this.props.t('signin.password')} />
                  </Form.Group>
                  <Button disabled={this.state.lock} variant="gold" type="submit">
                    {this.props.t('signin.connect')}
                  </Button>
                </Form>
              </Col>
            </Row>
            <Row style={{margin: '1rem 0'}}>
              <Col>
                <a 
                  className="Signin-Copyright text-center signin-forgotten-password"
                  href="/reset-password"
                >
                  <p>{this.props.t('signin.resetPassword')}</p>
                </a>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    );
  }

}

export default withTranslation('translate')(withAlert()(Signin));
