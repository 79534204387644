import React, { Component } from 'react';
import { Button, Form,  Modal } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { withAlert } from 'react-alert';
import { withRouter } from 'react-router';

import * as API from '../../../API';

class CreateAccount extends Component {

  constructor(props, context) {
    super(props, context);

    this.token = localStorage.getItem('myartadmin.token');

    this.state = {
      login: '',
      email: '',
      role: 'USER',
      password: ''
    }

  }

  createNewAccount() {
    API.createAccount({ ...this.state }, this.token)
      .then(account => this.props.history.push('/admin/accounts/' + account.id))
      .catch(() => {
        this.props.alert.error(this.props.t('accounts.createError'));
      })
  }

  render() {

    return (

      <Modal size="md" show={this.props.show} onHide={this.props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.t('accounts.newAccount')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Form.Group>
            <Form.Label>{this.props.t('account.login')}</Form.Label>
            <Form.Control
              required
              minLength={4}
              onChange={(event) => this.setState({login : event.target.value})}
              value={this.state.login}
              type="text"
              placeholder={this.props.t('account.login')}
            />
            <Form.Text>{this.props.t('account.logincontrol')}</Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>{this.props.t('account.email')}</Form.Label>
            <Form.Control
              required
              onChange={(event) => this.setState({email : event.target.value})}
              value={this.state.email}
              type="email"
              placeholder={this.props.t('account.email')}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>{this.props.t('account.password')}</Form.Label>
            <Form.Control
              required
              minLength={4}
              onChange={(event) => this.setState({password : event.target.value})}
              value={this.state.password}
              type="password"
              placeholder={this.props.t('account.password')}
            />
            <Form.Text>{this.props.t('account.passwordcontrol')}</Form.Text>
          </Form.Group>
          <Form.Group>
            <Form.Label>{this.props.t('account.role')}</Form.Label>
            <Form.Control
              as="select"
              required
              onChange={(event) => this.setState({role : event.target.value})}
              value={this.state.role}
              type="text"
              placeholder={this.props.t('account.role')}
            >
              <option value="USER">USER</option>
              <option value="ADMIN">ADMIN</option>
            </Form.Control>
          </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide} variant="secondary">{this.props.t('accounts.close')}</Button>
          <Button disabled={this.state.password.length < 4 || this.state.login.length < 4 || !this.validateEmail(this.state.email)}
            onClick={this.createNewAccount.bind(this)} variant="gold">{this.props.t('accounts.create')}</Button>
        </Modal.Footer>
      </Modal>
    );
  }

  validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

}

export default withRouter(withTranslation('translate')(withAlert()(CreateAccount)));