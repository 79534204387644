import React from 'react';
import './chipsManager.scss';

// const update = React.addons.update;

// const Chips = React.createClass({
//   propTypes: {
//     chips: React.PropTypes.array,
//     max:   React.PropTypes.oneOfType([
//       React.PropTypes.number,
//       React.PropTypes.string
//     ]),
//     maxlength: React.PropTypes.oneOfType([
//       React.PropTypes.number,
//       React.PropTypes.string
//     ]),
//     placeholder: React.PropTypes.string
//   },

class ChipsManager extends React.Component {
constructor(props) {
    super(props)
    this.state = {
        tags: [],
        max: '',
        maxLength: '20',
        placeholder: 'Add a chip...',
        KEY: {
            backspace: 8,
            tab:       9,
            enter:     13,
        },
    }
    this.onKeyDown = this.onKeyDown.bind(this);
    this.setTags = this.setTags.bind(this);
    this.updateTags = this.updateTags.bind(this);
    this.deleteChip = this.deleteChip.bind(this);
    this.focusInput = this.focusInput.bind(this);
}

    componentDidMount() {
        this.setTags(this.props.tags);
    };

    setTags(tags) {
        if (tags && tags.length) {
            this.setState({ tags });
            this.props.handleChangeTags(tags);
        };
    };

    onKeyDown(event) {
        let keyPressed = event.which;

        if (keyPressed === this.state.KEY.enter ||
            (keyPressed === this.state.KEY.tab && event.target.value)) {
        event.preventDefault();
        this.updateTags(event);
        } else if (keyPressed === this.state.KEY.backspace) {
        let tags = this.state.tags;

        if (!event.target.value && tags.length) {
            this.deleteChip(tags[tags.length - 1]);
        }
        }
    };

    updateTags(event) {
        if (!this.props.max ||
            this.state.tags.length < this.props.max) {
        let value = event.target.value;

        if (!value) return;

        let tag = value.trim().toLowerCase();

        if (tag && this.state.tags.indexOf(tag) < 0) {
            const tmpTags = [...this.state.tags]
            tmpTags.push(tag);
            this.setState({
            tags: tmpTags,
            });
            this.props.handleChangeTags(tmpTags);
        }
        }

        event.target.value = '';
    };

    deleteChip(chip) {
        let index = this.state.tags.indexOf(chip);

        if (index >= 0) {
        const tmpTags = [...this.state.tags];
            tmpTags.splice(index, 1);
        this.setState({
            tags: tmpTags,
        });
        this.props.handleChangeTags(tmpTags);
        }
    };

    focusInput(event) {
        let children = event.target.children;

        if (children.length) children[children.length - 1].focus();
    };



    render() {
        let tags = this.state.tags.map((chip, index) => {
            return (
                <span className="chip" key={index}>
                <span className="chip-value">{chip}</span>
                <button type="button" className="chip-delete-button" onClick={this.deleteChip.bind(null, chip)}>x</button>
                </span>
            );
        });

    let placeholder = !this.props.max || tags.length < this.props.max ? this.props.placeholder : '';

    return (
    <div className="chips" onClick={this.focusInput}>
        <input type="text" className="chips-input" placeholder={placeholder} onKeyDown={this.onKeyDown} />
        {tags}
    </div>
    );
}
}

export default ChipsManager;
