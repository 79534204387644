import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';
import { Form, Button } from 'react-bootstrap';
import { withAlert } from 'react-alert'
import { withRouter } from 'react-router';

import * as API from '../../API';

class ResetForm extends Component {

    constructor(props, context) {

        super(props, context);

        this.token = localStorage.getItem('myartadmin.token');

        this.state = {
            lock: false,
            confirmPassword: '',
            password: ''
        }

    }

    submitReset(event) {
        event.preventDefault();
        this.setState({ lock: true }, () => {
            API.updateMyPassword(this.state.password, this.token)
                .then(() => {
                    this.props.history.push('/home');
                    if(this.props.onConfirm) {
                        this.props.onConfirm();
                    }
                })
                .catch(() => {
                    this.props.alert.error(this.props.t('resetpassword.error'));
                });
        });
    }

    render() {

        return (
            <Form className="Signin-Form" onSubmit={this.submitReset.bind(this)}>

                <Form.Group controlId="form-reset-pwd">
                    <Form.Label>{this.props.t('resetpassword.password')}</Form.Label>
                    <Form.Control required
                        value={this.state.password}
                        onChange={(event) => this.setState({ password: event.target.value })}
                        type="password"
                        minLength="4"
                        placeholder={this.props.t('resetpassword.password')} />
                </Form.Group>

                <Form.Group controlId="form-reset-confirmPwd">
                    <Form.Label>{this.props.t('resetpassword.passwordConfirm')}</Form.Label>
                    <Form.Control
                        required
                        value={this.state.confirmPassword}
                        onChange={(event) => this.setState({ confirmPassword: event.target.value })}
                        type="password"
                        minLength="4"
                        placeholder={this.props.t('resetpassword.passwordConfirm')} />
                </Form.Group>
                <Button disabled={this.state.lock || this.state.password.length < 4 || this.state.password !== this.state.confirmPassword} variant="gold" type="submit">
                    {this.props.t('resetpassword.confirm')}
                </Button>
                {this.props.onCancel &&
                    <Button className="ml-sm-2" disabled={this.state.lock} variant="gold" type="button" onClick={this.props.onCancel}>
                        {this.props.t('resetpassword.cancel')}
                    </Button>
                }
            </Form>
        );
    }

}

export default withRouter(withTranslation('translate')(withAlert()(ResetForm)));