import i18n from 'i18next';
import translateEN from './en.json';
import translateFR from './fr.json';
import translateES from './es.json';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'fr',
    debug: false,
    interpolation: {
      escapeValue: false, // not needed for react as it escapes by default
    },
    languages: ['en', 'fr', 'es'],
    resources: {
        en: {
            translate: translateEN
        },
        fr: {
            translate: translateFR
        },
        es : {
          translate: translateES
        }
    }
  });


export default i18n;