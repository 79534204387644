import React, { Component } from 'react';
import { Container, Row, Col, Table, Button, Form, ButtonGroup } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { withAlert } from 'react-alert';
import PageToggle from '../PageToggle/PageToggle';

import CampaignList from "./CampaignList";

class Campaigns extends Component {

  constructor(props, context) {
    super(props, context);

    this.token = localStorage.getItem('myartadmin.token');

    this.searchTimeout = null;

    this.state = {
      showModal: false,
      loading: false,
    }
  }

  render() {

    return (
      <Container className="Common-Container">
        <Row>
          <Col md={12}>
            <PageToggle />
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <h1>{this.props.t('campaigns.title-list')}</h1>
          </Col>
        </Row>
        <CampaignList filter={true}/>
      </Container>
    );
  }

}

export default withTranslation('translate')(withAlert()(Campaigns));
