import React, { Component } from 'react';
import { Container, Row, Col, Table, Button, Form, ButtonGroup } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import { withTranslation } from 'react-i18next';
import { withAlert } from 'react-alert';
import PageToggle from '../PageToggle/PageToggle';

import * as API from '../../../API';
import CreateAccount from './CreateAccount';

class Accounts extends Component {

  constructor(props, context) {
    super(props, context);

    this.token = localStorage.getItem('myartadmin.token');

    this.searchTimeout = null;

    this.state = {
      showModal: false,
      loading: false,
      accounts: [],
      filters: {
        email: '',
        login: '',
        platform: '',
        role: '',
      },
      sortField: 'login',
      sortOrder: 'ASC',
      offset: 0,
      totalAccounts: 0
    }
  }

  componentDidMount() {

    if (this.token === undefined || this.token === null) {
      this.props.alert.error(this.props.t('accounts.loadError'));
    } else {
      this.refreshAccounts();
    }
  }

  componentDidUpdate(prevProps, prevState) {

    if (this.state.filters.login !== prevState.filters.login || this.state.filters.email !== prevState.filters.email
      || this.state.filters.platform !== prevState.filters.platform || this.state.filters.role !== prevState.filters.role) {
      if (this.searchTimeout !== null) {
        clearTimeout(this.searchTimeout);
      }
      this.searchTimeout = setTimeout(this.refreshAccounts.bind(this), 500);
    }
  }

  refreshAccounts() {
    API.fetchAccounts(this.state.filters, this.state.sortField, this.state.sortOrder, this.state.offset, this.token).then(accounts => {
      this.setState({ totalAccounts: accounts.totalAccounts, loading: false, accounts: accounts.data })
    }).catch(() => {
	  this.props.alert.error(this.props.t('accounts.loadError'));
    })
  }

  setFilter(field, value) {
    const filters = { ...this.state.filters };
    filters[field] = value;
    this.setState({ offset: 0, filters });
  }

  changeSort(field) {
    if (this.state.sortField === field) {
      this.setState({ sortOrder: this.state.sortOrder === 'ASC' ? 'DESC' : 'ASC' }, this.refreshAccounts.bind(this));
    } else {
      this.setState({ sortField: field, sortOrder: 'ASC' }, this.refreshAccounts.bind(this));
    }
  }

  render() {
    return (
      <Container className="Common-Container">
        <Row>
          <Col md={12}>
            <PageToggle />
          </Col>
        </Row>
        <Row>
          <Col md={8}>
            <h1>{this.props.t('accounts.title')}</h1>
          </Col>
          <Col md={4} className="text-right">
            <Button variant="gold" onClick={() => this.setState({ showModal: true })}><i className="fas fa-plus"></i> {this.props.t('accounts.new')}</Button>
          </Col>
        </Row>
        <Row>
          <Col md={1} style={{padding:'0 0 0 10px'}}>
            <p style={{ width: '100%' }} className="mt-sm-1">
              <strong>{this.props.t('accounts.filter-title')}</strong>
            </p>
          </Col>
          <Col md={4}>
            <Form>
              <Form.Row>
                <Form.Group as={Col} controlId="accounts-login-filter">
                  <Form.Control onChange={(event) => this.setFilter('login', event.target.value)} type="text" placeholder={this.props.t('accounts.loginfilter')} />
                </Form.Group>
                <Form.Group as={Col} controlId="accounts-email-filter">
                  <Form.Control onChange={(event) => this.setFilter('email', event.target.value)} type="text" placeholder={this.props.t('accounts.emailfilter')} />
                </Form.Group>
                <Form.Group as={Col} controlId="accounts-platform-filter">
                  <Form.Control onChange={(event) => this.setFilter('platform', event.target.value)} type="text" placeholder={this.props.t('accounts.platformfilter')} />
                </Form.Group>
                <Form.Group as={Col} controlId="accounts-role-filter">
                  <Form.Control onChange={(event) => this.setFilter('role', event.target.value)} type="text" placeholder={this.props.t('accounts.role')} />
                </Form.Group>
              </Form.Row>
            </Form>
          </Col>
        </Row>
        {this.state.accounts.length === 0 &&
          <Row>
            <Col md={{ span: 6, offset: 3 }}>
              <p className="mt-sm-4 text-center"><strong>{this.props.t('accounts.noaccounts')}</strong></p>
            </Col>
          </Row>
        }
        {this.state.accounts.length > 0 &&
          <Row className="justify-content-md-center">
            <Col md={12}>
              <Table variant="custom" size="sm" responsive="md" borderless>
                <thead>
                  <tr>
                    <th className={'clickable' + (this.state.sortField === 'login' ? ' sorted-' + this.state.sortOrder : '')}
                      onClick={() => this.changeSort('login')}>{this.props.t('accounts.login')}</th>
                    <th className={'clickable' + (this.state.sortField === 'email' ? ' sorted-' + this.state.sortOrder : '')}
                      onClick={() => this.changeSort('email')}>{this.props.t('accounts.email')}</th>
                    <th className={'clickable' + (this.state.sortField === 'role' ? ' sorted-' + this.state.sortOrder : '')}
                      onClick={() => this.changeSort('role')}>{this.props.t('accounts.role')}</th>
                    <th className={'clickable' + (this.state.sortField === 'platform' ? ' sorted-' + this.state.sortOrder : '')}
                      onClick={() => this.changeSort('platform')}>{this.props.t('accounts.platform')}</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {
                    this.state.accounts.map((a,i) => {
                      return <tr key={a.id} className={i%2 === 0 ? 'even' : 'odd'}>
                        <td>{a.login}</td>
                        <td>{a.email}</td>
                        <td>{a.role}</td>
                        <td>{a.platform === null ? 'WEB' : a.platform}</td>
                        <td>
                          <LinkContainer to={'/admin/accounts/' + a.id}>
                            <Button variant="gold" size="sm" className="small-button"><i className="fas fa-edit" style={{transform: 'translate(1px, 3px)'}}></i></Button>
                          </LinkContainer>
                        </td>
                      </tr>
					})
                  }
                </tbody>
              </Table>
            </Col>
            {this.state.totalAccounts > 10 &&
              <Col md={4} className="text-right">
                <ButtonGroup>
                  <Button disabled={this.state.offset === 0} onClick={() => this.setState({ offset: 0 }, () => this.refreshAccounts())} variant="gold">&lt;&lt;</Button>
                  <Button disabled={this.state.offset === 0} onClick={() => this.setState({ offset: Math.max(this.state.offset - 10, 0) }, () => this.refreshAccounts())} variant="gold">&lt;</Button>
                  <Button variant="light">{this.state.offset + 1} - {this.state.offset + 10}</Button>
                  <Button disabled={this.state.offset + 10 >= this.state.totalAccounts} onClick={() => this.setState({ offset: this.state.offset + 10 }, () => this.refreshAccounts())} variant="gold">&gt;</Button>
                  <Button disabled={this.state.offset + 10 >= this.state.totalAccounts} onClick={() => this.setState({ offset: (Math.floor(this.state.totalAccounts / 10) * 10) }, () => this.refreshAccounts())} variant="gold">&gt;&gt;</Button>
                </ButtonGroup>
              </Col>
            }
          </Row>
        }
        <CreateAccount show={this.state.showModal} onHide={() => this.setState({ showModal: false })} />
      </Container>
    );
  }

}

export default withTranslation('translate')(withAlert()(Accounts));
