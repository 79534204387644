import React, {Component} from 'react';
import {withTranslation} from 'react-i18next';
import {withAlert} from 'react-alert';
import {FlexibleHeightXYPlot, Hint, HorizontalBarSeries, VerticalGridLines, XAxis, YAxis} from 'react-vis';
import {Button, Col, Form} from "react-bootstrap";
import Datetime from 'react-datetime';
import * as moment from 'moment';

import * as API from '../../API';

import './redirectChart.css';
class RedirectChart extends Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      hint: false,
      selectedZone: '',
      number: 0,
      date: moment().startOf('day').toDate(),
      zones: this.props.zones,
      xDomain: [],
      chartDate: [],
      windowWidth: 0,
    }
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
    this.token = localStorage.getItem('myartadmin.token');
    this.role = localStorage.getItem('myartadmin.role');
    this.frameId = this.props.frameId;
    this.targetLabel = this.props.targetlabel;
    this.saveHint = this.saveHint.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
  }
  
  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }
  
  updateWindowDimensions() {
    this.setState({ windowWidth: window.innerWidth });
  }

  saveHint(event) {
    API.saveHint(this.token, this.state.selectedZone, this.state.number, moment(this.state.date).format('L')).then(() => {
      this.props.alert.success('Mise à jour effectuée avec succès');
      this.uploadChart();
    });
    event.stopPropagation();
    event.preventDefault();
  }

  uploadChart() {
    API.fetchZones(this.frameId, this.token).then((newZones) => {
      this.setState({
        zones: newZones.filter((nz) => nz.target === this.targetLabel).sort((ez1, ez2) => {
          return ('' + ez1['index']).localeCompare(ez2['index']);
        }),
      });
      this.initChart();
    });
  }

  initChart() {
    this.state.chartData = this.state.zones.map(z => {
      return {
        id: z.id,
        color: z.redirectCount,
        y: z.label.substr(0, 15),
        x: z.redirectCount
      }
    });

    if (this.state.chartData.length === 0) {
      return <div></div>
    } else {
      this.state.chartData.reverse();
    }

    this.state.xDomain = [0, Math.max(this.state.chartData.reduce((max, z) => {
      return z.x > max ? z.x : max;
    }, 0), 10)];
    console.log("this.state =>", this.state)
  }

  handleReset(event) {
    console.log("reset")
    API.saveHint(this.token, this.state.selectedZone, 0, null).then(() => {
      this.props.alert.success('Mise à jour effectuée avec succès');
      this.uploadChart();
    });
    event.stopPropagation();
    event.preventDefault();
  }

  render() {
    console.log("this.State.date =>", this.state.date)
    const CustomDatetimeStart = React.forwardRef(({ children, onChange}, ref) => (
      <Datetime
          dateFormat="DD/MM/YYYY"
          timeFormat="HH:mm"
          closeOnSelect={true}
          // isValidDate={ validStartDate }
          value={this.state.date}
          onChange={(d) => {
            if (typeof d !== 'string') {
              this.setState({date: d.toDate()})
              // this.handleOnChange('date', d.toDate())
            }
          }}/>
    ));

    this.initChart();
    return (
      <div>
        <div className="stat-chart-container">
          <FlexibleHeightXYPlot
            margin={{top: 50, bottom: 10, left: 100}}
            yType="ordinal"
            height={100 + this.state.chartData.length * 20}
            width={this.state.windowWidth > 768 ? this.state.windowWidth * 0.6 :  this.state.windowWidth * 0.8}
            xDomain={this.state.xDomain}>
            <VerticalGridLines style={{stroke: 'white'}}/>
            <XAxis
              orientation="top"
              tickFormat={d => {
                return  Math.round(d) === d ? d : '';
              }}
              style={{
                line: {stroke: '#B59A4F'},
                ticks: {stroke: '#ffffff'},
                text: {stroke: 'none', fill: '#ffffff'},
              }}
            />
            <YAxis style={{
              line: {stroke: '#B59A4F'},
              ticks: {stroke: '#ffffff'},
              text: {stroke: 'none', fill: '#ffffff'},
              orientation: 'right'
            }}/>
            <HorizontalBarSeries
              colorType="linear"
              colorRange={['#cf7d75', '#af2719']}
              colorDomain={this.state.xDomain}
              data={this.state.chartData}
              onValueMouseOut={() => this.setState({hint: false})}
              onValueMouseOver={(v) => this.setState({hint: v})}
            />
            {this.state.hint &&
            <Hint value={this.state.hint} align={{vertical: 'top', horizontal: 'left'}}>
              <div style={{
                background: 'black',
                color: 'white',
                fontSize: '0.8em',
                textAlign: 'center',
                padding: '3px',
                borderRadius: '4px',
              }}>
                {this.state.hint.x}
              </div>
            </Hint>
            }
          </FlexibleHeightXYPlot>
        </div>
        <div style={{position: 'relative', left: '7%'}}>
        { this.role === 'ADMIN' &&
          <div style={{paddingBottom: 20,}}>
            <div style={{maxWidth: '80%'}}>
              <h5>
                {this.props.t('frame.manageStats')}
              </h5>
            </div>
            <Form onSubmit={this.saveHint}>
              <Form.Row
                style={{
                  maxWidth: '67%',
                  marginBottom: '0.5rem',
                }}>
                <Col>
                  <Form.Control
                      as="select"
                      style={{minWidth: '150px'}}
                      value={this.state.selectedZone}
                      placeholder={this.props.t('frame.newzonename')}
                      onChange={(event) => this.setState({selectedZone: event.target.value})}>
                      <option value=''>---</option>
                      {
                        this.props.zones.map(t => {
                          return <option key={t.label} value={t.id}>{t.label}</option>;
                        })
                      }
                  </Form.Control>
                </Col>
                <Col>
                <Button disabled={this.state.selectedZone.length === 0} onClick={(e) => this.handleReset(e)} className="mr-2 reset-button-margin-top" variant="gold" style={{height: '35px'}}>
                  <span style={{fontFamily: "Helvetica Neue, 'Roboto', sans-serif", fontWeight: '600'}}>{this.props.t('frame.reset').toUpperCase()}</span>
                </Button>
              </Col>
              </Form.Row>
              <Form.Row>
                <Col>
                  <Form.Control
                    style={{}}
                    as={CustomDatetimeStart}
                    required
                  ></Form.Control>
                </Col>
                <Col>
                  <Form.Control
                    value={this.state.number}
                    placeholder={this.props.t('frame.number')}
                    onChange={(event) => this.setState({number: event.target.value < 0 ? 0 : event.target.value})}
                    type="number"
                    min="0"
                    disabled={this.state.selectedZone === ""}>
                  </Form.Control>
                </Col>
                <Col>
                  <Button type="submit" className="mr-2 small-button" variant="gold" disabled={this.state.selectedZone === ""}><i
                    className="fas fa-save"></i></Button>
                </Col>
              </Form.Row>
            </Form>
          </div>
        }
        </div>
      </div>
    );
  }


}

export default withTranslation('translate')(withAlert()(RedirectChart));
