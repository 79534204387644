import React, { Component } from 'react';
import { Switch, Route } from 'react-router-dom'
import Navigation from './Navigation/Navigation';
import Frames from './Frames/Frames';
import Frame from './Frame/Frame';
import Accounts from './Accounts/Accounts';
import Account from './Account/Account';
import Footer from '../Footer/Footer.js'
import Redirections from './Redirections/Redirections';
import SuperPlanification from './SuperPlanification/SuperPlanification';
import Campaigns from "./Campaigns/Campaigns";
import Campaign from "./Campaigns/Campaign";
import CampaignManager from './Campaigns/CampaignManager';
import UserRightsManager from './UserRights/UserRightsManager';
class Admin extends Component {

  render() {
    return (
      <div className="Admin">
        <Navigation />
        <Switch>
          <Route exact path='/admin/frames' component={Frames} />
          <Route exact path='/admin/frames/:frameId' component={Frame} />
          <Route exact path='/admin/frames/:frameId/users/:userId' component={UserRightsManager} />
          <Route exact path='/admin/frames/:frameId/campaign/:campaignId' component={Campaign} />
          <Route exact path='/admin/accounts' component={Accounts} />
          <Route exact path='/admin/accounts/:accountId' component={Account} />
          <Route exact path='/admin/redirections' component={Redirections} />
          <Route exact path='/admin/super-planifications' component={SuperPlanification} />
          <Route exact path='/admin/campaigns' component={Campaigns} />
          <Route exact path='/admin/campaigns/:campaignId' component={CampaignManager} />
        </Switch>
        <Footer />
      </div>
    );
  }
}

export default Admin;
