import React, { Component } from 'react';
import { Button, Form, Modal, Spinner, Row } from 'react-bootstrap';
import Toast from 'react-bootstrap/Toast'
import { withTranslation } from 'react-i18next';
import { withAlert } from 'react-alert';
import { withRouter } from 'react-router';

import * as API from '../../API'

class VideoUpload extends Component {

  constructor(props, context) {
    super(props, context);

    this.token = localStorage.getItem('myartadmin.token');

    this.state = {
      title: '',
      description: '',
      provider: 'videas',
      videoUrl: '',
      uploading: false,
      showToast: false,
    }
    this.setShow = this.setShow.bind(this)
  }

  setShow(value) {
    this.setState({
      showToast: value,
    })
  }

  initializeModal() {
    this.setState({
      title: '',
      description: '',
      provider: 'videas',
      videoUrl: '',
      uploading: false
    });
  }

  uploadVideo() {
    this.setState({
      uploading: true
    }, () => {
      API.uploadVideo(this.props.zoneId.frameId, this.props.zoneId.target, this.props.zoneId.index,
        this.state.videoUrl, this.state.title, this.state.description, this.state.provider, null, null, this.token)
        .then(() => {
          // alert(this.props.t('home.videouploadalert'));

          // la video a bien été envoyé, mais juste une alerte, pas de catch d'erreurs...
          // example();
          this.setShow(true);
          this.props.onHide()
        });
    })
  }

  render() {

    return (
      <React.Fragment>
        <div style={{ position: 'fixed', top: '140px', right: '20px'}}>
        <Toast onClose={() => this.setShow(false)} show={this.state.showToast} delay={3000} autohide>
          <Toast.Header>
            <img
              src="holder.js/20x20?text=%20"
              className="rounded mr-2"
              alt=""
            />
            <strong className="mr-auto">Merci !</strong>
            <small>Just now</small>
          </Toast.Header>
          <Toast.Body style={{ color: 'black'}}>
            Votre vidéo a bien été envoyé.
          </Toast.Body>
        </Toast>
      {/* <Col xs={6}>
        <Button onClick={() => this.setShow(true)}>Show Toast</Button>
      </Col> */}
    </div>
      <Modal size="lg"
        show={this.props.show}
        onEnter={this.initializeModal.bind(this)}
        onHide={this.props.onHide}>
        <Modal.Header closeButton>
          <Modal.Title>{this.props.t('home.videoupload')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.props.showProvider &&
            <Form.Group>
              <Form.Label>{this.props.t('home.videouploadprovider')}</Form.Label>
              <Form.Control as="select"
                value={this.state.provider}
                onChange={(event) => this.setState({ provider: event.target.value })}>
                <option value="videos">Videas</option>
                {/* <option value="youtube">Youtube</option> */}
                {/* <option value="campagne">Campagne</option> */}
              </Form.Control>
            </Form.Group>
          }
             <Form.Group>
             <Form.Label>{this.props.t('home.videouploadtitle')}</Form.Label>
             <Form.Control
               onChange={(event) => this.setState({ title: event.target.value })}
               value={this.state.title}
               type="text"
               placeholder={this.props.t('home.videouploadtitle')}
             />
           </Form.Group>
           <Form.Group>
             <Form.Label>{this.props.t('home.videouploaddesc')}</Form.Label>
             <Form.Control
               onChange={(event) => this.setState({ description: event.target.value })}
               value={this.state.description}
               type="text"
               placeholder={this.props.t('home.videouploaddesc')}
             />
           </Form.Group>
           <Form.Group>
             <Form.Label>{this.props.t('home.videouploadfile')}</Form.Label>
             <Form.Control
               id="new-frame-externalId"
               onChange={(event) => this.setState({ videoUrl: event.target.files[0] !== undefined ? event.target.files[0] : null })}
               value={this.state.targetUrl}
               type="file"
               placeholder={this.props.t('home.videouploadfile')}
             />
           </Form.Group>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.props.onHide} variant="secondary">{this.props.t('frame.close')}</Button>
          <Button disabled={this.state.title === ''
            || this.state.description === '' || this.state.uploading}
            onClick={this.uploadVideo.bind(this)} variant="gold">
            {this.state.uploading ?
              // this.props.t('home.uploading')
              <Spinner animation="border" role="status" size="sm">
                <span className="sr-only">Loading...</span>
              </Spinner>
            :
              this.props.t('home.upload')}
          </Button>
        </Modal.Footer>
      </Modal>
      </React.Fragment>

    );
  }

}

export default withRouter(withTranslation('translate')(withAlert()(VideoUpload)));