import React, {Component} from 'react';
import {Button, Col, Container, Form, Row} from 'react-bootstrap';
import * as moment from 'moment';
import Datetime from 'react-datetime';
import ChipsManager from './ChipsManager';
import ReactFlagsSelect from 'react-flags-select';
import 'react-flags-select/css/react-flags-select.css';
import './campaignForm.css';
import {DefaultEditor} from 'react-simple-wysiwyg';
import {IconButton} from "@material-ui/core";
import {Add, CheckCircleOutlined, Delete, ErrorOutlined} from "@material-ui/icons";
import * as API from '../../../API';
import {DebounceInput} from 'react-debounce-input';
import {withTranslation} from "react-i18next";
import {withAlert} from "react-alert";

class CampaignForm extends Component {

  constructor(props, context) {

    super(props, context);
    this.isUpdate = this.props.isUpdate;

    this.state = {
      name: '',
      startDate: moment().startOf('day').toDate(),
      endDate: moment().startOf('day').add(1, 'day').toDate(),
      vip: false,
      fan: false,
      family: false,
      cost: '',
      durationVideoFan: '20',
      durationVideoVip: '20',
      durationVideoFamily: '20',
      codeVip: '',
      vipUnique: true,
      fanUnique: true,
      familyUnique: true,
      formIsValid: false,
      tags: [],
      landingUrl: process.env.REACT_APP_FAN_ART_ZONE + '/',
      logo: null,
      imageHeader: null,
      imageForm: null,
      translations: [{lang: 'FR', vip: 'Description VIP', fan: 'Description FAN', family: 'Description Famille'}],
      ...this.props.campaign,
      uniqueKeyExists: false,
    }
    this.handleOnChange = this.handleOnChange.bind(this);
    this.checkState = this.checkState.bind(this);
    this.addTranslations = this.addTranslations.bind(this);
    moment.locale(this.props.i18n.language);
  }

  componentDidMount() {
    this.props.isFormOk(!this.checkState());
  }

  handleOnChange(key, value) {
    if (key === 'uniqueKey') {
      if (value.match("^[a-zA-Z0-9-]*$") !== null) {
        if (value && value.length > 0) {
          API.campaignUniqueKeyExists(this.props.token, value).then((result) => {
            if (result.exists) {
              this.setState({
                uniqueKeyExists: true,
              });
            } else {
              this.setState({
                uniqueKeyExists: false,
              });
              const tmpValue = process.env.REACT_APP_FAN_ART_ZONE + '/' + value.toLowerCase();
              this.setState({
                landingUrl: tmpValue,
                [key]: value.toLowerCase(),
              }, this.sendIsFormOk);
              this.props.onHandleChange(key, value.toLowerCase());
            }
          })
        } else {
          this.setState({
            uniqueKeyExists: false,
          });
          const tmpValue = process.env.REACT_APP_FAN_ART_ZONE + '/' + value;
          this.setState({
            landingUrl: tmpValue,
            [key]: value,
          }, this.sendIsFormOk);
          this.props.onHandleChange(key, value);
        }
      }
    } else {
      this.setState({
        [key]: value,
      }, this.sendIsFormOk);
      this.props.onHandleChange(key, value);
    }
  }

  sendIsFormOk() {
    this.props.isFormOk(!this.checkState());
  }

  checkState() {
    const {
      name,
      durationVideoFan,
      durationVideoFamily,
      codeVip,
      vip,
      fan,
      family,
      imageHeader,
      imageForm,
      headerUrl,
      imageFormUrl,
    } = this.state;
    return !name || (fan && !durationVideoFan) || (family && !durationVideoFamily)
      || (vip && !codeVip) || (!vip && !fan && !family) || (!imageForm && !imageFormUrl) || (!imageHeader && !headerUrl);
  }

  verifyAndSend = event => {
    const form = event.currentTarget;
    if (form.checkValidity() === false) {
      event.preventDefault();
      event.stopPropagation();
      this.setState({
        formIsValid: true,
      });
    } else {
      event.preventDefault();
      event.stopPropagation();
      this.props.checkAndSend();
      this.setState({
        formIsValid: true,
      });
    }
  }

  addTranslations() {
    if (!this.state.translations) {
      this.setState({
        translations: [{lang: 'FR', vip: 'Description VIP', fan: 'Description FAN', family: 'Description Famille'}],
      });
    } else {
      this.state.translations.push({
        lang: 'FR',
        vip: 'Description VIP',
        fan: 'Description FAN',
        family: 'Description Famille'
      });
      this.setState({
        translations: this.state.translations,
      });
    }
  }

  render() {
    const campaign = this.state;
    const yesterday = Datetime.moment().subtract(1, 'day');
    const validStartDate = function (current) {
      return current.isAfter(yesterday);
    };

    const CustomDatetimeStart = React.forwardRef(({children, onChange}, ref) => (
      <Datetime
        dateFormat="DD/MM/YYYY"
        timeFormat="HH:mm"
        closeOnSelect={true}
        isValidDate={validStartDate}
        value={moment(campaign.startDate).format('L')}
        onChange={(d) => {
          if (typeof d !== 'string') {
            this.handleOnChange('startDate', d.toDate())
          } else this.handleOnChange('startDate', moment(d).toDate());
        }}/>
    ));

    const CustomDatetimeEnd = React.forwardRef(({children, onChange}, ref) => (
      <Datetime
        dateFormat="DD/MM/YYYY"
        timeFormat="HH:mm"
        closeOnSelect={true}
        isValidDate={validStartDate}
        value={moment(campaign.endDate).format('L')}
        onChange={(d) => {
          if (typeof d !== 'string') {
            this.handleOnChange('endDate', d.toDate())
          } else this.handleOnChange('endDate', moment(d).toDate());
        }}/>
    ));

    // const CustomChipsManager = React.forwardRef(({ children, onChange}, ref) => (
    //   <Chips
    //       value={campaign.chips}
    //       onChange={e => this.handleOnChange('chips', e.target.value)}
    //       suggestions={["Your", "Data", "Here"]}

    //     />
    // ));

    return (
      <React.Fragment>
        <Form onSubmit={this.verifyAndSend} noValidate validated={this.state.formIsValid}>
          <Form.Group>
            <Form.Label>{this.props.t('campaigns.form.uniqueKey')}</Form.Label>
            <Form.Control type="text" placeholder={this.props.t('campaigns.form.uniqueKey-placeholder')}
                          onChange={e => this.handleOnChange('uniqueKey', e.target.value)} value={campaign.uniqueKey}
                          required style={{display: 'none'}}/>
            <div style={{position: 'relative', width: '100%'}}>
              <DebounceInput
                disabled={this.state.id}
                className="form-control"
                minLength={2}
                debounceTimeout={300}
                onChange={e => this.handleOnChange('uniqueKey', e.target.value)} value={campaign.uniqueKey}/>
              <div style={{position: "absolute", right: '5px', top: '5px'}}>
                {((campaign.uniqueKey && campaign.uniqueKey.length > 0) || this.state.uniqueKeyExists) &&
                <div>
                  {!this.state.uniqueKeyExists && <CheckCircleOutlined style={{color: "green",}}/>}
                  {this.state.uniqueKeyExists && <ErrorOutlined style={{color: "red",}}/>}
                </div>
                }
              </div>
              {this.state.uniqueKeyExists &&
              <div style={{color: 'red', fontSize: '0.7rem',}}>{this.props.t('campaigns.form.unique-key-exists')}</div>}
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>{this.props.t('campaigns.form.name')}</Form.Label>
            <Form.Control type="text" placeholder={this.props.t('campaigns.form.name-placeholder')}
                          onChange={e => this.handleOnChange('name', e.target.value)} value={campaign.name} required/>
          </Form.Group>
          <Form.Group>
            <Form.Label>{this.props.t('campaigns.form.url')}</Form.Label>
            <Form.Control type="text" placeholder={this.props.t('campaigns.form.url-placeholder')}
              // onChange={e => this.handleOnChange('landingUrl', e.target.value)}
                          disabled
                          value={campaign.landingUrl} required/>
          </Form.Group>
          <Form.Group>
            {this.props.isNotCreation &&
            <React.Fragment>
              <Form.Group>
                <Form.Label>{this.props.t('campaigns.form.targetLabel')}</Form.Label>
                <Form.Control type="text" placeholder={this.props.t('campaigns.form.name-placeholder')}
                              value={campaign.targetLabel} disabled/>
              </Form.Group>
              <Form.Group>
                <Form.Label>{this.props.t('campaigns.form.targetUrl')}</Form.Label>
                <Form.Control type="text" placeholder={this.props.t('campaigns.form.name-placeholder')}
                              value={campaign.targetUrl} disabled/>
              </Form.Group>
            </React.Fragment>
            }
            <Form.Label>{this.props.t('campaigns.form.start-date')}</Form.Label>
            <Form.Control
              style={{}}
              as={CustomDatetimeStart}
              required
            >
            </Form.Control>
            {moment(campaign.startDate).isAfter(moment(campaign.endDate)) &&
            <p style={{fontSize: '80%', color: '#dc3545'}}>{this.props.t('campaigns.form.end-date-error')}</p>
            }
          </Form.Group>
          <Form.Group>
            <Form.Label>{this.props.t('campaigns.form.end-date')}</Form.Label>
            <Form.Control
              as={CustomDatetimeEnd}
              required
            >

            </Form.Control>
            {moment(campaign.endDate).isBefore(Datetime.moment(campaign.startDate)) &&
            <p style={{fontSize: '80%', color: '#dc3545'}}>{this.props.t('campaigns.form.start-date-error')}</p>
            }
          </Form.Group>
          <Form.Group controlId="exampleForm.ControlSelect2">
            <Form.Label>{this.props.t('campaigns.form.type')}</Form.Label>
            <div style={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'space-around'
            }}>
              <Container fluid={true}>
                <Row xs={1} md={3}>
                  <Col xs={12} md={4}>
                    <Form.Check
                      type='checkbox'
                      id={`default-checkbox-VIP`}
                      label={this.props.t('campaigns.form.vip')}
                      onChange={e => this.handleOnChange('vip', e.target.checked)}
                      checked={campaign.vip}
                      required={!campaign.vip && !campaign.family && !campaign.fan}
                    />
                    {campaign.vip &&
                    <Form.Group>
                      <Form.Label>{this.props.t('campaigns.form.duration-max')}</Form.Label>
                      <Form.Control type="text" placeholder={this.props.t('campaigns.form.duration-vip')}
                                    onChange={e => this.handleOnChange('durationVideoVip', e.target.value)}
                                    value={campaign.durationVideoVip} required/>
                    </Form.Group>
                    }
                    {campaign.vip &&
                    <Form.Group>
                      <Form.Label>{this.props.t('campaigns.form.code-vip')}</Form.Label>
                      <Form.Control type="text" placeholder={this.props.t('campaigns.form.code-vip-placeholder')}
                                    onChange={e => this.handleOnChange('codeVip', e.target.value)}
                                    value={campaign.codeVip} required
                                    isValid={campaign.codeVip && campaign.codeVip.length >= 5}
                                    isInvalid={campaign.vip && campaign.codeVip && campaign.codeVip.length < 5}/>
                      <Form.Control.Feedback type="invalid">
                        {this.props.t('campaigns.form.code-vip-error')}
                      </Form.Control.Feedback>
                    </Form.Group>
                    }
                    {campaign.vip &&
                    <Form.Check
                      type='checkbox'
                      id={`default-checkbox-VIP`}
                      label={this.props.t('campaigns.form.unique-story')}
                      onChange={e => this.handleOnChange('vipUnique', e.target.checked)}
                      checked={campaign.vipUnique}
                    />
                    }
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Check
                      type='checkbox'
                      id={`default-checkbox-FAN`}
                      label={this.props.t('campaigns.form.fan')}
                      onChange={e => this.handleOnChange('fan', e.target.checked)}
                      checked={campaign.fan}
                      required={!campaign.vip && !campaign.family && !campaign.fan}
                    />
                    {campaign.fan &&
                    <Form.Group>
                      <Form.Label>{this.props.t('campaigns.form.duration-max')}</Form.Label>
                      <Form.Control type="text" placeholder={this.props.t('campaigns.form.duration-fan')}
                                    onChange={e => this.handleOnChange('durationVideoFan', e.target.value)}
                                    value={campaign.durationVideoFan} required/>
                    </Form.Group>
                    }
                    {campaign.fan &&
                    <Form.Check
                      type='checkbox'
                      id={`default-checkbox-VIP`}
                      label={this.props.t('campaigns.form.unique-story')}
                      onChange={e => this.handleOnChange('fanUnique', e.target.checked)}
                      checked={campaign.fanUnique}
                    />
                    }
                  </Col>
                  <Col xs={12} md={4}>
                    <Form.Check
                      type='checkbox'
                      id={`default-checkbox-FAMILY`}
                      label={this.props.t('campaigns.form.family')}
                      onChange={e => this.handleOnChange('family', e.target.checked)}
                      checked={campaign.family}
                      required={!campaign.vip && !campaign.family && !campaign.fan}
                    />
                    {campaign.family &&
                    <Form.Group>
                      <Form.Label>{this.props.t('campaigns.form.duration-max')}</Form.Label>
                      <Form.Control type="text" placeholder={this.props.t('campaigns.form.duration-family')}
                                    onChange={e => this.handleOnChange('durationVideoFamily', e.target.value)}
                                    value={campaign.durationVideoFamily} required/>
                    </Form.Group>
                    }
                    {campaign.family &&
                    <Form.Check
                      type='checkbox'
                      id={`default-checkbox-VIP`}
                      label={this.props.t('campaigns.form.unique-story')}
                      onChange={e => this.handleOnChange('familyUnique', e.target.checked)}
                      checked={campaign.familyUnique}
                    />
                    }
                  </Col>
                </Row>
              </Container>
            </div>
          </Form.Group>
          <Form.Group>
            <Form.Label>{this.props.t('campaigns.form.cost')}</Form.Label>
            <Form.Control type="text" placeholder={this.props.t('campaigns.form.cost-placeholder')}
                          onChange={e => this.handleOnChange('cost', e.target.value)}
                          value={campaign.cost}/>
          </Form.Group>
          <Form.Group>
            <Form.Label>{this.props.t('campaigns.form.tags-add')}</Form.Label>
            <ChipsManager
              tags={campaign.tags}
              placeholder="Add a chip..."
              max="10"
              handleChangeTags={(value) => this.handleOnChange('tags', value)}
            />
          </Form.Group>
          <Form.Group>
            <Form.Label>{this.props.t('campaigns.form.logo')}</Form.Label>
            {!this.state.logo && !this.state.logoUrl &&
            <div>
              <label htmlFor="logo" className="label-file">{this.props.t('campaigns.form.image')}</label>
              <Form.Control type="file" name="logo" id="logo" className="input-file" accept="image/png"
                            onChange={(event) => this.handleOnChange('logo', event.target.files[0] ? event.target.files[0] : null)}
                            required
              />
            </div>
            }
            {(this.state.logo || this.state.logoUrl) &&
            <div>
              {this.state.logo && this.state.logo.name ? <span>{this.state.logo.name}</span>
                :
                <img src={this.state.logo || this.state.logoUrl} width={150}/>
              }
              <IconButton style={{color: '#B59A4F'}}
                          onClick={() => this.setState({logo: null, logoUrl: null})}><Delete/></IconButton>
            </div>
            }
          </Form.Group>
          <Form.Group>
            <Form.Label>{this.props.t('campaigns.form.image-header')}</Form.Label>
            {!this.state.imageHeader && !this.state.headerUrl &&
            <div>
              <label htmlFor="imageHeader" className="label-file">{this.props.t('campaigns.form.image')}</label>
              <Form.Control type="file" name="imageHeader" id="imageHeader" className="input-file" accept="image/png"
                            onChange={(event) => this.handleOnChange('imageHeader', event.target.files[0] ? event.target.files[0] : null)}
                            required
              />
            </div>
            }
            {(this.state.imageHeader || this.state.headerUrl) &&
            <div>
              {this.state.imageHeader && this.state.imageHeader.name ? <span>{this.state.imageHeader.name}</span>
                :
                <img src={this.state.imageHeader || this.state.headerUrl} width={150}/>
              }
              <IconButton style={{color: '#B59A4F'}}
                          onClick={() => this.setState({imageHeader: null, headerUrl: null})}><Delete/></IconButton>
            </div>
            }
          </Form.Group>
          <Form.Group>
            <Form.Label>{this.props.t('campaigns.form.image-form')}</Form.Label>
            <div>
              {!this.state.imageForm && !this.state.imageFormUrl &&
              <div>
                <label htmlFor="imageForm" className="label-file">Choisir une image</label>
                <Form.Control type="file" name="imageForm" id="imageForm" className="input-file" accept="image/png"
                              onChange={(event) => this.handleOnChange('imageForm', event.target.files[0] ? event.target.files[0] : null)}
                              required
                />
              </div>
              }
              {(this.state.imageForm || this.state.imageFormUrl) &&
              <div>
                {this.state.imageForm && this.state.imageForm.name ? <span>{this.state.imageForm.name}</span>
                  :
                  <img src={this.state.imageForm || this.state.imageFormUrl} width={150}/>
                }
                <IconButton style={{color: '#B59A4F'}}
                            onClick={() => this.setState({imageForm: null, imageFormUrl: null})}><Delete/></IconButton>
              </div>
              }
            </div>
          </Form.Group>
          {(campaign.vip || campaign.fan || campaign.family) &&
          <div>
            <Form.Group>
              <Form.Label>Traductions</Form.Label>
              <IconButton size="small" variant="gold" type="button" onClick={this.addTranslations}
                          title={this.props.t('campaigns.form.add-translation')}
                          style={{marginLeft: '1rem', backgroundColor: '#424242', color: '#B59A4F'}}
              >
                <Add/>
              </IconButton>
              {this.state.translations && this.state.translations.map((t, index) =>
                <div key={index}
                     style={{
                       position: 'relative',
                       padding: '0.5rem',
                       marginTop: '1rem',
                       border: '1px solid #424242',
                       borderRadius: '1rem',
                     }}>
                  <ReactFlagsSelect defaultCountry={this.state.translations[index].lang} onSelect={(e) => {
                    this.state.translations[index].lang = e;
                  }} searchable={true}/>
                  {campaign.fan && <DefaultEditor value={this.state.translations[index].fan}
                                                  onChange={(e) => {
                                                    this.state.translations[index].fan = e.target.value;
                                                    this.setState({
                                                      translations: this.state.translations,
                                                    });
                                                    this.props.onHandleChange('translations', this.state.translations);
                                                  }}
                  />
                  }
                  {campaign.vip && <DefaultEditor value={this.state.translations[index].vip}
                                                  onChange={(e) => {
                                                    this.state.translations[index].vip = e.target.value;
                                                    this.setState({
                                                      translations: this.state.translations,
                                                    });
                                                    this.props.onHandleChange('translations', this.state.translations);
                                                  }}
                  />
                  }
                  {campaign.family && <DefaultEditor value={this.state.translations[index].family}
                                                     onChange={(e) => {
                                                       this.state.translations[index].family = e.target.value;
                                                       this.setState({
                                                         translations: this.state.translations,
                                                       });
                                                       this.props.onHandleChange('translations', this.state.translations);
                                                     }}
                  />
                  }
                  <IconButton type="button"
                              variant="gold"
                              style={{position: 'absolute', right: '0', top: '0', margin: '0.5rem', color: '#e43f3f'}}
                              onClick={() => {
                                this.state.translations.splice(index);
                                this.setState({
                                  translations: this.state.translations,
                                });
                              }}
                              size="small"
                  ><Delete/></IconButton>
                </div>
              )}
            </Form.Group>
          </div>
          }
          <Button
            type="submit"
            variant="gold"
            disabled={moment(campaign.startDate).isAfter(moment(campaign.endDate)) ||
            moment(campaign.endDate).isBefore(moment(campaign.startDate)) || (campaign.vip && (!campaign.codeVip || campaign.codeVip.length < 5))}
          >
            {this.props.t('home.save')}
          </Button>
        </Form>
      </React.Fragment>
    );
  }

}

export default withTranslation('translate')(withAlert()(CampaignForm));
