function createQuery(method, token, body) {
  const options = {
    method: method,
    mode: "cors",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }
  if (token) {
    options.headers = {...options.headers, 'Authorization': token};
  }
  if (body) {
    options.body = JSON.stringify(body);
  }
  console.log(options);
  return options;
};

export function signin(login, password) {
  return fetch(process.env.REACT_APP_API_URL + '/accounts/authenticate',
    createQuery("POST", null, {login, password})).then(response => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error('Invalid credentials');
    }
  });
}

export function fetchMyFrames(token) {

  return fetch(process.env.REACT_APP_API_URL + '/my-frames',
    createQuery("GET", token)
  ).then(response => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error('Unable to fetch frames');
    }
  });

}

export function fetchFrames(filters, sortField, sortOrder, offset, token) {

  let filterStr = '?offset=' + offset
  if (filters.id !== '') {
    filterStr += '&id=' + filters.id;
  }
  if (filters.name !== '') {
    filterStr += '&label=' + filters.name;
  }
  if (filters.account !== '') {
    filterStr += '&account=' + filters.account;
  }
  if (filters.externalId !== '') {
    filterStr += '&externalId=' + filters.externalId;
  }
  filterStr += '&sortField=' + sortField;
  filterStr += '&sortOrder=' + sortOrder;

  return fetch(process.env.REACT_APP_API_URL + '/frames' + filterStr,
    createQuery("GET", token))
    .then(response => {
      if (response.status === 200) {
        const totalFrames = parseInt(response.headers.get('X-Total-Frames'));
        return response.json().then(data => {
          return Promise.resolve({data, totalFrames});
        });
      } else {
        throw new Error('Unable to fetch frames');
      }
    })
}

export function createFrame(data, token) {
  return fetch(process.env.REACT_APP_API_URL + '/frames', {
    method: "POST",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify(data)
  }).then(response => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error('Unable to rename frame');
    }
  })
}

export function fetchZones(frameId, token) {

  return fetch(process.env.REACT_APP_API_URL + '/frames/' + frameId + '/zones', {
    method: "GET",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }).then(response => {
    if (response.status === 200) {
      console.log("Success in fetchZones");
      return response.json();
    } else {
      console.log("Error in fetchZones");
      throw new Error('Unable to fetch zones');
    }
  });

}

export function fetchTargets(frameId, token) {

  return fetch(process.env.REACT_APP_API_URL + '/frames/' + frameId + '/targets', {
    method: "GET",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }).then(response => {
    if (response.status === 200) {

      console.log("Success in fetchTargets");
      return response.json();
    } else {
      console.log("Error in fetchTargets");
      throw new Error('Unable to fetch targets');
    }
  });

}

export function sendNotification(frameId, frameName, label, zoneName, token) {

  var url = process.env.REACT_APP_API_URL + '/frames/' + frameId + '/notification/' + label;

  if (zoneName != null)
    url += "/" + zoneName;

  var body = {frameName: frameName};

  return fetch(url, {
    method: "POST",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify(body)
  }).then(response => {
    if (response.status === 201) {
      return Promise.resolve();
    } else {
      throw new Error('Unable to send notifications');
    }
  });
}

export function updateZone(frameId, target, index, data, token) {

  return fetch(process.env.REACT_APP_API_URL + '/frames/' + frameId + '/zones/' + target + '/' + index, {
    method: "PUT",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify(data)
  }).then(response => {
    if (response.status === 201) {
      return Promise.resolve();
    } else {
      throw new Error('Unable to fetch zones');
    }
  });

}

export function addZonePlanification(frameId, target, index, data, token) {

  return fetch(process.env.REACT_APP_API_URL + '/frames/' + frameId + '/zones/' + target + '/' + index + '/planification', {
    method: "POST",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify(data)
  }).then(response => {
    if (response.status === 201) {
      return Promise.resolve();
    } else {
      throw new Error('Unable add planification');
    }
  });

}

export function fetchSuperPlanifications(token) {
  return fetch(process.env.REACT_APP_API_URL + '/frames/super-planifications', {
    method: "GET",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }).then(response => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error('Unable to fetch zones');
    }
  })
}

export function fetchSuperPlanification(name, token) {
  return fetch(process.env.REACT_APP_API_URL + '/frames/super-planification?name=' + name, {
    method: "GET",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }).then(response => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error('Unable to fetch zones');
    }
  })
}

export function deleteSuperPlanification(name, token) {
  return fetch(process.env.REACT_APP_API_URL + '/frames/super-planification?name=' + name, {
    method: "DELETE",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }).then(response => {
    if (response.status === 201) {
      return Promise.resolve();
    } else {
      throw new Error('Unable to delete');
    }
  })
}

export function addSuperPlanification(data, token) {

  return fetch(process.env.REACT_APP_API_URL + '/frames/super-planifications', {
    method: "POST",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify(data)
  }).then(response => {
    if (response.status === 201) {
      return Promise.resolve();
    } else {
      throw new Error('Unable add planification');
    }
  });

}

export function deleteZonePlanification(frameId, target, index, targetUrl, token) {

  return fetch(process.env.REACT_APP_API_URL + '/frames/' + frameId + '/zones/' + target + '/' + index + '/planification', {
    method: "DELETE",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({targetUrl})
  }).then(response => {
    if (response.status === 200) {
      return Promise.resolve();
    } else {
      throw new Error('Unable delete planification');
    }
  });

}

export function fetchZonePlanification(frameId, target, index, from, to, token) {

  return fetch(process.env.REACT_APP_API_URL + '/frames/' + frameId + '/zones/' + target + '/' + index + '/planification?from=' + from + '&to=' + to, {
    method: "GET",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }).then(response => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error('Unable to fetch planifications');
    }
  });

}

export function getImage(frameId, token) {

  return fetch(process.env.REACT_APP_API_URL + '/frames/' + frameId + '/image', {
    method: "GET",
    mode: "cors",
    headers: {
      'Authorization': token
    }
  }).then(response => {
    if (response.status === 200) {
      return response.arrayBuffer();
    } else {
      console.log("Error in getImage");
      return null;
    }
  }).then((buffer) => {
    var binary = '';
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => binary += String.fromCharCode(b));
    return window.btoa(binary);
  });
}

export function getZoneImage(frameId, target, zoneIdx, token) {

  return fetch(process.env.REACT_APP_API_URL + '/frames/' + frameId + '/zones/' + target + '/' + zoneIdx + '/image', {
    method: "GET",
    mode: "cors",
    headers: {
      'Authorization': token
    }
  }).then(response => {
    if (response.status === 200) {
      return response.arrayBuffer();
    } else {
      return null;
    }
  }).then((buffer) => {
    var binary = '';
    var bytes = [].slice.call(new Uint8Array(buffer));
    bytes.forEach((b) => binary += String.fromCharCode(b));
    return window.btoa(binary);
  });
}

export function fetchAllUsers(token) {
  return fetch(process.env.REACT_APP_API_URL + '/accounts/users', {
    method: "GET",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }).then(response => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error('Unable to fetch users');
    }
  })
}

export function fetchFrame(frameId, token) {
  return fetch(process.env.REACT_APP_API_URL + '/frames/' + frameId, {
    method: "GET",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }).then(response => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error('Unable to fetch frames');
    }
  })
}

export function updateFrame(frameId, frameData, token) {
  return fetch(process.env.REACT_APP_API_URL + '/frames/' + frameId, {
    method: "PUT",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify(frameData)
  }).then(response => {
    if (response.status === 200) {
      return Promise.resolve();
    } else {
      throw new Error('Unable to rename frame');
    }
  })
}

export function addFrameTarget(frameId, target, token) {
  return fetch(process.env.REACT_APP_API_URL + '/frames/' + frameId + '/targets', {
    method: "PUT",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify(target)
  }).then(response => {
    if (response.status === 201) {
      return Promise.resolve();
    } else {
      throw new Error('Unable to add frame target');
    }
  })
}

export function removeFrameTarget(frameId, target, token) {
  return fetch(process.env.REACT_APP_API_URL + '/frames/' + frameId + '/targets', {
    method: "DELETE",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({
      label: target
    })
  }).then(response => {
    if (response.status === 201) {
      return Promise.resolve();
    } else {
      throw new Error('Unable to delete frame target');
    }
  })
}

export function fetchFrameAccounts(frameId, token) {
  return fetch(process.env.REACT_APP_API_URL + '/frames/' + frameId + '/accounts', {
    method: "GET",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }).then(response => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error('Unable to fetch frame accounts');
    }
  })
}

export function addFrameAccount(frameId, accountId, token) {
  return fetch(process.env.REACT_APP_API_URL + '/frames/' + frameId + '/accounts/' + accountId, {
    method: "PUT",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }).then(response => {
    if (response.status === 201) {
      return Promise.resolve();
    } else {
      throw new Error('Unable to add frame account');
    }
  })
}

export function removeFrameAccount(frameId, accountId, token) {
  return fetch(process.env.REACT_APP_API_URL + '/frames/' + frameId + '/accounts/' + accountId, {
    method: "DELETE",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }).then(response => {
    if (response.status === 201) {
      return Promise.resolve();
    } else {
      throw new Error('Unable to delete frame target');
    }
  })
}

export function addFrameZone(frameId, target, label, color, token) {
  return fetch(process.env.REACT_APP_API_URL + '/frames/' + frameId + '/zones', {
    method: "PUT",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({
      label: label,
      target: target,
      color: color
    })
  }).then(response => {
    if (response.status === 201) {
      return Promise.resolve();
    } else {
      throw new Error('Unable to add frame target');
    }
  })
}

export function removeFrameZone(frameId, target, index, token) {
  return fetch(process.env.REACT_APP_API_URL + '/frames/' + frameId + '/zones', {
    method: "DELETE",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({
      target: target,
      index: index
    })
  }).then(response => {
    if (response.status === 201) {
      return Promise.resolve();
    } else {
      throw new Error('Unable to delete frame target');
    }
  })
}

export function uploadImage(frameId, file, token) {

  var formData = new FormData()
  formData.append('imageFile', file, file.name)

  return fetch(process.env.REACT_APP_API_URL + '/frames/' + frameId + '/image', {
    method: "PUT",
    mode: "cors",
    headers: {
      'Authorization': token
    },
    body: formData
  }).then(response => {
    if (response.status === 201) {
      return Promise.resolve();
    } else {
      throw new Error('Unable to upload image');
    }
  })
}

export function uploadZoneImage(frameId, target, zoneIdx, file, token) {

  var formData = new FormData()
  formData.append('imageFile', file, file.name)

  return fetch(process.env.REACT_APP_API_URL + '/frames/' + frameId + '/zones/' + target + '/' + zoneIdx + '/image', {
    method: "PUT",
    mode: "cors",
    headers: {
      'Authorization': token
    },
    body: formData
  }).then(response => {
    if (response.status === 201) {
      return Promise.resolve();
    } else {
      throw new Error('Unable to upload image');
    }
  })
}

export function uploadVideo(frameId, target, zoneIdx, file, title, description, provider, from = null, to = null, token) {

  var formData = new FormData()
  formData.append('videoFile', file, file.name)
  formData.append('title', title)
  formData.append('description', description)
  if (provider === 'youtube') {
    formData.append('provider', provider)
  }
  if (from !== null && to !== null) {
    formData.append('beginDate', from)
    formData.append('endDate', to)
  }

  return fetch(process.env.REACT_APP_API_URL + '/frames/' + frameId + '/zones/' + target + '/' + zoneIdx + '/video', {
    method: "PUT",
    mode: "cors",
    headers: {
      'Authorization': token
    },
    body: formData
  }).then(response => {
    if (response.status === 201) {
      return Promise.resolve();
    } else {
      throw new Error('Unable to upload video');
    }
  })
}

export function uploadVideoForSuperPlanification(token, file, title, description, provider, from = null, to = null, planifTitle) {

  var formData = new FormData()
  formData.append('videoFile', file, file.name)
  formData.append('title', title)
  formData.append('description', description)
  formData.append('planifTitle', planifTitle)
  if (provider === 'youtube') {
    formData.append('provider', provider)
  }
  if (from !== null && to !== null) {
    formData.append('beginDate', from)
    formData.append('endDate', to)
  }
  return fetch(process.env.REACT_APP_API_URL + '/frames/super-planification/video', {
    method: "PUT",
    mode: "cors",
    headers: {
      'Authorization': token
    },
    body: formData
  }).then(response => {
    if (response.status === 201) {
      return Promise.resolve();
    } else {
      throw new Error('Unable to upload video');
    }
  })
}

export function deleteFrame(frameId, token) {
  return fetch(process.env.REACT_APP_API_URL + '/frames/' + frameId, {
    method: "DELETE",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }).then(response => {
    if (response.status === 200) {
      return Promise.resolve();
    } else {
      throw new Error('Unable to delete frame');
    }
  })
}

export function duplicateFrame(frameId, token) {
  return fetch(process.env.REACT_APP_API_URL + '/frames/' + frameId + '/duplicate', {
    method: "POST",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }).then(response => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error('Unable to duplicate frame');
    }
  })
}

export function updateTargetUrl(frameId, target, targetUrl, timeZone, blinklCampaign, blinklProvider, token) {
  return fetch(process.env.REACT_APP_API_URL + '/frames/' + frameId + '/targets/' + target, {
    method: "PUT",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({
      targetUrl,
      timeZone,
      blinklProvider,
      blinklCampaign
    })
  }).then(response => {
    if (response.status === 201) {
      return Promise.resolve();
    } else {
      throw new Error('Unable to fetch zones');
    }
  });
}

export function fetchAccounts(filters, sortField, sortOrder, offset, token) {

  let filtersStr = '?offset=' + offset
  if (filters.login !== '') {
    filtersStr += '&login=' + filters.login;
  }
  if (filters.email !== '') {
    filtersStr += '&email=' + filters.email;
  }
  if (filters.platform !== '') {
    filtersStr += '&platform=' + filters.platform;
  }
  if (filters.role !== '') {
    filtersStr += '&role=' + filters.role;
  }
  filtersStr += '&sortField=' + sortField;
  filtersStr += '&sortOrder=' + sortOrder;

  return fetch(process.env.REACT_APP_API_URL + '/accounts' + filtersStr, {
    method: "GET",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }).then(response => {
    if (response.status === 200) {
      const totalAccounts = parseInt(response.headers.get('X-Total-Accounts'));
      return response.json().then(data => {
        return Promise.resolve({data, totalAccounts});
      });
    } else {
      throw new Error('Unable to fetch accounts');
    }
  })
}

export function createAccount(data, token) {
  return fetch(process.env.REACT_APP_API_URL + '/accounts', {
    method: "POST",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify(data)
  }).then(response => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error('Unable to create account');
    }
  })
}

export function fetchAllFrames(token) {
  return fetch(process.env.REACT_APP_API_URL + '/frames/all', {
    method: "GET",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }).then(response => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error('Unable to fetch frames');
    }
  })
}

export function fetchAllzones(startDate, endDate, token) {
  return fetch(process.env.REACT_APP_API_URL + '/frames/zones?from=' + startDate + '&to=' + endDate, {
    method: "GET",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }).then(response => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error('Unable to fetch zones');
    }
  })
}

export function fetchAccount(accountId, token) {
  return fetch(process.env.REACT_APP_API_URL + '/accounts/' + accountId, {
    method: "GET",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }).then(response => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error('Unable to fetch accounts');
    }
  })
}

export function fetchAccountFrames(accountId, token) {
  return fetch(process.env.REACT_APP_API_URL + '/accounts/' + accountId + '/frames', {
    method: "GET",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }).then(response => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error('Unable to fetch account frames');
    }
  })
}

export function updateAccount(accountId, data, token) {
  return fetch(process.env.REACT_APP_API_URL + '/accounts/' + accountId, {
    method: "PUT",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify(data)
  }).then(response => {
    if (response.status === 200) {
      return Promise.resolve();
    } else {
      throw new Error('Unable to update account');
    }
  })
}

export function updateMyPasswordFromEmail(password, key) {
  return fetch(process.env.REACT_APP_API_URL + '/account-password', {
    method: "PUT",
    mode: "cors",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({
      key,
      password,
    })
  }).then(response => {
    if (response.status === 200) {
      return Promise.resolve();
    } else {
      throw new Error('Unable to update account password');
    }
  })
}

export function updateMyPassword(password, token) {
  return fetch(process.env.REACT_APP_API_URL + '/account-password', {
    method: "PUT",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({
      password: password
    })
  }).then(response => {
    if (response.status === 200) {
      return Promise.resolve();
    } else {
      throw new Error('Unable to update account');
    }
  })
}


export function updateAccountPassword(accountId, password, token) {
  return fetch(process.env.REACT_APP_API_URL + '/accounts/' + accountId + '/password', {
    method: "PUT",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({
      password: password
    })
  }).then(response => {
    if (response.status === 200) {
      return Promise.resolve();
    } else {
      throw new Error('Unable to update account');
    }
  })
}

export function removeAccount(accountId, token) {
  return fetch(process.env.REACT_APP_API_URL + '/accounts/' + accountId, {
    method: "DELETE",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }).then(response => {
    if (response.status === 200) {
      return Promise.resolve();
    } else {
      throw new Error('Unable to remove account');
    }
  })
}

export function removeAccountFrame(accountId, frameId, token) {
  return fetch(process.env.REACT_APP_API_URL + '/accounts/' + accountId + '/frames/' + frameId, {
    method: "DELETE",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }).then(response => {
    if (response.status === 201) {
      return Promise.resolve();
    } else {
      throw new Error('Unable to delete account frame');
    }
  })
}

export function addAccountFrame(accountId, frameId, token) {
  return fetch(process.env.REACT_APP_API_URL + '/accounts/' + accountId + '/frames/' + frameId, {
    method: "PUT",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }).then(response => {
    if (response.status === 201) {
      return Promise.resolve();
    } else {
      throw new Error('Unable to add account frame');
    }
  })
}

export function updateMasterPassword(password, token) {
  return fetch(process.env.REACT_APP_API_URL + '/master-password', {
    method: "PUT",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({
      password: password
    })
  }).then(response => {
    if (response.status === 200) {
      return Promise.resolve();
    } else {
      throw new Error('Unable to update account');
    }
  })
}

export function createCampaign(campaign, target, token, imageForm, imageHeader, logo) {
  const form = new FormData();
  form.append('campaign', JSON.stringify(campaign));
  form.append('target', JSON.stringify(target));
  form.append('imageHeader', imageHeader, imageHeader.name);
  form.append('imageForm', imageForm, imageForm.name);
  if (logo) form.append('logo', logo, logo.name);
  return fetch(process.env.REACT_APP_API_URL + '/campaign', {
    method: "POST",
    mode: "cors",
    headers: {
      'Authorization': token,
    },
    body: form
  }).then(response => {
    if (response.status === 201) {
      return Promise.resolve();
    } else {
      throw new Error('Unable to create campaign');
    }
  })
}

export function getCampaigns(token) {
  return fetch(process.env.REACT_APP_API_URL + '/campaign', {
    method: "GET",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    },
  }).then(response => {
    if (response.status === 200) {
      return response.json().then(data => {
        return Promise.resolve({data});
      });
    } else {
      throw new Error('Unable to fetch campaigns');
    }
  })
}

export function campaignUniqueKeyExists(token, uniqueKey) {
  return fetch(process.env.REACT_APP_API_URL + '/campaign/exists?uniqueKey=' + uniqueKey, {
    method: "GET",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    },
  }).then(response => {
    if (response.status === 200) {
      return response.json().then(data => {
        return Promise.resolve(data);
      });
    } else {
      throw new Error('Unable to fetch campaigns');
    }
  })
}

export function fetchCampaignsByFrameId(token, frameId, filters) {
  let url = frameId ? `${process.env.REACT_APP_API_URL}/frames/${frameId}/campaigns` : `${process.env.REACT_APP_API_URL}/campaign`;
  return fetch(url, {
    method: "GET",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }).then(response => {
    if (response.status === 200) {
      const totalAccounts = parseInt(response.headers.get('X-Total-Accounts'));
      return response.json().then(data => {
        return Promise.resolve({data, totalAccounts});
      });
    } else {
      throw new Error('Unable to fetch accounts');
    }
  })
}

export function fetchCampaigns(filters, sortField, sortOrder, offset, token, frameId) {

  let filtersStr = '?offset=' + offset
  if (filters.key !== '') {
    filtersStr += '&key=' + filters.key;
  }
  if (filters.name !== '') {
    filtersStr += '&name=' + filters.name;
  }
  filtersStr += '&sortField=' + sortField;
  filtersStr += '&sortOrder=' + sortOrder;

  let url = frameId ? `${process.env.REACT_APP_API_URL}/frames/${frameId}/campaigns` : process.env.REACT_APP_API_URL + '/campaign' + filtersStr;
  return this.fetchCampaignsByFrameId(token, frameId, filtersStr);
}

export function addCampaignPlanification(campaignZone, frameId, token) {
  return fetch(process.env.REACT_APP_API_URL + '/frames/' + frameId + '/campaign-zone', {
    method: "POST",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({
      ...campaignZone,
    })
  }).then(response => {
    if (response.status === 201) {
      return Promise.resolve();
    } else {
      throw new Error('Unable to planif campaign');
    }
  })
}

export function fetchCampaign(campaignId, token) {
  return fetch(process.env.REACT_APP_API_URL + '/campaign/' + campaignId, {
    method: "GET",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }).then(response => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error('Unable to fetch accounts');
    }
  });
}

export function removeCampaign(campaignId, token) {
  return fetch(process.env.REACT_APP_API_URL + '/campaign/' + campaignId, {
    method: "DELETE",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }).then(response => {
    if (response.status.toString().startsWith('2')) {
      return Promise.resolve();
    } else {
      throw new Error('Unable to fetch campaigns');
    }
  });
}

export function updateCampaign(campaign, token, imageForm, imageHeader, logo) {
  console.log('Update campaign', campaign);
  const form = new FormData();
  form.append('campaign', JSON.stringify(campaign));
  if (imageHeader && imageHeader.name) form.append('imageHeader', imageHeader, imageHeader.name);
  if (imageForm && imageForm.name) form.append('imageForm', imageForm, imageForm.name);
  if (logo && logo.name) form.append('logo', logo, logo.name);
  return fetch(process.env.REACT_APP_API_URL + '/campaign/' + campaign.id, {
    method: "PUT",
    mode: "cors",
    headers: {
      'Authorization': token,
    },
    body: form,
  }).then(response => {
    if (response.status.toString().startsWith('2')) {
      return Promise.resolve();
    } else {
      throw new Error('Unable to fetch campaigns');
    }
  });
}

///////////// renvoie toutes les videos, a utiliser dans Frame
export function fetchCampaignVideos(folderId, token) {
  if (folderId) {
    return fetch(process.env.REACT_APP_API_URL + '/medias/' + folderId + '/tree', {
      method: "GET",
      mode: "cors",
      headers: {
        'Authorization': token,
        'Accept': 'application/json',
        'Content-Type': 'application/json;charset=UTF-8'
      }
    }).then(response => {
      if (response.status === 200) {
        return response.json();
      } else {
        throw new Error('Unable to fetch campaign videos');
      }
    });
  } else return Promise.resolve(null);
}

export function getStreamFromMedia(mediaId, token) {
  return fetch(process.env.REACT_APP_API_URL + '/medias/' + mediaId + '/embed', {
    method: "GET",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }).then(response => {
    if (response.status === 200) {
      return response.text();
    } else {
      throw new Error('Unable to get media stream');
    }
  });
}

export function saveHint(token, zoneId, number, date) {
  console.log("date =>", date, zoneId)
  return fetch(process.env.REACT_APP_API_URL + '/frame-zones/' + zoneId + '/hint', {
    method: "PUT",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({hint: number, date: date}),
  }).then(response => {
    if (response.status.toString().startsWith('2')) {
      return Promise.resolve();
    } else {
      throw new Error('Unable to update hint');
    }
  });
}

export function getUserCampaigns(campaignId, token) {
  return fetch(process.env.REACT_APP_API_URL + '/campaigns/' + campaignId + '/usercampaigns', {
    method: "GET",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }).then(response => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error('Unable to get user campaigns');
    }
  });
}

export function getFrameTotalActivation(frameId, token) {
  return fetch(process.env.REACT_APP_API_URL + '/frames/' + frameId + '/statistics', {
    method: "GET",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }).then(response => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error('Unable to get frame total activation');
    }
  });
}

export function getFrameActivationByLabel(frameId, targetLabel, token) {
  return fetch(process.env.REACT_APP_API_URL + '/frames/' + frameId + '/' + targetLabel + '/statistics', {
    method: "GET",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }).then(response => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error('Unable to get frame activation by label');
    }
  });
}

export function getFrameActivationByZoneIndex(frameId, targetLabel, zoneIndex, token) {
  return fetch(process.env.REACT_APP_API_URL + '/frames/' + frameId + '/' + targetLabel + '/' + zoneIndex + '/statistics', {
    method: "GET",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }).then(response => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error('Unable to get frame activation by zone index');
    }
  });
}

////// user Rights /////

export function getZoneAccessByUser(frameId, userId, token) {
  return fetch(process.env.REACT_APP_API_URL + '/frames/' + frameId + '/frame-access-zones?accountId=' + userId, {
    method: "GET",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    }
  }).then(response => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error('Unable to get zone rights for this user');
    }
  });
}

export function toggleOptionAccessZone(frameId, userId, optionKey, optionValue, zoneId, token) {
  return fetch(process.env.REACT_APP_API_URL + '/frames/' + frameId + '/frame-access-zone/' + zoneId, {
    method: "PUT",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({
        "accountId": userId,
        [optionKey]: optionValue,
    })
  }).then(response => {
    if (response.status === 200) {
      return response.text();
    } else {
      throw new Error('Unable to toggle option to user');
    }
  });
}

export function deleteAccessOneZone(frameId, userId, zoneId, token) {
  return fetch(process.env.REACT_APP_API_URL + '/frames/' + frameId + '/frame-access-zone/' + zoneId, {
    method: "DELETE",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({
        "accountId": userId,
    })
  }).then(response => {
    if (response.status === 200) {
      return response.text();
    } else {
      throw new Error('Unable to remove access to user');
    }
  });
}

export function deleteAllAccessZones(frameId, userId, zones, token) {
  console.log("zones =>", zones)
  return fetch(process.env.REACT_APP_API_URL + '/frames/' + frameId + '/frame-access-zones', {
    method: "DELETE",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({
        "accountId": userId,
        "zones": zones,
    })
  }).then(response => {
    if (response.status === 200) {
      return response.text();
    } else {
      throw new Error('Unable to remove all access to user');
    }
  });
}

export function AddAccessOneZone(frameId, userId, zones, token) {
  console.log("zones =>", zones)
  return fetch(process.env.REACT_APP_API_URL + '/frames/' + frameId + '/frame-access-zones', {
    method: "POST",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({
        "accountId": userId,
        "zones": zones,
    })
  }).then(response => {
    if (response.status === 201) {
      return response.text();
    } else {
      throw new Error('Unable to add access to user');
    }
  });
}

export function addAllAccessZones(frameId, userId, zones, token) {
  console.log("zones =>", zones)
  return fetch(process.env.REACT_APP_API_URL + '/frames/' + frameId + '/frame-access-zones', {
    method: "POST",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({
        "accountId": userId,
        "zones": zones,
    })
  }).then(response => {
    if (response.status === 201) {
      return response.text();
    } else {
      throw new Error('Unable to add all access to user');
    }
  });
}

////////////////////////

//route to modify target name once it will be possible (one day ??)
export function modifyTargetLabel(frameId, targetId, label, targetUrl, timeZone, token) {
  return fetch(process.env.REACT_APP_API_URL + '/frames/' + frameId + '/target/' + targetId + '/update', {
    method: "PUT",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({
      target: label,
      targetUrl,
      timeZone
    })
  }).then(response => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error('Unable to modify target label');
    }
  });
}

export function modifyUserTimeZone(frameId, targetId, timeZone, token) {
  return fetch(process.env.REACT_APP_API_URL + '/frames/' + frameId + '/target/' + targetId + '/update-tz', {
    method: "PUT",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({
      timeZone
    })
  }).then(response => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error('Unable to modify target label');
    }
  });
}

export function resetPasswordMail(loginOrEmail) {
  return fetch(process.env.REACT_APP_API_URL + '/reset-password', {
    method: "POST",
    mode: "cors",
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: JSON.stringify({
      login: loginOrEmail,
    })
  }).then(response => {
    if (response.status === 200) {
      return response.json();
    } else {
      throw new Error('Unable to find matching information');
    }
  });
}

export function requestNewCampaign(token, campaignForm) {
  return fetch(process.env.REACT_APP_API_URL + '/campaigns/request', {
    method: "POST",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    },
    body: campaignForm.cellphoneNumber ?
    JSON.stringify({
      mailContent: campaignForm.mailContent,
      cellphoneNumber: campaignForm.cellphoneNumber,
      targetId: campaignForm.targetId,
    })
    :
    JSON.stringify({
      mailContent: campaignForm.mailContent,
      targetId: campaignForm.targetId,
    })
  }).then(response => {
    if (response.status === 200) {
      return response.text();
    } else {
      throw new Error('Unable to request new campaign');
    }
  });
}

export function deleteUserCampaign(campaignId, userCampaignId, token) {
  return fetch(`${process.env.REACT_APP_API_URL}/campaigns/${campaignId}/usercampaigns/${userCampaignId}`, {
    method: "DELETE",
    mode: "cors",
    headers: {
      'Authorization': token,
      'Accept': 'application/json',
      'Content-Type': 'application/json;charset=UTF-8'
    },
  }).then(response => {
    if (response.status < 300) {
      return;
    } else {
      throw new Error('Unable to delete user campaign');
    }
  });
}
