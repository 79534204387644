import React, { Component } from 'react';
import { Container, Row, Col, Form, Button, Navbar, Nav, Toast } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import Footer from '../Footer/Footer'
import { withAlert } from 'react-alert'

import * as API from '../../API';

import adpLogo from '../Signin/ads.png';

import frLogo from '../Admin/Navigation/fr.png';
import enLogo from '../Admin/Navigation/en.png';
import esLogo from '../Admin/Navigation/es.png';

import '../Signin/Signin.css';

class Signin extends Component {

  constructor(props, context) {

    super(props, context);

    this.state = {
      lock: false,
      login: '',
      showMenuLang: false,
      showToast: false,
    }
    this.showMenuLang = this.showMenuLang.bind(this);
    this.closeMenuLang = this.closeMenuLang.bind(this);
  }

  showMenuLang(event) {
    event.preventDefault();
    this.setState({ showMenuLang: true }, () => {
      document.addEventListener('click', this.closeMenuLang);
    });
    }

    closeMenuLang() {
    this.setState({ showMenuLang: false }, () => {
      document.removeEventListener('click', this.closeMenuLang);
      });
    }

    setShow(value) {
        this.setState({
            showToast: value,
        })
    }

  submitSignin(event) {
    event.preventDefault();
    API.resetPasswordMail(this.state.login).then(this.setShow(true));
  }

  handleLanguageFlag() {
    // console.log("language =>", this.props.i18n.language)
    if(this.props.i18n.language.indexOf("-") > 0) {
      this.props.i18n.language = this.props.i18n.languages[1];
    }
    switch(this.props.i18n.language) {
      case 'fr':
        return <img className="flag" alt="fr lang" onClick={() => this.props.i18n.changeLanguage('fr')} src={frLogo} />
      case 'en':
        return <img className="flag" alt="en lang" onClick={() => this.props.i18n.changeLanguage('en')} src={enLogo} />
      case "es":
        return <img className="flag" alt="es lang" onClick={() => this.props.i18n.changeLanguage('es')} src={esLogo} />
      default:
        return <img className="flag" alt="en lang" onClick={() => this.props.i18n.changeLanguage('en')} src={enLogo} />
    }
  }

  render() {
    this.handleLanguageFlag()
    return (
      <div className="Signin" >
          <div style={{ position: 'fixed', bottom: '140px', right: '20px', zIndex: '999'}}>
            <Toast onClose={() => this.setShow(false)} show={this.state.showToast} delay={3000} autohide>
              <Toast.Header>
                  <img
                  src="holder.js/20x20?text=%20"
                  className="rounded mr-2"
                  alt=""
                  />
                  <strong className="mr-auto">{this.props.t("signin.thanks")}</strong>
                  <small>{this.props.t("signin.toastTime")}</small>
              </Toast.Header>
              <Toast.Body style={{ color: 'black'}}>
                  {this.props.t('signin.emailSent')}
              </Toast.Body>
            </Toast>
            {/* <Col xs={6}>
                <Button onClick={() => this.setShow(true)}>Show Toast</Button>
            </Col> */}
          </div>
        <Navbar variant="custom" fixed="top">
          <Navbar.Brand style={{ width: '85%'}}>
            </Navbar.Brand>
          <Nav>
		  		  <Button onClick={this.showMenuLang} variant="gold" style={{width: '45px'}}> {this.handleLanguageFlag()} </Button>
          </Nav>
          {this.state.showMenuLang &&
          <Navbar variant="custom" fixed="top" style={{top: '66px', height: '40px', }}>
            <Container>
              <Row style={{flex: 1, flexDirection: 'row-reverse', marginBottom:'8px'}}>
                <Col className="mb-sm-4 text-right">
                  <div  className="menu btn btn-gold">
                    <img className="flag" alt="fr lang" onClick={() => this.props.i18n.changeLanguage('fr')} src={frLogo} />
                    <img className="flag" alt="en lang" onClick={() => this.props.i18n.changeLanguage('en')} src={enLogo} />
                    <img className="flag" alt="es lang" onClick={() => this.props.i18n.changeLanguage('es')} src={esLogo} />
                  </div>
                </Col>
                </Row>
            </Container>
            </Navbar>
          }
        </Navbar>
        {/* <Navbar variant="custom" fixed="top" style={{top: '66px', height: '40px', }}>

				</Navbar> */}
        <div className="Signin-Form-Container">
          <Container fluid>
            <Row>
              <Col md={12}>
                <img src={adpLogo} alt="Logo" className="Signin-Form-Logo" />
              </Col>
              <Col md={12}>
                <Form className="Signin-Form" onSubmit={this.submitSignin.bind(this)}>
                  <Form.Group controlId="form-signin-login">
                    <Form.Label>{this.props.t('signin.login')}</Form.Label>
                    <Form.Control required
                      value={this.state.login}
                      onChange={(event) => this.setState({ login: event.target.value })}
                      type="text"
                      minLength="4"
                      placeholder={this.props.t('signin.login')} />
                  </Form.Group>

                  <Button disabled={this.state.lock || this.state.login.length === 0} variant="gold" type="submit">
                    {this.props.t('signin.send')}
                  </Button>
                </Form>
              </Col>
            </Row>
          </Container>
        </div>
        <Footer />
      </div>
    );
  }

}

export default withTranslation('translate')(withAlert()(Signin));
