import React from 'react';
import {Button, Col, Container, Form, Nav, Navbar, Row, Table, Dropdown, Toast, Tabs, Tab, Spinner} from 'react-bootstrap';
import {withTranslation} from 'react-i18next';
import {withAlert} from 'react-alert';
import 'react-phone-number-input/style.css'
import PhoneInput from 'react-phone-number-input'
import * as API from '../../API';
class CampaignContact extends React.Component {
    constructor(props) {
        super(props);
        this.token = localStorage.getItem('myartadmin.token');
        this.state = {
            campaignForm: {
                email: true,
                cellphone: false,
                cellphoneNumber: '',
                mailContent: '',
            },
            showToast: false,
            toastMessage: '',
        }
    }

    setShow(value) {
      this.setState({
        showToast: value,
      })
    }

    submitCampaignDemand(data) {
      let tmpCampaignForm = {
        mailContent: data.mailContent,
      }
      if (data.cellphone) {
        tmpCampaignForm.cellphoneNumber = data.cellphoneNumber;
      }
      tmpCampaignForm.targetId = this.props.selectedFrame.id;
      API.requestNewCampaign(this.token, tmpCampaignForm)
      .then(res => {
        if (res === 'OK') {
          this.setState({
            showToast: true,
            toastMessage: this.props.t('home.emailSentSuccess'),
            campaignForm: {
              email: true,
              cellphone: false,
              cellphoneNumber: '',
              mailContent: '',
            }
          })
        } else {
          this.setState({
            showToast: true,
            toastMessage: this.props.t('home.emailSentError'),
          })
        }
      })
    }

    render() {
        return(
          <Form>
            <div style={{ position: 'fixed', bottom: '140px', right: '20px', zIndex: '999'}}>
            <Toast onClose={() => this.setShow(false)} show={this.state.showToast} delay={3000} autohide>
              <Toast.Header>
                  <img
                  src="holder.js/20x20?text=%20"
                  className="rounded mr-2"
                  alt=""
                  />
                  <strong className="mr-auto">{this.props.t("userManagement.thanks")}</strong>
                  <small>{this.props.t("userManagement.toastTime")}</small>
              </Toast.Header>
              <Toast.Body style={{ color: 'black'}}>
                  {this.state.toastMessage}
              </Toast.Body>
            </Toast>
            {/* <Col xs={6}>
                <Button onClick={() => this.setShow(true)}>Show Toast</Button>
            </Col> */}
          </div>
            <fieldset>
              <Form.Group as={Row}>
                <Form.Label as="legend" column sm={4}>
                  {this.props.t('home.contactWay')}
                </Form.Label>
                <Col sm={10}>
                  <Form.Check 
                    type="radio"
                    label={this.props.t('home.email')}
                    name="HorizontalRadiosEmail"
                    checked={this.state.campaignForm.email}
                    onChange={(e) => {
                      const tmp = {...this.state.campaignForm};
                      tmp.email = e.target.checked;
                      tmp.cellphone = false;
                      this.setState({campaignForm: tmp})
                    }}
                    id="formHorizontalRadiosEmail"
                  />
                    <Form.Check
                      type="radio"
                      label={this.props.t('home.cellphone')}
                      checked={this.state.campaignForm.cellphone}
                      onChange={(e) => {
                        const tmp = {...this.state.campaignForm};
                        tmp.cellphone = e.target.checked;
                        tmp.email = false;
                        this.setState({campaignForm: tmp})
                      }}
                      name="HorizontalRadiosCellphone"
                      id="formHorizontalRadiosCellphone"
                    />
                    {this.state.campaignForm.cellphone &&
                      <PhoneInput
                      style={{
                        width: '250px',
                        margin: '1rem 0 0 0',
                      }}
                      placeholder={this.props.t('home.cellphoneNumber')}
                      value={this.state.campaignForm.cellphoneNumber}
                      onChange={(value) => this.setState({
                        campaignForm: {
                          ...this.state.campaignForm,
                          cellphoneNumber: value,
                        }
                      })}
                      />
                    }
                </Col>
              </Form.Group>
            </fieldset>
            <Form.Group>
              <Form.Label>{this.props.t('home.specifyDemand')}</Form.Label>
              <Form.Control
                as="textarea"
                rows={4}
                value={this.state.campaignForm.mailContent}
                onChange={(e) => {
                  this.setState({campaignForm: {
                    ...this.state.campaignForm,
                    mailContent: e.target.value,
                  }})
                }}
              />
            </Form.Group>
            <Form.Row>
              <Button
                onClick={() => this.submitCampaignDemand(this.state.campaignForm)}
                className="mr-2"
                variant="gold"
                disabled={(!this.state.campaignForm.cellphone && this.state.campaignForm.mailContent.length === 0) ||
                (this.state.campaignForm.cellphone && (this.state.campaignForm.cellphoneNumber === "" ||
                  this.state.campaignForm.mailContent.length === 0))}
              >
                {this.props.t('home.send')}
              </Button>
            </Form.Row>
          </Form>
        )
    }
}

export default withTranslation('translate')(withAlert()(CampaignContact));