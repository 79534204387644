import React, {Component} from 'react';
import {Button, ButtonGroup, Col, Container, Form, Nav, Navbar, Row, Table} from 'react-bootstrap';
import {withTranslation} from 'react-i18next';
import {withAlert} from 'react-alert';
import {Link} from 'react-router-dom';
import Footer from '../Footer/Footer';
import adpLogo from '../Home/adp.png';
import frLogo from '../Admin/Navigation/fr.png';
import enLogo from '../Admin/Navigation/en.png';
import esLogo from '../Admin/Navigation/es.png';

import * as moment from 'moment';

import Datetime from 'react-datetime';

import * as API from '../../API';
import ChangePassword from '../Home/ChangePassword';

class Synthese extends Component {

  constructor(props, context) {

    super(props, context);

    this.token = localStorage.getItem('myartadmin.token');
    this.login = localStorage.getItem('myartadmin.login');

    this.state = {
      zones: [],
      zonesCopy: [],
      showMenuLang: false,
      showMenuContact: false,
      sortField: 'redirectCount',
      sortOrder: 'DESC',
      groupBy: 'frame',
      startDate: moment().startOf('year').toDate(),
      endDate: moment().endOf('month').toDate()
    }

    this.showMenuLang = this.showMenuLang.bind(this);
    this.closeMenuLang = this.closeMenuLang.bind(this);

    this.showMenuContact = this.showMenuContact.bind(this);
    this.closeMenuContact = this.closeMenuContact.bind(this);
  }

  componentDidMount() {
    this.refreshData();
  }

  refreshData() {
    API.fetchAllzones(moment(this.state.startDate).format('YYYY-MM-DD'), moment(this.state.endDate).format('YYYY-MM-DD'), this.token).then(zones => {
      this.setState({
        zones, zonesCopy: zones.map(z => {
          return {...z}
        })
      })
    }).catch(() => {
      this.props.alert.error(this.props.t('redirections.loadError'));
    })
  }

  setFilter(field, value) {
    const filters = {...this.state.filters};

    filters[field] = value;
    this.setState({filters}, () => {
      let filteredZones = this.state.zonesCopy.map(z => {
        return {...z}
      });
      Object.keys(this.state.filters).forEach((field) => {
        if (this.state.filters[field] !== '') {
          filteredZones = filteredZones.filter(z => z[field].toLowerCase().indexOf(this.state.filters[field].toLowerCase()) !== -1);
        }
      });
      this.setState({zones: filteredZones});
    });
  }

  showMenuLang(event) {
    event.preventDefault();
    this.setState({showMenuLang: true}, () => {
      document.addEventListener('click', this.closeMenuLang);
    });
  }

  closeMenuLang() {
    this.setState({showMenuLang: false}, () => {
      document.removeEventListener('click', this.closeMenuLang);
    });
  }

  showMenuContact(event) {
    event.preventDefault();
    this.setState({showMenuContact: true}, () => {
      document.addEventListener('click', this.closeMenuContact);
    });
  }

  closeMenuContact() {
    this.setState({showMenuContact: false}, () => {
      document.removeEventListener('click', this.closeMenuContact);
    });
  }

  signout() {
    localStorage.removeItem('myartadmin.token');
    localStorage.removeItem('myartadmin.login');
    this.props.history.push('/');
  }

  changeSort(field) {
    if (this.state.sortField === field) {
      this.setState({sortOrder: this.state.sortOrder === 'ASC' ? 'DESC' : 'ASC'});
    } else {
      this.setState({sortField: field, sortOrder: 'ASC'});
    }
  }

  handleLanguageFlag() {
    // console.log("language =>", this.props.i18n.language)
    if (this.props.i18n.language.indexOf("-") > 0) {
      this.props.i18n.language = this.props.i18n.languages[1];
    }
    switch (this.props.i18n.language) {
      case 'fr':
        return <img className="flag" alt="fr lang" onClick={() => this.props.i18n.changeLanguage('fr')} src={frLogo}/>
      case 'en':
        return <img className="flag" alt="en lang" onClick={() => this.props.i18n.changeLanguage('en')} src={enLogo}/>
      case "es":
        return <img className="flag" alt="es lang" onClick={() => this.props.i18n.changeLanguage('es')} src={esLogo}/>
      default:
        return <img className="flag" alt="en lang" onClick={() => this.props.i18n.changeLanguage('en')} src={enLogo}/>
    }
  }

  render() {
    const tmpTotal = this.state.zonesCopy.reduce((acc, e) => {
      return acc + e.redirectCount
    }, 0);
    const formatedTotal = new Intl.NumberFormat('fr-FR').format(tmpTotal)
    return (
      <div>
        <Container className="Common-Container">

          <Navbar variant="custom" fixed="top">
            <Container>
              <Navbar.Brand className="mr-auto">
                <img src={adpLogo} alt="adp logo" style={{height: '40px'}}/>
              </Navbar.Brand>
              <Nav className="mr-2">
                <Button onClick={this.showMenuContact} variant="success"><i className="fas fa-phone"></i> </Button>
              </Nav>

              <Nav className="mr-2">
                <Button onClick={this.showMenu} variant="gold"
                        style={{width: '45px'}}> {this.handleLanguageFlag()} </Button>
              </Nav>

              <Nav>
                <Button variant="gold" onClick={this.signout.bind(this)}><i className="fas fa-sign-out-alt"></i>
                </Button>
              </Nav>
            </Container>
          </Navbar>

          {
            this.state.showMenuLang
              ? (
                <Navbar variant="custom" fixed="top" style={{top: '66px', height: '40px'}}>
                  <Container>
                    <Row style={{flex: 1, flexDirection: 'row-reverse'}}>
                      <Col className="mb-sm-4 text-right">
                        <div className="menu btn btn-gold">
                          <img className="flag" alt="fr lang" onClick={() => this.props.i18n.changeLanguage('fr')}
                               src={frLogo}/>
                          <img className="flag" alt="en lang" onClick={() => this.props.i18n.changeLanguage('en')}
                               src={enLogo}/>
                          <img className="flag" alt="es lang" onClick={() => this.props.i18n.changeLanguage('es')}
                               src={esLogo}/>
                        </div>
                      </Col>
                    </Row>
                  </Container>
                </Navbar>
              )
              : (
                null
              )

          }

          {
            this.state.showMenuContact
              ? (
                <Navbar variant="custom" fixed="top" style={{top: '66px', height: '40px', marginBottom: '8px'}}>
                  <Container>
                    <Row style={{flex: 1, flexDirection: 'row-reverse', padding: '10px'}}>
                      <Nav className="mr-2">
                        <div className="menu btn btn-gold">
                          <a className="fas fa-envelope-square" style={{color: 'black'}}
                             href="mailto:Hello@ArtDesignPainting.paris"></a>
                        </div>
                        <div className="menu btn btn-gold">
                          <a className="fas fa-mobile-alt" style={{color: 'black'}} href="tel:+33665512312"></a>
                        </div>
                      </Nav>
                    </Row>
                  </Container>
                </Navbar>
              )
              : (
                null
              )
          }


          <Container className="Common-Container Home">
            <Row>
              <Col md={12} className="mb-sm-4 text-right">

                <strong style={{color: '#B59A4F'}}>{this.props.t('home.welcome')}</strong> <span
                className="font-italic">{this.login}</span>
                <Button onClick={() => this.props.history.push('/home')} className="ml-2" variant="gold" size="sm"><i
                  className="fas fa-table"></i> {/*this.props.t('frames.synthetisis')*/}</Button>
                <Button onClick={() => this.setState({showResetModal: true})} className="ml-2" variant="gold" size="sm"><i
                  className="fas fa-lock"></i></Button>

              </Col>
              <ChangePassword show={this.state.showResetModal} onHide={() => this.setState({showResetModal: false})}/>
            </Row>
            <Row>
              <Col md={8}>
                <h1>{this.props.t('redirections.title')}</h1>
                <h5 style={{color: '#B59A4F', fontWeight: '800'}}>Total : {formatedTotal}</h5>
              </Col>
            </Row>
            <Row>
              <Col md={1} style={{padding: '0 0 0 10px'}}>
                <p style={{width: '100%'}} className="mt-sm-1">
                  <strong>{this.props.t('accounts.filter-title')}</strong>
                </p>
              </Col>
              <Col md={6}>
                <Form>
                  <Form.Row>
                    <Form.Group as={Col} controlId="zones-frameLabel-filter">
                      <Form.Control onChange={(event) => this.setFilter('frameLabel', event.target.value)} type="text"
                                    placeholder={this.props.t('redirections.frameLabelFilter')}/>
                    </Form.Group>
                    <Form.Group as={Col} controlId="zones-target-filter">
                      <Form.Control onChange={(event) => this.setFilter('target', event.target.value)} type="text"
                                    placeholder={this.props.t('redirections.targetFilter')}/>
                    </Form.Group>
                    <Form.Group as={Col} controlId="zones-label-filter">
                      <Form.Control onChange={(event) => this.setFilter('label', event.target.value)} type="text"
                                    placeholder={this.props.t('redirections.labelFilter')}/>
                    </Form.Group>
                  </Form.Row>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col md={1} style={{padding: '0 0 0 10px'}}>
                <p style={{width: '100%'}} className="mt-sm-1">&nbsp;</p>
              </Col>
              <Col md={6}>
                <Form>
                  <Form.Row>
                    <Form.Group style={{color: '#000000'}} as={Col} controlId="zones-from-filter">
                      <Datetime
                        timeFormat={false}
                        dateFormat="DD/MM/YYYY"
                        closeOnSelect={true}
                        value={this.state.startDate}
                        onChange={(d) => {
                          if (typeof d !== 'string') {
                            this.setState({startDate: d.toDate()}, this.refreshData)
                          }
                        }}/>
                    </Form.Group>
                    <Form.Group style={{color: '#000000'}} as={Col} controlId="zones-from-filter">
                      <Datetime
                        timeFormat={false}
                        dateFormat="DD/MM/YYYY"
                        closeOnSelect={true}
                        value={this.state.endDate}
                        onChange={(d) => {
                          if (typeof d !== 'string') {
                            this.setState({endDate: d.toDate()}, this.refreshData)
                          }
                        }}/>
                    </Form.Group>
                  </Form.Row>
                </Form>
              </Col>
            </Row>
            <Row>
              <Col md={1} style={{padding: '0 0 0 10px'}}>
                <p style={{width: '100%'}} className="mt-sm-1">
                  <strong>{this.props.t('redirections.grouptitle')}</strong>
                </p>
              </Col>
              <Col md={6}>
                <div>
                  <Form.Check inline label={this.props.t('redirections.groupnone')} type="radio" onChange={(event) => {
                    if (event.target.value === 'on') {
                      this.setState({groupBy: null})
                    }
                  }} checked={this.state.groupBy === null}/>
                  <Form.Check inline label={this.props.t('redirections.groupframe')} type="radio" onChange={(event) => {
                    if (event.target.value === 'on') {
                      this.setState({groupBy: 'frame'})
                    }
                  }} checked={this.state.groupBy === 'frame'}/>
                  <Form.Check inline label={this.props.t('redirections.grouptarget')} type="radio"
                              onChange={(event) => {
                                if (event.target.value === 'on') {
                                  this.setState({groupBy: 'target'})
                                }
                              }} checked={this.state.groupBy === 'target'}/>
                </div>
              </Col>
            </Row>
            <Row className="justify-content-md-center">
              <Col md={12}>
                {this.state.groupBy === null &&
                <Table variant="custom" size="sm" responsive="md" borderless>
                  <thead>
                  <tr>
                    <th
                      className={'clickable' + (this.state.sortField === 'frameLabel' ? ' sorted-' + this.state.sortOrder : '')}
                      onClick={() => this.changeSort('frameLabel')}>{this.props.t('redirections.frameLabel')}</th>
                    <th
                      className={'clickable' + (this.state.sortField === 'target' ? ' sorted-' + this.state.sortOrder : '')}
                      onClick={() => this.changeSort('target')}>{this.props.t('redirections.target')}</th>
                    <th
                      className={'clickable' + (this.state.sortField === 'index' ? ' sorted-' + this.state.sortOrder : '')}
                      onClick={() => this.changeSort('index')}>{this.props.t('redirections.index')}</th>
                    <th
                      className={'clickable' + (this.state.sortField === 'label' ? ' sorted-' + this.state.sortOrder : '')}
                      onClick={() => this.changeSort('label')}>{this.props.t('redirections.label')}</th>
                    <th
                      className={'clickable' + (this.state.sortField === 'redirectCount' ? ' sorted-' + this.state.sortOrder : '')}
                      onClick={() => this.changeSort('redirectCount')}>{this.props.t('redirections.redirectCount')}</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    this.state.zones.sort((ez1, ez2) => {
                      if (this.state.sortOrder === 'DESC') {
                        if (isNaN(parseInt(ez2[this.state.sortField]))) {
                          return ('' + ez2[this.state.sortField]).localeCompare(ez1[this.state.sortField]);
                        }
                        return ez2[this.state.sortField] - ez1[this.state.sortField];
                      } else {
                        if (isNaN(parseInt(ez2[this.state.sortField]))) {
                          return ('' + ez1[this.state.sortField]).localeCompare(ez2[this.state.sortField]);
                        }
                        return ez1[this.state.sortField] - ez2[this.state.sortField];
                      }
                    }).map((z, i) => {
                      return <tr key={i} className={i % 2 === 0 ? 'even' : 'odd'}>
                        <td><Link style={{textDecoration: 'underline', color: '#ffffff'}}
                                  to={{pathname: '/home', data: z.frameId}}>{z.frameLabel}</Link></td>
                        <td>{z.target}</td>
                        <td>{z.index}</td>
                        <td>{z.label}</td>
                        <td>{z.redirectCount}</td>
                      </tr>
                    })
                  }
                  </tbody>
                </Table>
                }
                {this.state.groupBy === 'frame' &&
                <Table variant="custom" size="sm" responsive="md" borderless>
                  <thead>
                  <tr>
                    <th
                      className={'clickable' + (this.state.sortField === 'frameLabel' ? ' sorted-' + this.state.sortOrder : '')}
                      onClick={() => this.changeSort('frameLabel')}>{this.props.t('redirections.frameLabel')}</th>
                    <th
                      className={'clickable' + (this.state.sortField === 'redirectCount' ? ' sorted-' + this.state.sortOrder : '')}
                      onClick={() => this.changeSort('redirectCount')}>{this.props.t('redirections.redirectCount')}</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    this.state.zones.reduce((acc, e) => {
                      if (acc.find(ee => ee.frameLabel === e.frameLabel)) {
                        return acc.map(ee => {
                          if (ee.frameLabel === e.frameLabel) {
                            return {
                              ...ee,
                              redirectCount: ee.redirectCount + e.redirectCount
                            }
                          }
                          return {...ee};
                        })
                      } else {
                        return acc.concat([{
                          frameId: e.frameId,
                          frameLabel: e.frameLabel,
                          redirectCount: e.redirectCount
                        }]);
                      }
                    }, [])
                      .sort((ez1, ez2) => {
                        if (this.state.sortOrder === 'DESC') {
                          if (isNaN(parseInt(ez2[this.state.sortField]))) {
                            return ('' + ez2[this.state.sortField]).localeCompare(ez1[this.state.sortField]);
                          }
                          return ez2[this.state.sortField] - ez1[this.state.sortField];
                        } else {
                          if (isNaN(parseInt(ez2[this.state.sortField]))) {
                            return ('' + ez1[this.state.sortField]).localeCompare(ez2[this.state.sortField]);
                          }
                          return ez1[this.state.sortField] - ez2[this.state.sortField];
                        }
                      }).map((z, i) => {
                      return <tr key={i} className={i % 2 === 0 ? 'even' : 'odd'}>
                        <td><Link style={{textDecoration: 'underline', color: '#ffffff'}}
                                  to={{pathname: '/home', data: z.frameId}}>{z.frameLabel}</Link></td>
                        <td>{z.redirectCount}</td>
                      </tr>
                    })
                  }
                  </tbody>
                </Table>

                }
                {this.state.groupBy === 'target' &&
                <Table variant="custom" size="sm" responsive="md" borderless>
                  <thead>
                  <tr>
                    <th
                      className={'clickable' + (this.state.sortField === 'frameLabel' ? ' sorted-' + this.state.sortOrder : '')}
                      onClick={() => this.changeSort('frameLabel')}>{this.props.t('redirections.frameLabel')}</th>
                    <th
                      className={'clickable' + (this.state.sortField === 'frameExternalId' ? ' sorted-' + this.state.sortOrder : '')}
                      onClick={() => this.changeSort('frameExternalId')}>{this.props.t('redirections.frameExternalId')}</th>
                    <th
                      className={'clickable' + (this.state.sortField === 'target' ? ' sorted-' + this.state.sortOrder : '')}
                      onClick={() => this.changeSort('target')}>{this.props.t('redirections.target')}</th>
                    <th
                      className={'clickable' + (this.state.sortField === 'redirectCount' ? ' sorted-' + this.state.sortOrder : '')}
                      onClick={() => this.changeSort('redirectCount')}>{this.props.t('redirections.redirectCount')}</th>
                  </tr>
                  </thead>
                  <tbody>
                  {
                    this.state.zones.reduce((acc, e) => {
                      if (acc.find(ee => ee.frameLabel === e.frameLabel && ee.target === e.target)) {
                        return acc.map(ee => {
                          if (ee.frameLabel === e.frameLabel && ee.target === e.target) {
                            return {
                              ...ee,
                              redirectCount: ee.redirectCount + e.redirectCount
                            }
                          }
                          return {...ee};
                        })
                      } else {
                        return acc.concat([{
                          frameId: e.frameId,
                          frameLabel: e.frameLabel,
                          frameExternalId: e.frameExternalId,
                          target: e.target,
                          redirectCount: e.redirectCount
                        }]);
                      }
                    }, []).sort((ez1, ez2) => {
                      if (this.state.sortOrder === 'DESC') {
                        if (isNaN(parseInt(ez2[this.state.sortField]))) {
                          return ('' + ez2[this.state.sortField]).localeCompare(ez1[this.state.sortField]);
                        }
                        return ez2[this.state.sortField] - ez1[this.state.sortField];
                      } else {
                        if (isNaN(parseInt(ez2[this.state.sortField]))) {
                          return ('' + ez1[this.state.sortField]).localeCompare(ez2[this.state.sortField]);
                        }
                        return ez1[this.state.sortField] - ez2[this.state.sortField];
                      }
                    }).map((z, i) => {
                      return <tr key={i} className={i % 2 === 0 ? 'even' : 'odd'}>
                        <td><Link style={{textDecoration: 'underline', color: '#ffffff'}}
                                  to={{pathname: '/home', data: z.frameId}}>{z.frameLabel}</Link></td>
                        <td>{z.frameExternalId}</td>
                        <td>{z.target}</td>
                        <td>{z.redirectCount}</td>
                      </tr>
                    })
                  }
                  </tbody>
                </Table>

                }
              </Col>
              {this.state.totalAccounts > 10 &&
              <Col md={4} className="text-right">
                <ButtonGroup>
                  <Button disabled={this.state.offset === 0}
                          onClick={() => this.setState({offset: 0}, () => this.refreshAccounts())}
                          variant="gold">&lt;&lt;</Button>
                  <Button disabled={this.state.offset === 0}
                          onClick={() => this.setState({offset: Math.max(this.state.offset - 10, 0)}, () => this.refreshAccounts())}
                          variant="gold">&lt;</Button>
                  <Button variant="light">{this.state.offset + 1} - {this.state.offset + 10}</Button>
                  <Button disabled={this.state.offset + 10 >= this.state.totalAccounts}
                          onClick={() => this.setState({offset: this.state.offset + 10}, () => this.refreshAccounts())}
                          variant="gold">&gt;</Button>
                  <Button disabled={this.state.offset + 10 >= this.state.totalAccounts}
                          onClick={() => this.setState({offset: (Math.floor(this.state.totalAccounts / 10) * 10)}, () => this.refreshAccounts())}
                          variant="gold">&gt;&gt;</Button>
                </ButtonGroup>
              </Col>
              }
            </Row>
            <Row>
              <Col md={12}>
                <Footer/>
              </Col>
            </Row>
          </Container>
        </Container>
      </div>
    );

  }

}

export default withTranslation('translate')(withAlert()(Synthese));
