import React from 'react';
import {Button, Col, Row, Tab, Tabs} from 'react-bootstrap';
import {withTranslation} from 'react-i18next';
// import CampaignList from './CampaignList';
import CampaignVideosManager from './CampaignVideosManager';
import Campaign from './Campaign';
import * as API from '../../../API';
import './campaignManager.css';
import PageToggle from '../PageToggle/PageToggle';
import CampaignParticipants from './CampaignParticipants';

class CampaignManager extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      campaign: {},
      windowWidth: 0,
    }
    this.token = localStorage.getItem('myartadmin.token');
    this.campaignId = this.props.match.params.campaignId;
    this.updateWindowDimensions = this.updateWindowDimensions.bind(this);
  }

  componentDidMount() {
    this.updateWindowDimensions();
    window.addEventListener('resize', this.updateWindowDimensions);
    if (this.token === undefined || this.token === null) {
      this.props.alert.error(this.props.t('campaigns.loadError'));
    } else {
      this.refreshCampaign();
    }
  }

  shouldComponentUpdate(nextProps, nextState) {
    if (this.state.campaign !== nextState.campaign) {
      API.getImage(nextState.campaign.frameId, this.token).then(
        res => this.setState({frameImage: res})
      )
    }
    return true;
  }

  refreshCampaign() {
    API.fetchCampaign(this.campaignId, this.token).then(campaign => {
      this.setState({
        campaign: campaign,
      })
    }).catch(() => {
      // needed to catch error...
      //   this.props.alert.error(this.props.t('accounts.loadError'));
    })
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateWindowDimensions);
  }

  updateWindowDimensions() {
    this.setState({windowWidth: window.innerWidth});
  }

  render() {
    return (
      <React.Fragment>
        <Row className="campaign-general-menu">
          <Col md={12}>
            <PageToggle/>
          </Col>
        </Row>
        <Row className="campaign-general-menu">
          <h3 style={{marginLeft: '1rem'}} className="campaign-title-padding">{this.state.campaign.name}</h3>
        </Row>
        <Tabs variant="pills" defaultActiveKey="campaigns" id="uncontrolled-tab-example"
              className="campaign-manager-tabs">
          <Tab eventKey="campaigns" title={this.props.t("frame.informations").toUpperCase()}
               className="campaign-manager-tabs">
            <h2>
              {this.state.campaign.targetLabel}
              <a href={this.state.campaign.landingUrl} target="_blank" rel="noopener noreferrer"
                 style={{marginLeft: '1rem'}}>
                <Button variant="gold" size="sm" className="small-button"><i className="fas fa-edit"
                                                                             style={{transform: 'translate(1px, 3px)'}}></i></Button>
              </a>
            </h2>
            {
              this.state.frameImage !== null &&
              <img className="mt-sm-4" src={'data:image/*;base64,' + this.state.frameImage}
                   alt="visuel" style={{
                width: this.state.windowWidth >= 768 ? '40%' : '90%',
                marginBottom: '1rem',
                position: 'relative',
                left: '50%',
                transform: 'translateX(-50%)'
              }}/>
            }
            <div>
            </div>
            <Campaign {...this.props} />
          </Tab>
          <Tab eventKey="videos" title={this.props.t("frame.videos").toUpperCase()} className="campaign-manager-tabs">
            <CampaignVideosManager folderId={this.state.campaign.folderId}/>
          </Tab>
          <Tab eventKey="participants" title={this.props.t("campaigns.participants").toUpperCase()}
               className="campaign-manager-tabs">
            <CampaignParticipants campaign={this.state.campaign} campaignId={this.campaignId}/>
          </Tab>
        </Tabs>
      </React.Fragment>
    )
  }
}

export default withTranslation('translate')(CampaignManager);

