import React, { Component } from 'react';
import { withTranslation } from 'react-i18next';

import './Footer.css'

class Footer extends Component {

	render() {

    return (
      <div className="Footer">
        <ul className="list-unstyled">
          <li>© ArtDesignStory PARIS</li>
          <li>78 avenue des Champs-Elysées - 75008 Paris</li>
          <li><a href="mailto:hello@artdesignstory.fr">hello@artdesignstory.fr</a></li>
        </ul>
      </div>
    );
  }
}

export default withTranslation('translate')(Footer);