import React, { Component } from 'react';
import { Container, Row, Col, Button, Form } from 'react-bootstrap';
import { withTranslation } from 'react-i18next';
import { withAlert } from 'react-alert';
import PageToggle from '../PageToggle/PageToggle';
import { Redirect } from "react-router-dom";

import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';

import * as API from '../../../API';

class Account extends Component {

  constructor(props, context) {
    super(props, context);

    this.token = localStorage.getItem('myartadmin.token');
    this.accountId = this.props.match.params.accountId;

    this.state = {
      loading: true,
      showModal: false,
      frameList: [],
      login: '',
      email: '',
      role: '',
      password: '',
      newTarget: '',
      newFrame: '',
      newZoneLabel: '',
      newZoneTarget: ''
    }
  }

  componentDidMount() {
	
    if (this.token === undefined || this.token === null) {
      this.props.alert.error(this.props.t('account.loadError'));
    } else {
      API.fetchAllFrames(this.token).then((frames) => this.setState({ frameList: frames })).catch(()=> {
		this.props.history.push('/home')
	  });
      this.refreshAccount();
    }
  }


  removeAccount() {
	const promises = [];
    promises.push(API.removeAccount(this.accountId, this.token));

    Promise.all(promises).then(() => {
      this.refreshAccount();
	  this.props.alert.success(this.props.t('account.updateSuccess'));
	  this.setState({ redirect: "/admin/accounts" });
    })
    .catch(() => {
      this.props.alert.error(this.props.t('account.updateError'));
    });
  }

  globalSave() {
    const promises = [];
    promises.push(API.updateAccount(this.accountId, { login: this.state.login, role: this.state.role, email: this.state.email }, this.token));
    if(this.state.password.length >= 4) {
      promises.push(API.updateAccountPassword(this.accountId, this.state.password, this.token));
    }

    Promise.all(promises).then(() => {
      this.refreshAccount();
      this.props.alert.success(this.props.t('account.updateSuccess'));
    })
    .catch(() => {
      this.props.alert.error(this.props.t('account.updateError'));
    });

  }

  refreshAccount() {
    Promise.all([
      API.fetchAccount(this.accountId, this.token),
      API.fetchAccountFrames(this.accountId, this.token)
    ]).then(values => {

      this.setState({
        account: values[0],
        frames: values[1],
        loading: false,
        login: this.state.loading ? values[0].login : this.state.login,
        email: this.state.loading ? values[0].email : this.state.email,
        role: this.state.loading ? values[0].role : this.state.role,
        password: ''
      })

    }).catch(() => {
	  //this.props.alert.error(this.props.t('accounts.loadError'));
    })
  }

  saveNewLogin() {
    API.updateAccount(this.accountId, { ...this.state.account, login: this.state.login }, this.token)
      .then(() => {
        this.refreshAccount();
        this.props.alert.success(this.props.t('account.updateSuccess'));
      })
      .catch(() => {
        this.props.alert.error(this.props.t('account.updateError'));
      });
  }

  saveNewEmail() {
    API.updateAccount(this.accountId, { ...this.state.account, email: this.state.email }, this.token)
      .then(() => {
        this.refreshAccount();
        this.props.alert.success(this.props.t('account.updateSuccess'));
      })
      .catch(() => {
        this.props.alert.error(this.props.t('account.updateError'));
      });
  }
  saveNewRole() {
    API.updateAccount(this.accountId, { ...this.state.account, role: this.state.role }, this.token)
      .then(() => {
        this.refreshAccount();
        this.props.alert.success(this.props.t('account.updateSuccess'));
      })
      .catch(() => {
        this.props.alert.error(this.props.t('account.updateError'));
      });
  }

  saveNewPassword() {
    API.updateAccountPassword(this.accountId, this.state.password, this.token)
      .then(() => {
        this.refreshAccount();
        this.props.alert.success(this.props.t('account.updateSuccess'));
      })
      .catch(() => {
        this.props.alert.error(this.props.t('account.updateError'));
      });
  }

  removeFrame(frame) {
    if (window.confirm(this.props.t('account.confirmRemoveAccess'))) {
      API.removeAccountFrame(this.accountId, frame, this.token)
        .then(() => {
          this.refreshAccount();
          this.props.alert.success(this.props.t('account.frameRemoved'));
        })
        .catch(() => {
          this.props.alert.error(this.props.t('account.removeframeError'));
        });
    }
  }

  addNewFrame() {
    API.addAccountFrame(this.accountId, this.state.newFrame.id, this.token)
      .then(() => {
        this.refreshAccount();
        this.props.alert.success(this.props.t('account.frameAdded'));
      })
      .catch(() => {
        this.props.alert.error(this.props.t('account.addframeError'));
      });
  }

  state = { redirect: null };

  handleUserRightsView(user, t) {
    this.props.history.push({
      pathname: `/admin/frames/${t.id}/users/${user.id}`,
      state: { user: this.state.account }
    })
  }

  render() {

	if (this.state.redirect) {
		return <Redirect to={this.state.redirect} />
	}
    if (this.state.loading) {
      return <div></div>;
	}

  const CustomAutoComplete = React.forwardRef(({ children, onChange}, ref) => (
    <Autocomplete
      id="accounts-autoComplete"
      value={this.state.newFrame}
      onChange={(event, newValue) => this.setState({newFrame: newValue})}
      options={this.state.frameList}
      getOptionLabel={(option) => option.label !== undefined && option.label !== "" ? option.label : ''}
      style={{ width: '100%' }}
      renderInput={(params) => 
        <div ref={params.InputProps.ref}>
          <input
            type="text"
            {...params.inputProps}
            autoComplete="new-password"
            style={{
              width: '100%',
              height: 'calc(1.5em + .75rem + 2px)',
              padding: '.375rem .75rem',
              fontSize: '1rem',
              fontWeight: '400',
              lineHeight: '1.5',
              color: '#495057',
              backgroundColor: '#fff',
              backgroundClip: 'padding-box',
              border: '1px solid #ced4da',
              borderRadius: '.25rem',
              transition: 'border-color .15s ease-in-out,box-shadow .15s ease-in-out',
              outline: 'white',
            }}  
          />
        </div>
      }
    />
  ));
      console.log("this.state in account =>", this.state)
    return (
      <Container className="Common-Container">
        <Row>
          <Col md={12}>
            <PageToggle />
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 6, offset: 6 }} className="text-right">
            <Button onClick={this.globalSave.bind(this)} className="mr-sm-2 small-button" variant="gold">
				<i className="fas fa-save"></i>
			</Button>
			<Button onClick={(e) => { if (window.confirm(this.props.t('account.confirmDeletion'))) this.removeAccount(this) } } className="mr-sm-2 small-button" variant="gold">
				<i className="fas fa-eraser"></i>
			</Button>
          </Col>
        </Row>
        <Row>
          <Col md={{ span: 4, offset: 1 }}>
            <section className="mb-sm-4">
              <h4>{this.props.t('account.login')}</h4>
              <Form.Row>
                <Col md={10}>
                  <Form.Control
                    onChange={(event) => this.setState({ login: event.target.value })}
                    value={this.state.login}
                    type="text"
                    placeholder={this.props.t('account.login')}
                  />
                </Col>
                <Col md={2}>
                  <Button variant="gold"
                    className="small-button"
                    disabled={this.state.account.login === this.state.login || this.state.login.length < 4}
                    onClick={this.saveNewLogin.bind(this)}><i className="fas fa-save"></i></Button>
                </Col>
              </Form.Row>
            </section>
            <section className="mb-sm-4">
              <h4>{this.props.t('account.password')}</h4>
              <Form.Row>
                <Col md={10}>
                  <Form.Control
                    onChange={(event) => this.setState({ password: event.target.value })}
                    value={this.state.password}
                    type="text"
                    placeholder={this.props.t('account.password')}
                  />
                </Col>
                <Col md={2}>
                  <Button variant="gold"
                    className="small-button"
                    disabled={this.state.password.length < 4}
                    onClick={this.saveNewPassword.bind(this)}><i className="fas fa-save"></i></Button>
                </Col>
              </Form.Row>
            </section>
            <section className="mb-sm-4">
              <h4>{this.props.t('account.email')}</h4>
              <Form.Row>
                <Col md={10}>
                  <Form.Control
                    onChange={(event) => this.setState({ email: event.target.value })}
                    value={this.state.email}
                    type="text"
                    placeholder={this.props.t('account.email')}
                  />
                </Col>
                <Col md={2}>
                  <Button variant="gold"
                    className="small-button"
                    disabled={this.state.account.email === this.state.email || !this.validateEmail(this.state.email)}
                    onClick={this.saveNewEmail.bind(this)}><i className="fas fa-save"></i></Button>
                </Col>
              </Form.Row>
            </section>
            <section>
              <h4>{this.props.t('account.role')}</h4>
              <Form.Row>
                <Col md={10}>
                  <Form.Control as="select"
                    value={this.state.role}
                    onChange={(event) => this.setState({ role: event.target.value })}>
                    <option value="USER">USER</option>
                    <option value="ADMIN">ADMIN</option>
                  </Form.Control>
                </Col>
                <Col md={2}>
                  <Button variant="gold"
                  className="small-button"
                    disabled={this.state.account.role === this.state.role}
                    onClick={this.saveNewRole.bind(this)}><i className="fas fa-save"></i></Button>
                </Col>
              </Form.Row>
              <Form.Group as={Col} controlId="account-role">

              </Form.Group>
            </section>
          </Col>
          <Col md={6}>
            <h4>{this.props.t('account.frames')}</h4>
            {
              this.state.account.role === 'USER' ?
                <div>
                  <Form.Row autoComplete="new-password">
                    <Col>
                      <Form.Control
                        style={{}}
                        as={CustomAutoComplete}
                      />
                      {/* <Form.Control as="select" value={this.state.newFrame} onChange={(event) => this.setState({ newFrame: event.target.value })}>
                        <option value="">---</option>
                        {
                          this.state.frameList
                            .filter(t => this.state.frames.find(f => f.id === t.id) === undefined)
                            .map(t => {
                              return <option key={t.id} value={t.id}>{t.label}</option>;
                            })
                        }
                      </Form.Control> */}
                    </Col>
                    <Col>
                      <Button disabled={this.state.newFrame === ''} onClick={this.addNewFrame.bind(this)} variant="gold" className="small-button"><i className="fas fa-plus"></i></Button>
                    </Col>
                  </Form.Row>
                  <ul className="mt-sm-4">
                    {
                      this.state.frames.map(t => {
                        return (
                            <li className="trash-button" key={t.id}>
                              {t.label}
                              <i onClick={() => this.handleUserRightsView(this.state.account, t)}
                              className="ml-sm-2 fas fa-pen margin-icons-account"></i>
                              <i onClick={() => this.removeFrame(t.id)} className="ml-sm-2 fas fa-trash"></i>
                            </li>
                        )
                      })
                    }
                  </ul>
                </div>
                :
                <p>{this.props.t('account.isAdminAccount')}</p>
            }
          </Col>
        </Row>
      </Container>
    );
  }

  validateEmail(email) {
    var re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  }

}

export default withTranslation('translate')(withAlert()(Account));